import { call, put, takeLatest } from "redux-saga/effects";
import aboutDeleteApi from "../../../api/about/aboutDeleteApi";
import { aboutDeleteActions } from "../../slice/about/aboutDeleteSlice";

function* Delete(action) {
    try {
        const response = yield call(aboutDeleteApi.delete, action.payload);
        yield put(aboutDeleteActions.success(response));
    } catch (error) {
        yield put(aboutDeleteActions.failed(error.message));
    }
}
export default function* aboutDeleteSaga() {
    yield takeLatest(aboutDeleteActions.delete.type, Delete)
}