import { urls } from '../urls'
import axiosClient from '../axiosClient';

const authApi = {
    login(params) {
        return axiosClient.post(`${urls.LOGIN_URL}`, params);
    },
    refreshlogin(params){
        return axiosClient.post(`${urls.REFRESH_LOGIN}`,params)
    }
};

export default authApi;