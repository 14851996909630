import { call, put, takeLatest } from "redux-saga/effects";
import { appoinmentActions } from "../../slice/appoinment/appoinmentSlice";
import appoinmentApi from "../../../api/appoinment/appoinmentApi";
function* getAll(action) {
    try {
        const response = yield call(appoinmentApi.doctorAppoinments, action.payload);
        yield put(appoinmentActions.success(response));
    } catch (error) {
        yield put(appoinmentActions.failed(error.message));
    }
}

function* getOne(action) {
    try {
        const response = yield call(appoinmentApi.getOne, action.payload)
        yield put(appoinmentActions.success(response));
    } catch (error) {
        yield put(appoinmentActions.failed(error.message));
    }
}
function* search(action) {
    try {
        const response = yield call(appoinmentApi.search, action.payload)
        yield put(appoinmentActions.success(response));
    } catch (error) {
        yield put(appoinmentActions.failed(error.message));
    }
}
function* performanceDoctor(action) {
    try {
        const response = yield call(appoinmentApi.performanceDoctor, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* searchDoctorAppoinments(action) {
    try {
        const response = yield call(appoinmentApi.doctorAppoinmentsSearch, action.payload)
        yield put(appoinmentActions.success(response));
    } catch (error) {
        yield put(appoinmentActions.failed(error.message));
    }
}
function* doctorAppoinmentCount(action) {
    try {
        const response = yield call(appoinmentApi.doctorAppoinmentsCount, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* performancePatient(action) {
    try {
        const response = yield call(appoinmentApi.performancePatient, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* patientAppoinmentsGetAll(action) {
    try {
        const response = yield call(appoinmentApi.patientAppoinmentsGetAll, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* patientAppoinmentsGetAllSearch(action) {
    try {
        const response = yield call(appoinmentApi.patientAppoinmentsGetAllSearch, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* patientAppoinmentCount(action) {
    try {
        const response = yield call(appoinmentApi.patientAppoinmentsCount, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* clinicAppointmentPerformance(action) {
    try {
        const response = yield call(appoinmentApi.clinicAppointmentPerformance, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* clinicAppointmentGetAll(action) {
    try {
        const response = yield call(appoinmentApi.clinicAppointmentGetAll, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* clinicAppointmentGetAllSearch(action) {
    try {
        const response = yield call(appoinmentApi.clinicAppointmentGetAllSearch, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* clinicAppointmentCount(action) {
    try {
        const response = yield call(appoinmentApi.clinicAppointmentCount, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* appointmentGetAll(action) {
    try {
        const response = yield call(appoinmentApi.appointmentGetAll, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* appointmentGetAllSearch(action) {
    try {
        const response = yield call(appoinmentApi.appointmentGetAllSearch, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* appointmentGetSingle(action) {
    try {
        const response = yield call(appoinmentApi.appointmentGetSingle, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* appointmentPerformance(action) {
    try {
        const response = yield call(appoinmentApi.appointmentPerformance, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* appointmentCount() {
    try {
        const response = yield call(appoinmentApi.appointmentCount)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
function* appointmentSingleGet(action) {
    try {
        const response = yield call(appoinmentApi.appointmentSingleGet, action.payload)
        yield put(appoinmentActions.success(response))
    } catch (error) {
        yield put(appoinmentActions.failed(error.message))
    }
}
export default function* appoinmentSaga() {
    yield takeLatest(appoinmentActions.getAll.type, getAll)
    yield takeLatest(appoinmentActions.getOne.type, getOne)
    yield takeLatest(appoinmentActions.search.type, search)
    yield takeLatest(appoinmentActions.performanceDoctor.type, performanceDoctor)
    yield takeLatest(appoinmentActions.searchDoctorAppoinments.type, searchDoctorAppoinments)
    yield takeLatest(appoinmentActions.doctorAppoinmentCount.type, doctorAppoinmentCount)
    yield takeLatest(appoinmentActions.performancePatient.type, performancePatient)
    yield takeLatest(appoinmentActions.patientAppoinmentsGetAll.type, patientAppoinmentsGetAll)
    yield takeLatest(appoinmentActions.patientAppoinmentsGetAllSearch.type, patientAppoinmentsGetAllSearch)
    yield takeLatest(appoinmentActions.patientAppoinmentsCount.type, patientAppoinmentCount)
    yield takeLatest(appoinmentActions.clinicAppointmentPerformance.type, clinicAppointmentPerformance)
    yield takeLatest(appoinmentActions.clinicAppointmentGetAll.type, clinicAppointmentGetAll)
    yield takeLatest(appoinmentActions.clinicAppointmentGetAllSearch.type, clinicAppointmentGetAllSearch)
    yield takeLatest(appoinmentActions.clinicAppointmentCount.type, clinicAppointmentCount)
    yield takeLatest(appoinmentActions.appointmentGetAll.type, appointmentGetAll)
    yield takeLatest(appoinmentActions.appointmentGetAllSearch.type, appointmentGetAllSearch)
    yield takeLatest(appoinmentActions.appointmentGetSingle.type, appointmentGetSingle)
    yield takeLatest(appoinmentActions.appointmentPerformance.type, appointmentPerformance)
    yield takeLatest(appoinmentActions.appointmentCount.type, appointmentCount)
    yield takeLatest(appoinmentActions.appointmentSingleGet.type, appointmentSingleGet)
}