import { call, put, takeLatest } from "redux-saga/effects";
import { clinicActions } from "../../slice/clinic/clinicSlice";
import clinicApi from '../../../api/clinic/clinicApi'
function* add(action) {
    try {
        const response = yield call(clinicApi.add, action.payload);
        yield put(clinicActions.success(response));
    } catch (error) {
        yield put(clinicActions.failed(error.message));
    }
}

function* edit(action) {
    try {
        const response = yield call(clinicApi.edit, action.payload);
        yield put(clinicActions.success(response));
    } catch (error) {
        yield put(clinicActions.failed(error.message));
    }
}

function* getAll(action) {
    try {
        const response = yield call(clinicApi.getAll, action.payload);
        yield put(clinicActions.success(response));
    } catch (error) {
        yield put(clinicActions.failed(error.message));
    }
}

function* getOne(action) {
    try {
        const response = yield call(clinicApi.getOne, action.payload);
        yield put(clinicActions.success(response));
    } catch (error) {
        yield put(clinicActions.failed(error.message));
    }
}

function* getBankDetails(action) {
    try {
        const response = yield call(clinicApi.getBank, action.payload);
        yield put(clinicActions.success(response));
    } catch (error) {
        yield put(clinicActions.failed(error.message));
    }
}

function* addBank(action) {
    try {
        const response = yield call(clinicApi.addBank, action.payload);
        yield put(clinicActions.success(response));
    } catch (error) {
        yield put(clinicActions.failed(error.message));
    }
}

function* editBank(action) {
    try {
        const response = yield call(clinicApi.editBank, action.payload);
        yield put(clinicActions.success(response));
    } catch (error) {
        yield put(clinicActions.failed(error.message));
    }
}

function* changePassword(action) {
    try {
        const response = yield call(clinicApi.changePassword, action.payload);
        yield put(clinicActions.success(response));
    } catch (error) {
        yield put(clinicActions.failed(error.message));
    }
}

function* getAllAppoinments(action) {
    try {
        const response = yield call(clinicApi.getAllAppoinments, action.payload)
        yield put(clinicActions.success(response))
    }
    catch (err) {
        yield put(clinicActions.failed(err.message))
    }
}

function* getAllPayements(action) {
    try {
        const response = yield call(clinicApi.getAllPayments, action.payload)
        yield put(clinicActions.success(response))
    }
    catch (err) {
        yield put(clinicActions.failed(err.message))
    }
}

function* getDoctor(action) {
    try {
        const response = yield call(clinicApi.getDoctor, action.payload)
        yield put(clinicActions.success(response))
    }
    catch (err) {
        yield put(clinicActions.failed(err.message))
    }
}

function* searchClinic(action) {
    try {
        const response = yield call(clinicApi.searchClinic, action.payload)
        yield put(clinicActions.success(response))
    } catch (error) {
        yield put(clinicActions.failed(error.message))
    }
}

function* searchAppoinments(action) {
    try {
        const response = yield call(clinicApi.searchAppoinments, action.payload)
        yield put(clinicActions.success(response))
    } catch (error) {
        yield put(clinicActions.failed(error.message))
    }
}

function* searchDoctors(action) {
    try {
        const response = yield call(clinicApi.searchDoctors, action.payload)
        yield put(clinicActions.success(response))
    } catch (error) {
        yield put(clinicActions.failed(error.message))
    }
}
function* searchPayments(action) {
    try {
        const response = yield call(clinicApi.searchPayments, action.payload)
        yield put(clinicActions.success(response))
    } catch (error) {
        yield put(clinicActions.failed(error.message))
    }
}

function* updateStatus(action) {
    try {
        const response = yield call(clinicApi.updateStatus, action.payload)
        yield put(clinicActions.success(response))
    } catch (error) {
        yield put(clinicActions.failed(error.message))
    }
}

export default function* clinicSaga() {
    yield takeLatest(clinicActions.add.type, add);
    yield takeLatest(clinicActions.edit.type, edit)
    yield takeLatest(clinicActions.getAll.type, getAll);
    yield takeLatest(clinicActions.getOne.type, getOne)
    yield takeLatest(clinicActions.getBankDetails.type, getBankDetails)
    yield takeLatest(clinicActions.addBank.type, addBank)
    yield takeLatest(clinicActions.editBank.type, editBank)
    yield takeLatest(clinicActions.changePassword.type, changePassword)
    yield takeLatest(clinicActions.getAllAppoinments.type, getAllAppoinments)
    yield takeLatest(clinicActions.getAllPayments.type, getAllPayements)
    yield takeLatest(clinicActions.getDoctor.type, getDoctor)
    yield takeLatest(clinicActions.searchClinic.type, searchClinic)
    yield takeLatest(clinicActions.searchAppoinments.type, searchAppoinments)
    yield takeLatest(clinicActions.searchDoctors.type, searchDoctors)
    yield takeLatest(clinicActions.searchPayments.type, searchPayments)
    yield takeLatest(clinicActions.updateStatus.type,updateStatus)
}