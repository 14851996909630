import { Button, TextField } from "@mui/material";
import React from "react";
import './addclinic.scss'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { ClinicAddInputs } from "../../../config/Config";
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { clinicActions } from '../../../redux/slice/clinic/clinicSlice'
import clinicSelector from '../../../redux/selector/clinic/clinicSelector'
import AlertMsg from "../../../components/alert/AlertMsg";
import CircularProgress from '@mui/material/CircularProgress';
import { BackButton } from "../../../components/buttons/Buttons";
import MapGoogle from '../../../components/googlemap/MapGoogle'
import { InputAdornment } from "@mui/material";
import { Dialog } from '@mui/material';


const schema = yup.object({
    name: yup.string().required("Please enter clinic name"),
    reg_no: yup.string().required("Please enter clinic reg no"),
    email: yup.string().required("Please enter clinic email").email("Please enter clinic email"),
    contact_no: yup.string().required("Please enter clinic phone no").matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "Please enter valid phone no"),
    address: yup.string().required("Please enter clinic address"),
    location: yup.string().required("Please select your location"),
    
});

export const AddClinic = () => {
    const [open, setOpen] = React.useState(false)
    const { handleSubmit, register,setValue, formState: { errors }, reset,watch } = useForm({ resolver: yupResolver(schema),mode:'all',shouldUnregister:false });
    const dispatch = useAppDispatch()
    const clinicSelect = useAppSelector(clinicSelector)
    const defaultProps = {
        center: {
            lat: 22.354220,
            lng: 87.766493
        },
        zoom: 15
    };
    const ref = React.useRef(null);
    const [myLatLng, setMyLatLng] = React.useState({
        lat: 22.354220,
        lng: 87.766493
    })
    const [mapValue, setMapValue] = React.useState(null)
    const [alertShow, setAlertShow] = React.useState({ status: false, type: "", msgText: "" })
    React.useEffect(() => {
        if (clinicSelect && clinicSelect.data && clinicSelect.data.message && clinicSelect.data.status) {
            setAlertShow({
                status: true,
                type: "success",
                msgText: clinicSelect.data.message
            })
        }
        else if (clinicSelect && clinicSelect.data && clinicSelect.data.message) {
            setAlertShow({
                status: true,
                type: "error",
                msgText: clinicSelect.data.message
            })
        }
    }, [clinicSelect])
    const watchedLocation = watch('location')
    const onSubmit = (data) => 
    {
        if (data) {
            let obj = {...data}
            let split_arr = obj["location"].split(' ')
            obj["long"] = parseFloat(split_arr[1])
            obj["lat"] = parseFloat(split_arr[0])
            delete obj["location"]
            dispatch(clinicActions.reset())
            dispatch(clinicActions.add(obj))
        }
    }
    React.useEffect(() => {
        dispatch(clinicActions.reset())
        setAlertShow()
    }, [dispatch])
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const getMapValue = (props) => {
        setValue('location',`${props.lat} ${props.lng}`)
    }
    return (
        <React.Fragment>
            {clinicSelect.isFetching ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '48px 0' }}>
                    <CircularProgress />
                </div>
                :
                <React.Fragment>
                    <div className="top_header">
                        <BackButton />
                    </div>
                    <div className="add_wrap">
                        <h1 className="form_header">Add a Clinic</h1>
                        <form onSubmit={handleSubmit(onSubmit)} className="form_wrap">
                            <div className="form_body">
                                {
                                    ClinicAddInputs.map((elem, index) => {
                                        return (
                                            <div className="form_body_item" key={index}>
                                                <TextField rows={elem.multiline ? elem.rows : 1} fullWidth={elem.fullWidth ? true : false} {...register(elem.name)} size={elem.size} label={elem.label} placeholder={elem.placeholder} variant={elem.variant} />
                                                {errors[elem.name] && <span className="error_text">{errors[elem.name].message}</span>}
                                            </div>
                                        );
                                    })
                                }
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField size="small" placeholder='Location' onClick={() => { handleClickOpen() }} className="location_input" {...register("location")}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">
                                                <Button onClick={() => { handleClickOpen() }} variant="contained" className="location_button" style={{ padding: '4px 8px', backgroundColor: "#b3b3b3", color: 'white', fontSize: '14px', textTransform: 'capitalize', boxShadow: 'none !important' }}>Choose</Button>
                                            </InputAdornment>,
                                        }}/>
                                    {errors["location"] && !watchedLocation && <span className="error_text">{errors["location"].message}</span>}

                                </div>
                            </div>
                        </form>
                        <div>
                            <Dialog
                                aria-labelledby="customized-dialog-title"
                                open={open}
                                maxWidth='700px'
                            >
                                <div style={{ width:window.innerWidth>768?window.innerWidth-200:window.innerWidth-120, margin: 0 }}>
                                    <MapGoogle lat={22.572645} lng={88.363892} value={getMapValue} onClose={handleClose}/>
                                </div>
                            </Dialog>
                        </div>


                    </div>
                    <div className="submit_wrap">
                        <div className="submit_grid">
                            <Button variant="outlined" className="clear" type="reset" onClick={() => { reset() }} size="small" sx={{ textTransform: 'capitalize', background: 'white' }}>Clear</Button>
                            <Button variant="contained" className="submit" size="small" sx={{ textTransform: 'capitalize' }} onClick={handleSubmit(onSubmit)}>Submit</Button>
                        </div>
                    </div>

                    <div className="alert_msg_pos">
                        {
                            alertShow && alertShow.status &&
                            <AlertMsg open={alertShow.status} onClose={() => { setAlertShow() }} type={alertShow.type} msgText={alertShow.msgText} />
                        }
                    </div>
                </React.Fragment>}
        </React.Fragment>
    );
}