import { urls } from '../urls'
import axiosClient from '../axiosClient';

const paymentApi = {
    getAll(params) {
        return axiosClient.get(`${urls.GET_ALL_PAYMENTS}`, { params })
    },
    search(params) {
        return axiosClient.get(`${urls.PAYMENTS_SEARCH}`, { params })
    },
    performanceDoctor(params) {
        return axiosClient.get(`${urls.PAYMENT_PERFORMANCE_DOCTOR}`, { params })
    },
    doctorPayment(params) {
        return axiosClient.get(urls.DOCTOR_PAYMENT, { params })
    },
    doctorPaymentsCount(params) {
        return axiosClient.get(`${urls.DOCTOR_APPOINMENT_COUNT}/${params}`,)
    },
    doctorPaymentSearch(params) {
        return axiosClient.get(urls.DOCTOR_PAYMENT_SEARCH, { params })
    },
    performancePayment(params) {
        return axiosClient.get(urls.APPOINMENT_PATIENT_PERFORMANCE, { params })
    },
    performancePaymentGetAll(params) {
        return axiosClient.get(urls.PAYMENT_PATIENT_GET_ALL, { params })
    },
    performancePaymentGetAllSearch(params) {
        return axiosClient.get(urls.PAYMENT_PATIENT_SEARCH_ALL, { params })
    },
    clinicPaymentGetAll(params) {
        return axiosClient.get(urls.CLINIC_PAYMENT_GET_ALL, { params })
    },
    clinicPaymentGetAllSearch(params) {
        return axiosClient.get(urls.CLINIC_PAYMENT_GET_ALL_SEARCH, { params })
    },
    clinicPaymentPerformance(params) {
        return axiosClient.get(urls.CLINIC_PAYMENT_PERFORMANCE, { params })
    },
    clinicPaymentCount(params) {
        return axiosClient.get(`${urls.CLINIC_APPOINTMENT_COUNT}/${params}`)
    },
    paymentGetAll(params){
        return axiosClient.get(urls.PAYMENT_GET_ALL,{params})
    },
    paymentGetAllSearch(params){
        return axiosClient.get(urls.PAYMENT_GET_ALL_SEARCH,{params})
    },
    paymentPerformance(params){
        return axiosClient.get(urls.PAYMENT_PERFORMANCE,{params})
    },
    paymentCount(){
        return axiosClient.get(urls.APPOINTMENT_COUNT)
    },
    paymentSingleGet(params){
        return axiosClient.get(`${urls.PAYMENT_SINGLE_GET}/${params}`)
    }
}

export default paymentApi;