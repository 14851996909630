import React from "react";
import CustomDialog from "../CustomDialog";
import { DialogTitle, DialogContentText, DialogContent, TextField, IconButton, InputAdornment } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { adminSelector } from "../../../redux/selector/admin/adminSelector";
import { adminActions } from "../../../redux/slice/admin/adminSlice";
import { authActions } from "../../../redux/slice/auth/authSlice";

const passwordSchema = yup.object({
    oldPassword: yup.string().min(8, "Password should be minimum eight characters").required("Please enter your old password"),
    password: yup.string().min(8, "Password should be minimum eight characters").required("Please enter your new password"),
    conf_password: yup.string().oneOf([yup.ref('password'), null], "Passwords doesn't matched").min(8, "Password should be minimum eight characters").required("Please enter your confirm password")
})

export const ChangePasswordModal = ({ open, onClose }) => {
    const [passwordToggle, setPasswordToggle] = React.useState({ 'oldPassword': false, 'password': false, 'conf_password': false })
    const { handleSubmit, register, reset, formState: { errors } } = useForm({ resolver: yupResolver(passwordSchema) })
    const dispatch = useDispatch()
    const selector = useSelector(adminSelector)
    React.useEffect(() => {
        dispatch(adminActions.reset())
        reset()
    }, [open,reset, dispatch])
    const onSubmit = (data) => {
        dispatch(adminActions.adminUpdatePassword(data))
    }
    React.useEffect(() => {
        if (selector && selector.data) {
            if (selector.data.status && selector.data.message) {
                onClose()
                alert(selector.data.message)
                dispatch(adminActions.reset())
                dispatch(authActions.reset())
                localStorage.removeItem("refresh_token")
            }
            else if (!selector.data.status && selector.data.message) {
                alert(selector.data.message)
                dispatch(adminActions.reset())
            }
        }
    }, [selector, dispatch, onClose])
    return (
        <React.Fragment>
            <CustomDialog open={open} onClose={onClose} children={
                <React.Fragment>
                    <DialogTitle style={{ color: 'black', paddingBottom: 10 }}>Update your password</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ marginBottom: 6 }}>Please fill correct information that are directly update on database.</DialogContentText>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div style={{ marginBottom: 16 }}>
                                <TextField {...register("oldPassword")} fullWidth size="small" placeholder='Old Password' type={passwordToggle.oldPassword ? "text" : "password"} label="Old Password"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={() => { setPasswordToggle({ ...passwordToggle, 'oldPassword': !passwordToggle.oldPassword }) }}
                                                onMouseDown={(e) => { e.preventDefault() }}
                                            >
                                                {passwordToggle.oldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                                {errors && errors["oldPassword"] &&
                                    <p style={{ margin: 0, color: 'red' }}>{errors["oldPassword"]?.message}</p>}
                            </div>
                            <div style={{ marginBottom: 16 }}>
                                <TextField {...register("password")} fullWidth size="small" placeholder='New Password' type={passwordToggle.password ? "text" : "password"} label="New Password"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={() => { setPasswordToggle({ ...passwordToggle, 'password': !passwordToggle.password }) }}
                                                onMouseDown={(e) => { e.preventDefault() }}
                                            >
                                                {passwordToggle.password ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                                {errors && errors["password"] &&
                                    <p style={{ margin: 0, color: 'red' }}>{errors["password"]?.message}</p>}
                            </div>
                            <div>
                                <TextField {...register("conf_password")} fullWidth size="small" placeholder='Confirm Password' type={passwordToggle.conf_password ? "text" : "password"} label="Confirm Password"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={() => { setPasswordToggle({ ...passwordToggle, 'conf_password': !passwordToggle.conf_password }) }}
                                                onMouseDown={(e) => { e.preventDefault() }}
                                            >
                                                {passwordToggle.conf_password ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                                {errors && errors["conf_password"] &&
                                    <p style={{ margin: 0, color: 'red' }}>{errors["conf_password"]?.message}</p>}
                            </div>
                        </form>
                    </DialogContent>
                    <div className="dialog_footer">
                        <div className="dialog_footer_child_wrap">
                            <button className="b_d_f_close" onClick={onClose}>Close</button>
                            <button className='b_d_f_update' onClick={handleSubmit(onSubmit)}>Update</button>
                        </div>
                    </div>
                </React.Fragment>
            } />
        </React.Fragment>
    );
}