import React from "react";
import CustomDialog from "../../settings/CustomDialog";
import { DialogTitle, DialogContentText, DialogContent, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch} from "react-redux";
import { clinicActions } from "../../../redux/slice/clinic/clinicSlice";
import AlertMsg from "../../../components/alert/AlertMsg";
import MapGoogle from "../../../components/googlemap/MapGoogle";
const nameSchema = yup.object({
    value: yup.string().required("Please enter clinic")
})

export const DetailsEdit = (props) => {
    const { handleSubmit, register, reset, formState: { errors } } = useForm({ resolver: yupResolver(nameSchema)})
    const dispatch = useDispatch()
    let obj={}
    const onSubmit = (data) => {
        let field=data.value
        
        if(props.modalFor==="name")
        {
             obj={
                "updation_type":"name",
                "id":props.id,
                "name":field
            }
        }
        if(props.modalFor==="registration no")
        {
             obj={
                "updation_type":"name",
                "id":props.id,
                "reg_no":field
            }
        }
        if(props.modalFor==="address")
        {
             obj={
                "updation_type":"name",
                "id":props.id,
                "address":field
            }
        }
        dispatch(clinicActions.edit(obj))
        alert(`Clinic ${props.modalFor} update successfull`)
        props.onClose()
        // if(dispatch)
        // {
            //response.status===true&&alert(`Clinic ${props.modalFor} update successfull`); 
           
        //     window.history.back();
        // }
    }
    const getMapValue=(value)=>{
       if(value){
        obj={
            "updation_type":"name",
            "id":props.id,
            "lat":value.lat,
            "long":value.lng
        }
        dispatch(clinicActions.edit(obj))
        if(dispatch)
        {
            console.log("dispatched")
            props.onClose();
            window.history.back()
        }
    }
    }
    return (
        <React.Fragment>
            <CustomDialog open={props.open} onClose={props.onClose} children={
                <React.Fragment>
                    <DialogTitle style={{ color: 'black', paddingBottom: 10 }}>{props.modalFor==="name"?"Update Clinic Name":props.modalFor==="registration no"?"Update Clinic Reg No.":props.modalFor==="address"?"Update Clinic Reg No.":props.modalFor==="location"&&"Update Clinic Location"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ marginBottom: 6 }}>Please fill correct information that are directly update on database.</DialogContentText>
                        {props.modalFor!=="location"?<form onSubmit={handleSubmit(onSubmit)}>
                            <React.Fragment>
                                <TextField {...register("value")} fullWidth size="small" placeholder={props.modalFor==="name"?"ABC Medical":props.modalFor==="registration no"?"XX68367565XX":props.modalFor==="address"&&"123,Kolkata,700006"} type='text' label={props.modalFor==="name"?"Clinic Name":props.modalFor==="registration no"?"Registration Number":props.modalFor==="address"&&"Address"} />
                                {errors && errors["value"] &&
                                    <p style={{ margin: 0, color: 'red' }}>{errors["value"]?.message} {props.modalFor}</p>
                                    
                                }
                            </React.Fragment>
                        </form>:
                        <div style={{ width:window.innerWidth>768?window.innerWidth-500:window.innerWidth-120, margin: 0,height:'500px' }}>
                        <MapGoogle lat={props.lat&&props.lat} lng={props.lng&&props.lng} value={getMapValue} onClose={props.onClose}/>
                        </div>
                        }
                    </DialogContent>
                    {props.modalFor!=="location"&&<div className="dialog_footer">
                        <div className="dialog_footer_child_wrap">
                            <button className="b_d_f_close" onClick={props.onClose}>Close</button>
                            <button className='b_d_f_update' onClick={handleSubmit(onSubmit)}>Update</button>
                        </div>
                    </div>}
                </React.Fragment>
            } />
        </React.Fragment>
    );
}