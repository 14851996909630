import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const doctorUpdateSlice = createSlice({
    name: "doctor_update",
    initialState,
    reducers: {
        updateCertificate: (state) => {
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        failed(state, action) {
            state.message = action.payload
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    },
});

// Actions
export const doctorUpdateActions = doctorUpdateSlice.actions;

// Reducer
const doctorUpdateReducer = doctorUpdateSlice.reducer;

export { doctorUpdateReducer };