import { call, put, takeLatest } from "redux-saga/effects";
import aboutAddApi from "../../../api/about/aboutAddApi";
import { aboutAddActions } from "../../slice/about/aboutAddSlice";

function* add(action) {
    try {
        const response = yield call(aboutAddApi.add, action.payload);
        yield put(aboutAddActions.success(response));
    } catch (error) {
        yield put(aboutAddActions.failed(error.message));
    }
}
export default function* aboutAddSaga() {
    yield takeLatest(aboutAddActions.add.type, add)
}