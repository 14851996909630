import { Avatar, Skeleton, CircularProgress, Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AreaChart } from "../../components/area_chart/AreaChart";
import { LineChart } from "../../components/line_chart/Line_Chart";
import { BASE_URL } from "../../config/Config";
import { doctorSelector } from "../../redux/selector/doctor/doctorSelector";
import { doctorActions } from "../../redux/slice/doctor/doctorSlice";
import '../doctor_details/style.scss'
import Slide from '@mui/material/Slide';
import Dialog from "@mui/material/Dialog";
import { BackButton } from "../../components/buttons/Buttons";
import { patientSelector } from "../../redux/selector/patient/patientSelector";
import { patientActions } from "../../redux/slice/patient/patientSlice";
// import { PushNotification } from "./dialog/PushNotification";
import paymentSelector from "../../redux/selector/payment/paymentSelector";
import { paymentActions } from "../../redux/slice/payment/paymentSlice";
import { appoinmentActions } from "../../redux/slice/appoinment/appoinmentSlice";
import appoinmentSelector from "../../redux/selector/appoinment/appoinmentSelector"
import { adminSelector } from '../../redux/selector/admin/adminSelector';
import { adminActions } from "../../redux/slice/admin/adminSlice";
import { formatDate } from "../../utils";
import "./style.scss"
export const PatientDetailsView = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const selector = useSelector(patientSelector)
    React.useEffect(() => {
        if (params && params.id) {
            dispatch(adminActions.reset())
            dispatch(patientActions.getOne(params.id))
        }
    }, [params, dispatch])
    const navigate = useNavigate();
    const [fsc, setFsc] = React.useState(false)
    const [imgLoading, setImgLoading] = React.useState(true)
    const paymentPerformanceSelector = useSelector(paymentSelector)
    const [paymentPerformanceData, setPaymentPerformanceData] = React.useState([])
    const [paymentPerformanceTableData, setPaymentPerformanceTableData] = React.useState([])
    const appoinmentPerformanceSelector = useSelector(appoinmentSelector)
    const [appoinmentPerformanceData, setAppoinmentPerformanceData] = React.useState([])
    const [appoinmentPerformanceTableData, setAppoinmentPerformanceTableData] = React.useState([])
    const adminSelect = useSelector(adminSelector)
    React.useEffect(() => {
        if (params && params.id) {
            dispatch(appoinmentActions.performancePatient({ 'id': params.id, 'filter': 'year' }))
            dispatch(paymentActions.performancePayment({ 'id': params.id, 'filter': 'year' }))
        }
    }, [params])
    React.useEffect(() => {
        if (params && params.id) {
            setAppoinmentPerformanceTableData([])
            setPaymentPerformanceTableData([])
            dispatch(appoinmentActions.patientAppoinmentsGetAll({ 'patient_id': params.id, 'page': 1, 'limit': 4 }))
            dispatch(paymentActions.performancePaymentGetAll({ 'patient_id': params.id, 'page': 1, 'limit': 4 }))
        }
    }, [params])
    React.useEffect(() => {
        if (paymentPerformanceSelector && paymentPerformanceSelector.data && paymentPerformanceSelector.data instanceof Array) {
            if (paymentPerformanceSelector.data.length > 0) {
                setPaymentPerformanceData(paymentPerformanceSelector)
            }
            else {
                setPaymentPerformanceData([])
            }
        }
        else if (paymentPerformanceSelector.data.rows && paymentPerformanceSelector.data.rows instanceof Array) {
            if (paymentPerformanceSelector.data.rows.length > 0) {
                setPaymentPerformanceTableData(paymentPerformanceSelector)
            }
            else {
                setPaymentPerformanceTableData([])
            }
        }
    }, [paymentPerformanceSelector])
    React.useEffect(() => {
        if (appoinmentPerformanceSelector && appoinmentPerformanceSelector.data && appoinmentPerformanceSelector.data instanceof Array) {
            if (appoinmentPerformanceSelector.data.length > 0) {
                setAppoinmentPerformanceData(appoinmentPerformanceSelector)
            }
            else {
                setAppoinmentPerformanceData([])
            }
        }
        else if (appoinmentPerformanceSelector.data.rows && appoinmentPerformanceSelector.data.rows instanceof Array) {
            if (appoinmentPerformanceSelector.data.rows.length > 0) {
                setAppoinmentPerformanceTableData(appoinmentPerformanceSelector)
            }
            else {
                setAppoinmentPerformanceTableData([])
            }
        }
    }, [appoinmentPerformanceSelector])
    const deactivatePatient = () => {
        if (params && params.id) {
            dispatch(adminActions.deactivePatient({ "id": params.id }))
        }
    }
    const deletePatient = () => {
        if (params && params.id) {
            if (window.confirm("Are you want to delete these patient?")) {
                dispatch(adminActions.delelePatient({ "id": params.id }))
            }
        }
    }
    React.useEffect(() => {
        if (adminSelect && adminSelect.data && adminSelect.data.status && adminSelect.data.message) {
            if (adminSelect.data.message === "Account is deleted successfully!") {
                alert("These patient deleted successfully")
                navigate('/patients')
            }
            else if (adminSelect.data.message && adminSelect.data.status) {
                if (params && params.id) {
                    dispatch(patientActions.getOne(params.id))
                }
            }
            dispatch(adminActions.reset())
        }
    }, [adminSelect])
    return (
        <React.Fragment>
            <div className="doctor_details">
                <div className="header" >
                    <BackButton />
                </div>
                <div className="grid_3">
                    {paymentPerformanceSelector.isFetching ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"240px", width: '100%', background: 'white', borderRadius: 6, margin: '48px 0' }}>
                                <CircularProgress />
                            </div> :
                            <React.Fragment>
                               
                                    <div className="appoinment" style={{ backgroundColor: "white",borderRadius:"7px",marginTop:0 }}>
                                        <div style={{ display: "flex", padding:"0px 15px 0px 15px",alignItems:"center", justifyContent: "space-between" }}>
                                            <p style={{ paddingBottom: 0,paddingLeft:2,paddingRight:2 }}>Payments</p>
                                            {paymentPerformanceData.data && paymentPerformanceData.data.length > 0 && <button onClick={() => { navigate(`/patients/payment/${selector.data.id}`) }} style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14, color: '#314c7c' }}>View All</button>}
                                        </div>
                                        {paymentPerformanceTableData && paymentPerformanceTableData.data && paymentPerformanceTableData.data.rows && paymentPerformanceTableData.data.rows instanceof Array && paymentPerformanceTableData.data.rows.length > 0 ?
                                            <div className="table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td style={{ whiteSpace: 'nowrap' }}>Appointment Id</td>
                                                            <td>
                                                                <div className="th_cont">
                                                                    <span>Doctor Name</span>
                                                                </div>
                                                            </td>
                                                            <td>Status</td>
                                                            <td>Amount</td>
                                                            <td>Date</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paymentPerformanceTableData && paymentPerformanceTableData.data && paymentPerformanceTableData.data.rows && paymentPerformanceTableData.data.rows instanceof Array && paymentPerformanceTableData.data.rows.length > 0 && paymentPerformanceTableData.data.rows.map((elem, index) => {
                                                            return (
                                                                <tr onClick={() => { navigate(`/payment/details/${elem.id}`) }} key={index}>
                                                                    <td>
                                                                        <div className="usr_ico_n_tex">
                                                                            <span className="title">{elem.appointment_id ? elem.appointment_id : null}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="usr_ico_n_tex">
                                                                            <div className="ico_wrp">
                                                                                <img src={require('../../assets/icon/doctor.png')} alt="user" />
                                                                            </div>
                                                                            <span className="title">{elem.doctor ? elem.doctor.name ? elem.doctor.name : null : null}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="status_flex">
                                                                            <span className={elem.payment_status === "Success" ? "enabled status" : "disabled status"}></span>
                                                                            <span className="title">{elem.payment_status ? elem.payment_status : null}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="usr_ico_n_tex">
                                                                            <div className="ico_wrp">
                                                                                <img src={require('../../assets/icon/rupee.png')} alt="user" />
                                                                            </div>
                                                                            <span className="title">{elem.fees ? elem.fees : null}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {elem.date ?
                                                                            <React.Fragment>
                                                                                {formatDate(elem?.date)}
                                                                            </React.Fragment> : null}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div> :
                                        <div style={{ height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 180, maxWidth: '100%' }} />
                                        </div>}
                                    </div>
                            </React.Fragment>
                        }
                    {
                        selector.isFetching ?
                            <div className="top_right card_shadow">
                                <CircularProgress />
                            </div> :
                            <React.Fragment>
                                {selector && selector.data && selector.data.name &&
                                    <div className="top_right card_shadow" style={{ height: "auto" }}>
                                        <div style={{ position: 'relative' }}>
                                            {
                                                selector.data.profile_pic ?
                                                    <div style={{ position: 'relative' }}>
                                                        <Avatar sx={{ width: 84, height: 84, fontSize: 36 }} src={`${BASE_URL}${selector.data.profile_pic.slice(2)}`} onLoad={(e) => { setImgLoading(false) }} />
                                                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                                                            {imgLoading && <Skeleton variant="circular" width={84} height={84} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <Avatar sx={{ width: 84, height: 84, fontSize: 36, bgcolor: `lightgrey` }} className="shadow">{
                                                        (selector.data.name && selector.data.name.split(' ') && selector.data.name.split(' ').length > 2) ? `${selector.data.name.split(' ')[1].charAt(0).toUpperCase()}${selector.data.name.split(' ')[2].charAt(0).toUpperCase()}` :
                                                            (selector.data.name && selector.data.name.split(' ') && selector.data.name.split(' ').length === 2) ? `${selector.data.name.split(' ')[0].charAt(0).toUpperCase()}${selector.data.name.split(' ')[1].charAt(0).toUpperCase()}` : selector.data.name.charAt(0).toUpperCase()
                                                    }</Avatar>
                                            }
                                        </div>
                                        <div className='title' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6 }}>
                                            <h1>{selector.data.name}</h1>
                                        </div>
                                        <div className='social_icons'>
                                            {selector.data.contact_no && <a href={`tel:${selector.data.contact_no}`}>
                                                <img src={require('../../assets/icon/social/phone.png')} alt={selector.data.contact_no} />
                                            </a>}
                                            {selector.data.email && <a href={`mailto:${selector.data.email}`}>
                                                <img src={require('../../assets/icon/social/gmail.png')} alt={selector.data.email} />
                                            </a>}
                                        </div>
                                    </div>}
                            </React.Fragment>
                    }
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 12, alignItems: 'center' }}>
                        <Button sx={{ textTransform: 'capitalize' }} style={{ width: '100%', justifyContent: 'center', background: 'gray', color: "white" }} onClick={deactivatePatient}>{`${selector.data.active_status ? "Deactivate Account" : "Activate Account"}`}</Button>
                        <Button sx={{ textTransform: 'capitalize' }} style={{ width: '100%', justifyContent: 'center', background: '#D11A2A', color: "white" }} onClick={deletePatient}>Delete Account</Button>
                        {/* <Button sx={{ textTransform: 'capitalize' }} style={{ width: '100%', justifyContent: 'center' }} color="primary" onClick={() => { setPushN(true) }}>Push Notification</Button> */}
                    </div>
                </div>
                <div className="patientsTop">
                    <div>
                        <div className="top_left card_shadow">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                                <p style={{ paddingBottom: 0 }}>Appointment Performance</p>

                                {appoinmentPerformanceData.data && appoinmentPerformanceData.data.length > 0 &&
                                    <div style={{ display: 'flex', gap: "22px", alignItems: "center" }}>
                                        <button onClick={() => { navigate(`/patients/appoinment/${selector.data.id}`) }} style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14, color: '#314c7c' }}>View All</button></div>}
                            </div>
                            {
                                appoinmentPerformanceSelector.isFetching ?
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', margin: '48px 0' }}>
                                        <CircularProgress />
                                    </div> : appoinmentPerformanceData.data && appoinmentPerformanceData.data instanceof Array && appoinmentPerformanceData.data.length > 0 ? <AreaChart data={
                                        {
                                            'labels': appoinmentPerformanceData.data.map(e => e._id),
                                            'datasets': [
                                                {
                                                    'fill': true,
                                                    'label': "Count",
                                                    'lineTension': 0.5,
                                                    'data': appoinmentPerformanceData.data.map(e => e.count),
                                                    'borderColor': 'rgb(53, 162, 235)',
                                                    'backgroundColor': 'rgba(53, 162, 235, 0.5)',
                                                }
                                            ]
                                        }
                                    } /> :
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 320, maxWidth: '100%' }} />
                                        </div>
                            }
                        </div>
                       
                    </div>
                    <div>
                        <div className="top_left card_shadow">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                                <p style={{ paddingBottom: 0 }}>Payment Performance</p>
                                {paymentPerformanceData.data && paymentPerformanceData.data.length > 0 && <button onClick={() => { navigate(`/patients/payment/${selector.data.id}`) }} style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14, color: '#314c7c' }}>View All</button>}
                            </div>
                            {
                                paymentPerformanceSelector.isFetching ?
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', margin: '48px 0' }}>
                                        <CircularProgress />
                                    </div> : paymentPerformanceData.data && paymentPerformanceData.data.length > 0 ? <AreaChart data={
                                        {
                                            'labels': paymentPerformanceData.data.map(e => e._id),
                                            'datasets': [
                                                {
                                                    'fill': false,
                                                    'label': "Count",
                                                    'lineTension': 0.5,
                                                    'data': paymentPerformanceData.data.map(e => e.count),
                                                    borderColor: "#742774"

                                                },
                                                {
                                                    'fill': true,
                                                    'label': "Amount",
                                                    'lineTension': 0.5,
                                                    'data': paymentPerformanceData.data.map(e => e.fees),
                                                    backgroundColor: "rgba(75,192,192,0.2)",
                                                    borderColor: "rgba(75,192,192,1)"
                                                }
                                            ]
                                        }
                                    } /> :
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 320, maxWidth: '100%' }} />
                                        </div>
                            }
                        </div>
                    </div>
                </div>
                <div style={{width:"100%"}}>
                {appoinmentPerformanceSelector.isFetching ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', width: '100%', background: 'white', borderRadius: 6, margin: '48px 0' }}>
                                <CircularProgress />
                            </div> :
                            <React.Fragment>
                                {(appoinmentPerformanceData.data && appoinmentPerformanceData.data.length > 0) &&
                                    <div className="appoinment" style={{ backgroundColor: "white",borderRadius:"7px" }}>
                                        <div style={{ display: "flex", padding:"0px 15px 0px 15px",alignItems:"center", justifyContent: "space-between" }}>
                                            <p style={{ paddingBottom: 0,paddingLeft:2,paddingRight:2 }}>Appointments</p>
                                            <button onClick={() => { navigate(`/patients/appoinment/${selector.data.id}`) }} style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14, color: '#314c7c' }}>View All</button>
                                        </div>
                                        {
                                            appoinmentPerformanceTableData.data && appoinmentPerformanceTableData.data.rows && appoinmentPerformanceTableData.data.rows instanceof Array && appoinmentPerformanceTableData.data.rows.length > 0 ?
                                                <div className="table">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <td>
                                                                    <div className="th_cont">
                                                                        <span>Doctor Name</span>
                                                                    </div>
                                                                </td>
                                                                <td>Status</td>
                                                                <td>Clinic</td>
                                                                <td>Fees</td>
                                                                <td>Date</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {appoinmentPerformanceTableData.data && appoinmentPerformanceTableData.data.rows && appoinmentPerformanceTableData.data.rows instanceof Array && appoinmentPerformanceTableData.data.rows.length > 0 && appoinmentPerformanceTableData.data.rows.map((elem, index) => {
                                                                return (
                                                                    <tr onClick={() => { navigate(`/appointment/details/${elem.id}`) }} key={index}>
                                                                        <td>
                                                                            <div className="usr_ico_n_tex">
                                                                                <div className="ico_wrp">
                                                                                    <img src={require('../../assets/icon/doctor.png')} alt="user" />
                                                                                </div>
                                                                                <span className="title">{(elem.doctor && elem.doctor.name) ? elem.doctor.name : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="status_flex">
                                                                                <span className={(elem.appointment_status.trim() === "Booked" || elem.appointment_status.trim() === "Completed") ? "enabled status" : "disabled status"}></span>
                                                                                <span className="title">{elem.appointment_status ? elem.appointment_status : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="usr_ico_n_tex">
                                                                                <div className="ico_wrp">
                                                                                    <img src={require('../../assets/icon/clinic.png')} alt="user" />
                                                                                </div>
                                                                                <span className="title">{(elem.clinic && elem.clinic.name) ? elem.clinic.name : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="usr_ico_n_tex">
                                                                                <div className="ico_wrp">
                                                                                    <img src={require('../../assets/icon/rupee.png')} alt="user" />
                                                                                </div>
                                                                                <span className="title">{elem.fees ? elem.fees : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {elem.appointment_date ?
                                                                                <React.Fragment>
                                                                                    {formatDate(elem.appointment_date)}  {elem?.time}
                                                                                </React.Fragment> : null}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div> :
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 320, maxWidth: '100%' }} />
                                        </div>
                                        }
                                    </div>}
                            </React.Fragment>
                        }
                </div>
            </div>
            {
                fsc &&
                <FullScreenCertificate open={fsc} onClose={() => { setFsc(false) }} children={
                    <div style={{
                        background: 'white',
                        boxShadow: '3px 3px 10px #d9d9d9,-3px -3px 10px #ffffff',
                        padding: 16,
                        borderRadius: 6
                    }}>
                        <img src={`${BASE_URL}${selector.data.certificate_image.slice(2)}`} alt="full_certificate" style={{ height: 'auto', width: '100%' }} />
                    </div>
                } />
            }
            {/* {
                pushN && <PushNotification open={pushN} onClose={() => { setPushN(false) }} />
            } */}
        </React.Fragment>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenCertificate = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
        >
            {props.children}
        </Dialog>
    );
}
