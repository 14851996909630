import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const specializationDeleteSlice = createSlice({
    name: "specializationDelete",
    initialState,
    reducers: {
       
        specializationDelete: (state,action) => {
            state.isFetching = true;
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        failed(state, action) {
            state.message = action.payload
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    },
});

// Actions
export const specializationDeleteActions = specializationDeleteSlice.actions;

// Reducer
const specializationDeleteReducer = specializationDeleteSlice.reducer;


export { specializationDeleteReducer };