import { Routes,Route } from "react-router-dom";
import { AddClinic } from "../../pages/clinic/add/AddClinic";
import { Clinics } from "../../pages/clinic/Clinics";
import { ClinicsDetails } from "../../pages/clinic/ClinicsDetails";

export const ClinicRouting = ()=>{
    return(
        <Routes>
            <Route path={CLINIC_HOME} element={<Clinics />}  />
            <Route path={`${CLINIC_DETAILS}/:id`} element={<ClinicsDetails />} />
            <Route path={CLINIC_ADD} element={<AddClinic />} />
        </Routes>
    );
}
export const CLINIC_HOME = "/"
export const CLINIC_PUBLIC_HOME = "/clinics"
export const CLINIC_DETAILS = "/details"
export const CLINIC_PUBLIC_DETAILS = `${CLINIC_PUBLIC_HOME}${CLINIC_DETAILS}`
export const CLINIC_ADD = "/add"
export const CLINIC_PUBLIC_ADD = `${CLINIC_PUBLIC_HOME}${CLINIC_ADD}`