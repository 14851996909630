import { Button, Portal } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import clinicSelector from "../../redux/selector/clinic/clinicSelector";
import { clinicActions } from "../../redux/slice/clinic/clinicSlice";
import AlertMsg from "../alert/AlertMsg";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import './style.scss'
export const ReGeneratePassword = (props) => {
    const [generateSuccess, setGenerateSuccess] = React.useState(false)
    const dispatch = useAppDispatch()
    const selector = useAppSelector(clinicSelector)
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        if (selector && selector.isFetching) {
            setOpen(true)
        }
        else {
            setOpen(false)
        }
    }, [selector])
    const onClick = () => {
        if (props.id) {
            dispatch(clinicActions.changePassword({ 'id': props.id }))
        }
    }
    React.useEffect(() => {
        if (selector && selector.data && selector.data.status) {
            setGenerateSuccess(true)
        }
    }, [selector])
    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Button onClick={onClick} className="table_title_cell_link" sx={{ textTransform: 'capitalize' }} variant="contained" size="small">Re-Generate</Button>
            </div>
            <Portal>
                {
                    open &&
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                {
                    generateSuccess && selector.data.message &&
                    <AlertMsg
                        open={generateSuccess}
                        onClose={() => { setGenerateSuccess(false); dispatch(clinicActions.reset()); dispatch(clinicActions.getAll({ 'limit': 5, 'page': 1 })) }}
                        type="success"
                        msgText={selector.data.message}
                    />
                }
            </Portal>
        </React.Fragment>
    )
}

export const UpdateStatus = (props) => {
    const dispatch = useAppDispatch()
    const selector = useAppSelector(clinicSelector)
    const onClickStatus = () => {
        if (props && props.row && props.row.id) {
            let obj = {
                'id': props.row.id,
                'active_status': props.row.active_status === true ? false : props.row.active_status === false ? true : ''
            }
            dispatch(clinicActions.updateStatus(obj))
        }
    }
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button onClick={onClickStatus} size="small" variant="contained" style={{ textTransform: 'capitalize', background: props.row.active_status === false ? 'rgba(255,0,0,0.75)' : 'mediumseagreen' }}>{props.row.active_status === true ? 'Enabled' : props.row.active_status === false ? 'Disabled' : ''}</Button>
        </div>
    )
}

export const BackButton = ()=>{
    return(
        <Button onClick={()=>{window.history.back()}} size="small" className="button_small">Back</Button>
    );
}