import { urls } from '../urls'
import axiosClient from '../axiosClient';
export const patientApi = {
    getAll: (params) => {
        return (
            axiosClient.get(`${urls.GET_ALL_PATIENT}`, { params })
        )
    },
    getOne: (params) => {
        return (
            axiosClient.get(`${urls.GET_SINGLE_PATIENT}/${params}`)
        )
    },
    getAllAppoinment: (params) => {
        return (
            axiosClient.get(`${urls.GET_ALL_APPOINMENTS_REF_PATIENT}`, { params })
        )
    },
    getAllPayments: (params) => {
        return (
            axiosClient.get(`${urls.GET_ALL_PAYMENTS_REF_PATIENT}`, { params })
        );
    },
    searchPatient: (params) => {
        return (
            axiosClient.get(`${urls.PATIENT_SEARCH}`, { params })
        )
    },
    searchPatientAppoinments: (params) => {
        return axiosClient.get(`${urls.PATIENT_APPOINMENTS_SEARCH}`, { params })
    },
    searchPatientPayments: (params) => {
        return axiosClient.get(`${urls.PATIENT_PAYMENTS_SEARCH}`, { params })
    }
}