import React from "react";
import { useNavigate } from "react-router-dom";
import './style.scss'
import { Avatar } from "@mui/material";
import { Link } from 'react-router-dom'
import { AreaChart } from "../../components/area_chart/AreaChart";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { paymentActions } from "../../redux/slice/payment/paymentSlice";
import paymentSelector from "../../redux/selector/payment/paymentSelector";
import { formatDate } from "../../utils";
export const NewPayment = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const appointmentSelect = useSelector(paymentSelector)
    React.useEffect(() => {
        dispatch(paymentActions.paymentPerformance({ "filter": "year" }))
        dispatch(paymentActions.paymentGetAll({ 'page': 1, 'limit': 4 }))
    }, [dispatch])
    const [appointmentFilterData, setAppointmentFilterData] = React.useState([])
    const [appointmentTableData, setAppointmentTableData] = React.useState()
    React.useEffect(() => {
        console.log("appointmentSelect:",appointmentSelect)
        if (appointmentSelect && appointmentSelect.data && appointmentSelect.data instanceof Array) {
            if (appointmentSelect.data.length > 0) {
                setAppointmentFilterData(appointmentSelect)
            }
            else {
                setAppointmentFilterData([])
            }
        }
        else if (appointmentSelect?.data?.rows) {
            if (appointmentSelect?.data.rows.length > 0) {
                setAppointmentTableData(appointmentSelect)
            }
            else {
                setAppointmentTableData([])
            }
        }
    }, [appointmentSelect])
    React.useEffect((elem)=>{
        console.log("appointmentTableData:",appointmentTableData)
    },[appointmentTableData])
    return (
        <React.Fragment>
            <div className="appointment_home_main">
                <div className='top_header' style={{ marginBottom: 0 }}>
                    <div className='left'>
                        <h1>Payment Performance</h1>
                    </div>
                </div>
                <div className="top">
                    <div style={{ padding: 16, background: 'white', borderRadius: 6 }}>
                        <div className="top_left card_shadow">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                                <p style={{ paddingBottom: 0 }}>Payment Performance</p>
                            </div>
                            {
                                appointmentSelect.isFetching ?
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '48px 0' }}>
                                        <CircularProgress />
                                    </div> : appointmentFilterData?.data && appointmentFilterData?.data.length > 0 ? <AreaChart data={
                                        {
                                            'labels': appointmentFilterData?.data.map(e => e._id),
                                            'datasets': [
                                                {
                                                    'fill': false,
                                                    'label': "Count",
                                                    'lineTension': 0.5,
                                                    'data': appointmentFilterData?.data.map(e => e.count),
                                                    borderColor: "#742774"

                                                },
                                                {
                                                    'fill': true,
                                                    'label': "Amount",
                                                    'lineTension': 0.5,
                                                    'data': appointmentFilterData?.data.map(e => e.fees),
                                                    backgroundColor: "rgba(75,192,192,0.2)",
                                                    borderColor: "rgba(75,192,192,1)"
                                                }
                                            ]
                                        }
                                    } /> :
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 240, maxWidth: '100%' }} />
                                        </div>
                            }
                        </div>
                    </div>
                    <div style={{ background: 'white', borderRadius: 6 }}>
                        <div className="top_left card_shadow">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 16 }}>
                                <p style={{ paddingBottom: 0 }}>Recent Payments</p>
                                {appointmentTableData && appointmentTableData?.data && appointmentTableData?.data.rows && appointmentTableData?.data.rows instanceof Array && appointmentTableData?.data.rows.length > 0 && <Link to="/payment/details" style={{ color: '#314c7c' }}><button style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14 }}>View All</button></Link>}
                            </div>
                            {appointmentSelect.isFetching ?
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', width: '100%', background: 'white', borderRadius: 6, margin: '48px 0' }}>
                                    <CircularProgress />
                                </div> :
                                <React.Fragment>
                                    {appointmentTableData?.data && appointmentTableData?.data?.rows&&appointmentTableData?.data?.rows?.length>0 ?
                                        <div className="appoinment">
                                            {appointmentTableData && appointmentTableData?.data && appointmentTableData?.data.rows && appointmentTableData?.data.rows.length > 0 ?
                                                <div className="table">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <td>
                                                                    <div className="th_cont">
                                                                        <span>Patient Name</span>
                                                                    </div>
                                                                </td>
                                                                <td>Status</td>
                                                                <td>
                                                                    <div className="th_cont">
                                                                        <span>Clinic Name</span>
                                                                    </div>
                                                                </td>
                                                                <td>Amount</td>
                                                                <td>Date</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {appointmentTableData && appointmentTableData?.data && appointmentTableData?.data?.rows  && appointmentTableData?.data?.rows?.length > 0 && appointmentTableData?.data?.rows?.map((elem, index) => {
                                                                return (
                                                                    <tr onClick={() => { navigate(`/payment/details/${elem.id}`) }} key={index}>
                                                                        <td>
                                                                            <div className="usr_ico_n_tex">
                                                                                <div className="ico_wrp">
                                                                                    <img src={require('../../assets/icon/patient.png')} alt="user" />
                                                                                </div>
                                                                                <span className="title">{elem.patient ? elem.patient.name ? elem.patient.name : null : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="status_flex">
                                                                                <span className={elem.payment_status === "Success" ? "enabled status" : "disabled status"}></span>
                                                                                <span className="title">{elem.payment_status ? elem.payment_status : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="usr_ico_n_tex">
                                                                                <div className="ico_wrp">
                                                                                    <img src={require('../../assets/icon/clinic.png')} alt="user" />
                                                                                </div>
                                                                                <span className="title">{elem.clinic ? elem.clinic.name ? elem.clinic.name : null : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="usr_ico_n_tex">
                                                                                <div className="ico_wrp">
                                                                                    <img src={require('../../assets/icon/rupee.png')} alt="user" />
                                                                                </div>
                                                                                <span className="title">{elem.fees ? elem.fees : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {elem.date ?
                                                                                <React.Fragment>
                                                                                    {formatDate(elem?.date)}
                                                                                </React.Fragment> : null}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div> :  <div style={{ height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                         <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 180, maxWidth: '100%' }} />
                                     </div>}
                                        </div>:
                                         <div style={{ height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                         <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 180, maxWidth: '100%' }} />
                                     </div>}
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
                {/* <div className="top_clinic_wrap" >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', fontWeight: "bold" }}>
                        <p>Top Gainer Clinics</p>
                    </div>
                    <div className="top_clinic_grid">
                        {
                            AppointmentData && AppointmentData.map((elem, index) => {
                                return (
                                    <AppointmentCard background={index === 0 ? "#BCCEF8" : index === 1 ? "#A7FFE4" : index === 2 ? "#DAEAF1" : index === 3 && "#85F4FF"} data={elem} view={"grid"} key={index} />
                                )
                            })

                        }
                    </div>
                </div>
                <div className="top_clinic_wrap" >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', fontWeight: "bold" }}>
                            <p>Top Gainer Doctors</p>
                        </div>
                    </div>
                    <div className="top_clinic_grid">
                        {
                            AppointmentData && AppointmentData.map((elem, index) => {
                                return (
                                    <AppointmentCard background={index === 0 ? "#fdffb6" : index === 1 ? "#9bf6ff" : index === 2 ? "#ffc6ff" : index === 3 && "#caffbf"} data={elem} view={"grid"} key={index} />
                                )
                            })

                        }
                    </div>
                </div> */}
            </div>
        </React.Fragment>
    )
}
export const AppointmentData = [
    {
        name: "Hiranmoy Poria",
    },
    {
        name: "Hiranmoy Poria",
    },
    {
        name: "Hiranmoy Poria",
    },
    {
        name: "Hiranmoy Poria",
    }


]
export const AppointmentCard = (props) => {
    const [rColor, setRColor] = React.useState()
    React.useMemo(() => {
        if (props) {
            setRColor(Math.floor(Math.random() * 999999))
        }
    }, [props])
    const navigate = useNavigate()
    return (
        <React.Fragment>
            <div className='profile_card_as_link' style={{ backgroundColor: props.background && props.background, padding: "12px 0px 0px 0px" }}>
                <div className="card">
                    <div className="profile_pic" style={{ padding: "0px 12px", width: 'calc(100% - 24px)' }}>
                        <div style={{ position: 'relative' }}>
                            <Avatar sx={{ width: 84, height: 84, fontSize: 36, bgcolor: `#${rColor}` }} className="shadow">{
                                (props.data.name && props.data.name.split(' ') && props.data.name.split(' ').length > 2) ? `${props.data.name.split(' ')[1].charAt(0).toUpperCase()}${props.data.name.split(' ')[2].charAt(0).toUpperCase()}` :
                                    (props.data.name && props.data.name.split(' ') && props.data.name.split(' ').length === 2) ? `${props.data.name.split(' ')[0].charAt(0).toUpperCase()}${props.data.name.split(' ')[1].charAt(0).toUpperCase()}` : props.data.name.charAt(0).toUpperCase()
                            }</Avatar>
                             <img src={require(`../../assets/icon/${props.data.active_status ? "verified.png" : "unverified.png"}`)} alt={props.data.active_status ? "Verified" : "Unverified"} style={{ width: 32, height: 32, boxShadow: 'none', padding: 0, background: 'none',position:'absolute',bottom:-4,right:-4 }} />
                        </div>
                    </div>
                    <div className={props.view === 'grid' ? "gridview_card_content" : "listview_card_content"}>
                        <div className='content_item' style={{ padding: "0px 12px",marginTop:10 }}>
                            <div className='title' style={{ display: 'flex',alignItems: 'center'}}>
                                <span onClick={() => { navigate(`/clinic/details/${props.data.id}`) }}>{props.data.name}</span>
                            </div>
                            <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflowX: 'hidden', color: 'gray' }}>{props.data.address}</span>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: 'center', }}>
                            <div style={{ display: "flex", justifyContent: "center", padding: "8px 12px", backgroundColor: "#17BA86", alignItems: "center", borderBottomLeftRadius: "6px", borderTopRightRadius: "6px", gap: "8px" }}>
                                <img style={{ height: 25, width: 25 }} src={require('../../assets/icon/growth.png')} alt="growth percentage" />
                                <p style={{ fontSize: "18px", color: "white", margin: 0 }}>+76%</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}