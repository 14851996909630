
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const privacyAddSlice = createSlice({
    name: "privacy_add",
    initialState,
    reducers: {
        add: (state, action) => {
            state.isFetching = true;
        },
        updateStatus: (state) => {
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        reset(state) {
            for(let key in state.data)
            {
                delete state.data[key];
            }
        },
        failed(state, action) {
            state.message = action.payload
        }
    },
});
export const privacyAddActions=privacyAddSlice.actions;
const privacyAddReducer=privacyAddSlice.reducer;
export {privacyAddReducer}