import { call, put, takeLatest } from "redux-saga/effects";
import specializationAddApi from "../../../api/specialization/specializationAddApi";
import { specializationAddActions } from "../../slice/specialization/specializationCreateSlice";

function* add(action) {
    try {
        const response = yield call(specializationAddApi.add, action.payload);
        yield put(specializationAddActions.success(response));
    } catch (error) {
        yield put(specializationAddActions.failed(error.message));
    }
}
export default function* specializationAddSaga() {
    yield takeLatest(specializationAddActions.add.type, add);
}