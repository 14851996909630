import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './style.scss'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertMsg(props) {
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={props.open} autoHideDuration={props.autoHide ? props.authHide : 4000} onClose={() => { props.onClose(true) }}>
                <Alert onClose={() => {props.onClose(true) }} severity={props.type} sx={{ width: '100%' }}>
                    {props.msgText}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
