 import './style.scss'
 import FontFamily from '@tiptap/extension-font-family'
 import TextStyle from '@tiptap/extension-text-style'
 import Underline from '@tiptap/extension-underline'
 import { EditorContent, useEditor } from '@tiptap/react'
 import StarterKit from '@tiptap/starter-kit'
 import { Color } from '@tiptap/extension-color'
 import React, { useCallback } from 'react'
 import TextAlign from '@tiptap/extension-text-align'
 import { Button, CircularProgress } from '@mui/material'
 import { useDispatch } from 'react-redux'
 import Link from '@tiptap/extension-link'
 import Highlight from '@tiptap/extension-highlight'
 import { aboutUpdateActions } from '../../redux/slice/about/aboutUpdateSlice'
 import { termsAddActions } from '../../redux/slice/terms/termsAddSlice'
 import { useSelector } from 'react-redux'
 import aboutAddSelector from '../../redux/selector/about/aboutAddSelector'
 import { aboutAddActions } from '../../redux/slice/about/aboutAddSlice'
 import { termsUpdateActions } from '../../redux/slice/terms/termsUpdateSlice'
 import aboutEditSelector from '../../redux/selector/about/aboutEditSelector'
 import termsAddSelector from '../../redux/selector/terms/termsAddSelector'
 import TermsUpdateSelector from '../../redux/selector/terms/termsUpdateSelector'
 import TaskItem from '@tiptap/extension-task-item'
 import TaskList from '@tiptap/extension-task-list'
 import MenuItem from '@mui/material/MenuItem';
 import FormControl from '@mui/material/FormControl';
 import Select from '@mui/material/Select';
 import { Brush } from '../../assets/svgicons/Svgicons'
 import CustomDialog from '../../pages/settings/CustomDialog'
 import { privacyAddActions } from '../../redux/slice/privacy/privacyAddSlice'
 import { privacyUpdateActions } from '../../redux/slice/privacy/privacyUpdateSlice'
 import PrivacyAddSelector from '../../redux/selector/privacy/privacyAddSelector'
 import PrivacyUpdateSelector from '../../redux/selector/privacy/privacyUpdateSelector'
import AlertMsg from '../alert/AlertMsg'

 const MenuBar = ({ editor }) => {
   const [textType, setTextType] = React.useState("normal text")
   const [color, setColor] = React.useState('#000000')
   const [fontFamily, setFontFamily] = React.useState('Inter')
   const [bgColor, setBgColor] = React.useState('#000000')
   const handleChange = (event) => {
     setTextType(event.target.value);
     event.target.value === "normal text" && editor.chain().focus().setParagraph().run(); event.target.value === "heading1" && editor.chain().focus().toggleHeading({ level: 1 }).run();
     event.target.value === "heading2" && editor.chain().focus().toggleHeading({ level: 2 }).run(); event.target.value === "heading3" && editor.chain().focus().toggleHeading({ level: 3 }).run();
     event.target.value === "heading4" && editor.chain().focus().toggleHeading({ level: 4 }).run(); event.target.value === "heading5" && editor.chain().focus().toggleHeading({ level: 5 }).run();
     event.target.value === "heading6" && editor.chain().focus().toggleHeading({ level: 6 }).run()
   };
   const fontFamilyChange = (event) => {
     setFontFamily(event.target.value)
     event.target.value === "Inter" && editor.chain().focus().setFontFamily('Inter').run(); event.target.value === "Comic Sans" && editor.chain().focus().setFontFamily('Comic Sans MS, Comic Sans').run();
     event.target.value === "serif" && editor.chain().focus().setFontFamily('serif').run(); event.target.value === "monospace" && editor.chain().focus().setFontFamily('monospace').run();
     event.target.value === "cursive" && editor.chain().focus().setFontFamily('cursive').run();
   }
   const setLink = useCallback(() => {
     const previousUrl = editor.getAttributes('link').href
     const url = window.prompt('URL', previousUrl)
     if (url === null) {
       return
     }
     if (url === '') {
       editor.chain().focus().extendMarkRange('link').unsetLink()
         .run()
       return
     }
     editor.chain().focus().extendMarkRange('link').setLink({ href: url })
       .run()
   }, [editor])
   React.useEffect(() => { }, [textType, fontFamily])
   if (!editor) {
     return null;
   }
   return (
     <div className="menubar" >
       <div className="menubar_second_child">
         <FormControl variant='standard' sx={{ minWidth: 140, minHeight: 20, padding: 0, fontSize: 15 }}>
           <Select
             value={textType}
             displayEmpty
             inputProps={{ 'aria-label': 'Without label' }}
             onChange={(event) => {
               handleChange(event);
             }}
           >
             <MenuItem value="normal text">
               normal text
             </MenuItem>
             <MenuItem value={"heading1"}>heading1</MenuItem>
             <MenuItem value={"heading2"}>heading2</MenuItem>
             <MenuItem value={"heading3"}>heading3</MenuItem>
             <MenuItem value={"heading4"}>heading4</MenuItem>
             <MenuItem value={"heading5"}>heading5</MenuItem>
             <MenuItem value={"heading6"}>heading6</MenuItem>
           </Select>
         </FormControl>
       </div>
       <div className="menubar_second_child ">
         <FormControl variant='standard' sx={{ minWidth: 140, minHeight: 20, padding: 0, fontSize: 15, color: '#17BA86' }}>
           <Select
             value={fontFamily}
             displayEmpty
             inputProps={{ 'aria-label': 'Without label' }}
             onChange={(event) => {
               fontFamilyChange(event);
             }}
           >
             <MenuItem value={"Inter"}>Inter</MenuItem>
             <MenuItem value={"Comic Sans"}>Comic Sans</MenuItem>
             <MenuItem value={"serif"}>serif</MenuItem>
             <MenuItem value={"monospace"}>monospace</MenuItem>
             <MenuItem value={"cursive"}>cursive</MenuItem>
           </Select>
         </FormControl>

       </div>
       <div className='menubar_first_child'>
         <button
           style={{ fontWeight: 'bold' }}
           onClick={() => editor.chain().focus().toggleBold().run()}
           disabled={
             !editor.can()
               .chain()
               .focus()
               .toggleBold()
               .run()
           }
           className={editor.isActive('bold') ? 'is-active' : ''}
         >
           B
         </button>
         <button
           style={{ fontStyle: 'italic' }}
           onClick={() => editor.chain().focus().toggleItalic().run()}
           disabled={
             !editor.can()
               .chain()
               .focus()
               .toggleItalic()
               .run()
           }
           className={editor.isActive('italic') ? 'is-active' : ''}
         >
           I
         </button>
         <button
           style={{ textDecoration: 'underline' }}
           onClick={() => editor.chain().focus().toggleUnderline().run()}
           className={editor.isActive('underline') ? 'is-active' : ''}
         >
           U
         </button>
         <button
           onClick={() => editor.chain().focus().toggleStrike().run()}
           style={{ textDecoration: "line-through", width: '30px' }}
           disabled={
             !editor.can()
               .chain()
               .focus()
               .toggleStrike()
               .run()
           }
           className={editor.isActive('strike') ? 'is-active' : ''}

         >
           abc
         </button>
         <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginTop: '2px', height: 25, width: 25 }}>
           <p style={{ margin: 0, color: '#17BA86', fontWeight: 600 }}>A</p>
           <div style={{ backgroundColor: color, height: 6, width: 25, position: 'absolute', bottom: 0, left: 0, border: '1px solid #000000', borderRadius: '2px' }}></div>
           <input
             type="color"
             onInput={event => { editor.chain().focus().setColor(event.target.value).run(); setColor(event.target.value) }}
             value={editor.getAttributes('textStyle').color}
             style={{ position: 'absolute' }}
           />
         </div>
         <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginTop: '2px', height: 25, width: 25 }}>
           <Brush color={bgColor} />
           <input
             type="color"
             onInput={event => { editor.chain().focus().toggleHighlight({ color: event.target.value }).run(); setBgColor(event.target.value) }}
             value={bgColor}
             style={{ position: 'absolute' }}
             className={editor.isActive('highlight', { color: bgColor }) ? 'is-active' : ''}
           />
         </div>
         <button
           onClick={setLink}
           className={editor.isActive('link') ? 'is-active' : ''}

         >
            <img src={require('../../assets/icon/tiptap_icons/link.png')} alt='icon' />
         </button>
       </div>
       <div className='menubar_first_child'>

         <button
           onClick={() => editor.chain().focus().toggleBulletList().run()}
           className={editor.isActive('bulletList') ? 'is-active' : ''}
         >
           <img src={require('../../assets/icon/tiptap_icons/bullet-list.png')} alt='icon' />
         </button>
         <button
           onClick={() => editor.chain().focus().toggleOrderedList().run()}
           className={editor.isActive('orderedList') ? 'is-active' : ''}
         >
           <img src={require('../../assets/icon/tiptap_icons/order-list.png')} alt='icon' />
         </button>
         <button
           onClick={() => editor.chain().focus().toggleTaskList().run()}
           className={editor.isActive('taskList') ? 'is-active' : ''}
         >
           <img src={require('../../assets/icon/tiptap_icons/checked-list.png')} alt='icon' />
         </button>
       </div>
       <div className='menubar_first_child'>

         <button
           onClick={() => editor.chain().focus().setTextAlign('left').run()}
           className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
         >
           <img src={require('../../assets/icon/tiptap_icons/left_alignment.png')} alt='icon' />
         </button>
         <button
           onClick={() => editor.chain().focus().setTextAlign('center').run()}
           className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
         >
           <img src={require('../../assets/icon/tiptap_icons/middle_alignment.png')} alt='icon' />
         </button>
         <button
           onClick={() => editor.chain().focus().setTextAlign('right').run()}
           className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
         >
           <img src={require('../../assets/icon/tiptap_icons/right_alignment.png')} alt='icon' />
         </button>
         <button
           onClick={() => editor.chain().focus().setTextAlign('justify').run()}
           className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
         >
           <img src={require('../../assets/icon/tiptap_icons/justify_alignment.png')} alt='icon' />
         </button>
       </div>
       <div className='menubar_first_child'>
         <button
           onClick={() => editor.chain().focus().undo().run()}
           disabled={
             !editor.can().chain().focus().undo().run()
           }
         >
           <img src={require('../../assets/icon/tiptap_icons/undo.png')} alt='icon' />
         </button>
         <button
           onClick={() => editor.chain().focus().redo().run()}
           disabled={
             !editor.can().chain().focus().redo().run()
           }
         >
           <img src={require('../../assets/icon/tiptap_icons/redo.png')} alt='icon' />
         </button>
       </div>
     </div>
   )
 }

 export const Tiptap = (props) => {
   const editor = useEditor({
     extensions: [
       StarterKit, TextStyle, Text, FontFamily, Underline, TaskList, Color,
       TaskItem.configure({
         nested: true,
       }),
       TextAlign.configure({
         types: ['heading', 'paragraph'],
       }), Highlight.configure({ multicolor: true }),Link.configure({
         openOnClick: false,
       }),
     ],
     content: props.content ? props.content : ``,
   })
   const dispatch = useDispatch()
   const aboutUpdateSelector = useSelector(aboutEditSelector)
   const aboutAdSelector = useSelector(aboutAddSelector)
   const termsAdSelector = useSelector(termsAddSelector)
   const termsUpdateSelector = useSelector(TermsUpdateSelector)
   const privacyAddSelector=useSelector(PrivacyAddSelector)
   const privacyUpdateSelector=useSelector(PrivacyUpdateSelector)
   const [processing,setProcessing]=React.useState(false)
   const [error,setError]=React.useState(false)
   const OnSubmit = () => {
     const html = editor.getHTML();
     if (props.from === "about") {
       let obj = {
         title: "About Us",
         url: "about-us",
         content: html
       }
       if (props.type === "create") {
        setError(false)
        
        if(obj?.content==="<p></p>")
       {
         obj.content=null
         setError(true)
       }
       else{
        dispatch(aboutAddActions.add(obj))
       }
         
       }
       if (props.type === "edit") {
        setError(false)
        
        if(obj?.content==="<p></p>")
       {
         obj.content=null
         setError(true)
       }
       else{
        dispatch(aboutUpdateActions.edit(obj))
       }
         
       }
     }
     if (props.from === "terms") {
       let obj = {
         title: "Terms and Condition",
         url: "terms_policies",
         content: html
       }
       if (props.type === "create") {
          setError(false)
        if(obj?.content==="<p></p>")
        {
          obj.content=null
          setError(true)
        }
        else{
          dispatch(termsAddActions.add(obj))
        }
        
       }
       if (props.type === "edit") {
        setError(false)
        
         if(obj?.content==="<p></p>")
        {
          obj.content=null
          setError(true)
        }
        else{
          dispatch(termsUpdateActions.edit(obj))
        }

       }
     }
     if (props.from === "privacy") {
       let obj = {
         title: "Privacy Policy",
         url: "privacy-policy",
         content: html
       }
       if (props.type === "create") {
        setError(false)
        
         if(obj?.content==="<p></p>")
        {
          obj.content=null
          setError(true)
        }
        else{
          dispatch(privacyAddActions.add(obj))
        }
         
       }
       if (props.type === "edit") {
        setError(false)
        
        if(obj?.content==="<p></p>")
       {
         obj.content=null
         setError(true)
       }
       else{
        dispatch(privacyUpdateActions.edit(obj))
       }
        
       }
     }
   }
   React.useEffect(() => {
     if(aboutUpdateSelector && aboutUpdateSelector.isFetching === false && aboutUpdateSelector.data.status) 
     {
       props.response(aboutUpdateSelector)
       setProcessing(false)
     }
     if(aboutUpdateSelector && aboutUpdateSelector.isFetching === true && !aboutUpdateSelector.data.status) 
     {
    
       setProcessing(true)
     }
     if(aboutAdSelector && aboutAdSelector.isFetching === false && aboutAdSelector.data.status )
     {
       props.response(aboutAdSelector)
       setProcessing(false)
     }
     if(aboutAdSelector && aboutAdSelector.isFetching === true && !aboutAdSelector.data.status )
     {
       setProcessing(true)
     }
     if(termsAdSelector && termsAdSelector.isFetching === false && termsAdSelector.data.status)
     {
       props.response(termsAdSelector)
       setProcessing(false)
     }
     if(termsAdSelector && termsAdSelector.isFetching === true && !termsAdSelector.data.status)
     {
       setProcessing(true)
     }
     if(termsUpdateSelector && termsUpdateSelector.isFetching === false && termsUpdateSelector.data.status)
     {
       props.response(termsUpdateSelector)
       setProcessing(false)
     }
     if(termsUpdateSelector && termsUpdateSelector.isFetching === true && !termsUpdateSelector.data.status)
     {
       setProcessing(true)
     } 
     if(privacyAddSelector && privacyAddSelector.isFetching === false && privacyAddSelector.data.status)
     {
       props.response(privacyAddSelector)
       setProcessing(false)
     }
     if(privacyAddSelector && privacyAddSelector.isFetching === true && !privacyAddSelector.data.status)
     {
       setProcessing(true)
     }
     if(privacyUpdateSelector && privacyUpdateSelector.isFetching === false && privacyUpdateSelector.data.status)
     {
       props.response(privacyUpdateSelector)
       setProcessing(false)
     }
     if(privacyUpdateSelector && privacyUpdateSelector.isFetching === true && !privacyUpdateSelector.data.status)
     {
       setProcessing(true)
     }
   },
     [aboutUpdateSelector.isFetching, aboutAdSelector.isFetching, termsAdSelector.isFetching, termsUpdateSelector.isFetching,privacyAddSelector.isFetching,privacyUpdateSelector.isFetching])
   return (
     <React.Fragment>
       <div style={{ width: '100%', borderRadius: '6px', backgroundColor: 'white', boxShadow: '3px 3px 10px #d9d9d9, -3px -3px 10px #ffffff' }}>

         <div className='menubar_main'>
           <MenuBar editor={editor} />
         </div>
         <div className='tiptap_content'>
           <div className='content'>
             <EditorContent editor={editor} />
           </div>
         </div>
         <div className="tiptap_modal_footer">
         <Button variant='contained' color='error' onClick={() => { props.onClose() }}>CANCEL</Button>
          <Button variant='contained' color={props?.type==="edit"?"primary":"success"} onClick={() => { OnSubmit() }}>{props?.type==="edit"?"UPDATE":"SUBMIT"}</Button>
          </div>
       </div>
       {
         <CustomDialog open={processing} children={<div style={{padding:32,backgroundColor:'white',borderRadius:6}}><CircularProgress /></div>}/>
       }
       {
        error&&<AlertMsg autoHide="true" authHide='2000' type={"error"} open={error} onClose={() => { setError(false) }} msgText={"Please enter some text"} />
       }
     </React.Fragment>
   )
 }