import React, { useEffect, useState } from "react";
import { BackButton } from "../../components/buttons/Buttons";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import './style.scss'
import { useDispatch, useSelector } from "react-redux";
import { specializationAddActions } from "../../redux/slice/specialization/specializationCreateSlice";
import SpecializationAddSelector from "../../redux/selector/specialization/specializationAddSelector";
import SpecializationGetAllSelector from "../../redux/selector/specialization/specializationGetAllSelector";
import { specializationGetAllActions } from "../../redux/slice/specialization/specializationGetAllSlice";
import AlertMsg from "../../components/alert/AlertMsg";
import CustomDialog from "../settings/CustomDialog";
import { specializationDeleteActions } from "../../redux/slice/specialization/specializationDeleteSlice";
import SpecializationDeleteSelector from "../../redux/selector/specialization/specializationDeleteSelector";
import { specializationUpdateActions } from "../../redux/slice/specialization/specializationUpdateSlice";
import SpecializationUpdateSelector from "../../redux/selector/specialization/specializationUpdateSelector";



export const Specialization = () => {
    // const [addModalOpen, setAddModalOpen] = React.useState(false)
    const [modalHeader, setModalHeader] = React.useState()
    const [deleteMsg, setDeleteMsg] = React.useState(false)
    const [addData, setAddData] = React.useState()
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = React.useState(false)
    const [type, setType] = React.useState()
    const [response, setResponse] = React.useState()
    const selector = useSelector(SpecializationGetAllSelector)
    const deleteSelector = useSelector(SpecializationDeleteSelector)
    const updateSelector = useSelector(SpecializationUpdateSelector)
    const createSelector = useSelector(SpecializationAddSelector)
    const [createMsg, setCreateMsg] = React.useState(false)
    const [processing, setProcessing] = React.useState()
    const [updateMsg, setUpdateMsg] = React.useState(false)


    const handleChange = (e) => {
        setAddData({
            ...addData,
            [e.target.name]: e.target.value
        })
    }

    React.useEffect(() => {
        dispatch(specializationGetAllActions.getAll())

    }, [dispatch, type, createSelector, updateSelector, deleteSelector])



    const getResponse = (props) => {
        
        setResponse(props)
        if (props.data.message === "Update successful") {
            setDeleteMsg(false)
            setCreateMsg(false)
            setUpdateMsg(true)
            setProcessing(false)
            dispatch(specializationUpdateActions.reset())
            setAddData({})
        }
        if (props.data.message === "Specialization add successful") {
            console.log("called")
            setDeleteMsg(false)
            setCreateMsg(true)
            setUpdateMsg(false)
            setProcessing(false)
            dispatch(specializationAddActions.reset())
        }
        if (props.data.message === "delete successful") {
            setCreateMsg(false)
            setUpdateMsg(false)
            setDeleteMsg(true)
            setProcessing(false)
            dispatch(specializationDeleteActions.reset())

        }

    }
    const create = () => {
        dispatch(specializationAddActions.add(addData))
        setType("create")
        setModalOpen(false)

    }
    const update = () => {
    console.log("payload:",addData)
        dispatch(specializationUpdateActions.edit(addData))
        setType('update')
        setModalOpen(false)

    }

    const deleteData = (value) => {
        dispatch(specializationDeleteActions.specializationDelete({ id: value }))
        setType("delete")

    }

    const handleUpdate=(data)=>{
        setType("update")
        console.log(data)
        setAddData(data)
        setModalHeader("Update Specialization")
        setModalOpen(true);
    }

    const handleCreate=()=>{
        setModalOpen(true);
        setModalHeader("Create New Specialization")
        setType("New")
        setAddData({
            specialization:""
        })
    }


    useEffect(() => {
        setAddData({})
        if (createSelector?.data?.status === true) {
            getResponse(createSelector)
        }


        if (updateSelector?.data?.status === true) {

            getResponse(updateSelector)
        }
        if (deleteSelector?.data?.status === true) {
            getResponse(deleteSelector)
        }


    }, [selector, createSelector, deleteSelector, updateSelector])
    React.useEffect(() => {
        if (createSelector?.isFetching || updateSelector?.isFetching || deleteSelector?.isFetching) {
            setProcessing(true)
        }
    },
        [createSelector?.isFetching, updateSelector?.isFetching, deleteSelector?.isFetching])





    return (
        <React.Fragment>

            <div className="top_header">
                <BackButton />
            </div>
            <div className='top_header' >
                <div className='left'>
                    <h1>Specialization</h1>
                </div>
                <div className='right'>
                    <Button style={{ color: "white", background: '#17BA86', padding: 6, borderRadius: 6, width: 100 }} to="/clinic/create" onClick={() =>handleCreate()}>Add</Button>
                </div>
            </div>
            {selector?.data?.isFetching ?
                <div style={{ width: '100%', display: 'flex', marginTop: '50px', alignItems: "center", justifyContent: "center" }}>

                    <CircularProgress />
                </div> :
                selector?.data?.rows?.length > 0 ?
                    <div className ="sp_grid">

                        {

                            selector?.data?.rows?.map((elem) => {
                                return (
                                    <div className="specializationChip" >
                                        <p>{elem?.specialization}</p>
                                        <div className="buttonGroup">
                                            <button style={{ background: "red" }} onClick={() => { deleteData(elem?.id) }}>
                                                <DeleteForeverIcon color="white" />
                                            </button>
                                            <button style={{ background: "#00b3fe" }} onClick={() =>handleUpdate(elem) }>
                                                <UpdateIcon />
                                            </button>
                                        </div>
                                    </div>
                                )
                            })

                        }
                    </div> 
                    :<div style={{ width: '100%', display: 'flex', alignItems: "center", justifyContent: "center" }}>
                <div className="no_data_found_wrap">
                    <div className="no_data_found_img_wrap">
                        <img src={require('../../assets/img/no_data_found.png')} className="no_data_found_img" alt="no data found" />
                    </div>
                    <div className="no_data_found_text_wrap" style={{ paddingBottom: 16 }}>
                        <span>{"You Don't Have Any Specialization "}</span>
                    </div>
                </div>
            </div>
                }


            {modalOpen &&
                <Dialog
                    fullWidth
                    open={modalOpen}
                    onClose={() => {setModalOpen(false);setAddData({})}}
                    maxWidth="sm"
                    aria-labeledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {modalHeader}
                    </DialogTitle>
                    <DialogContent>
                        
                        <DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Specialization"
                                type="Text"
                                fullWidth
                                id="fullWidth"
                                value={addData?.specialization??""}
                                name="specialization"
                                onChange={handleChange}
                            />
                            <div style={{width:'100%',display:'flex',justifyContent:"flex-end",gap:'20px',alignItems:'center',marginTop:'20px'}}>
                            <Button autoFocus onClick={() => setModalOpen(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit" color="primary" onClick={() => { type==="update" ? update() : create() }} autoFocus>
                            {type==="update"?"Update":"Submit"}
                        </Button>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    {/* <DialogActions>
                       
                    </DialogActions> */}
                </Dialog>
            }
            {updateMsg && response.data.status && response.data.message && <AlertMsg autoHide="true" authHide='2000' type={response.data.status === true ? "success" : "error"} open={updateMsg} onClose={() => { setUpdateMsg(false) }} msgText={response.data.message} />}
            {deleteMsg && response.data.status && response.data.message && <AlertMsg autoHide="true" authHide='2000' type={response.data.status === true ? "success" : "error"} open={deleteMsg} onClose={() => { setDeleteMsg(false) }} msgText={response.data.message} />}
            {createMsg && response.data.status && response.data.message && <AlertMsg autoHide="true" authHide='2000' type={response.data.status === true ? "success" : "error"} open={createMsg} onClose={() => { setCreateMsg(false) }} msgText={response.data.message} />}
            {
                <CustomDialog open={processing} children={<div style={{ padding: 32, backgroundColor: 'white', borderRadius: 6 }}><CircularProgress /></div>} />
            }





        </React.Fragment>
    )
}