
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const specializationAddSlice = createSlice({
    name: "specializationAdd",
    initialState,
    reducers: {
        add: (state, action) => {
            state.isFetching = true;
        },
        updateStatus: (state) => {
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        reset(state) {
            Object.assign(state, initialState)
        },
        failed(state, action) {
            state.message = action.payload
        }
    },
});
export const specializationAddActions=specializationAddSlice.actions;
const specializationAddReducer=specializationAddSlice.reducer;
export {specializationAddReducer}