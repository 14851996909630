import { call, put, takeLatest } from "redux-saga/effects";
import privacyDeleteApi from "../../../api/privacy/privacyDeleteApi";
import { privacyDeleteActions } from "../../slice/privacy/privacyDelete";

function* Delete(action) {
    try {
        const response = yield call(privacyDeleteApi.delete, action.payload);
        yield put(privacyDeleteActions.success(response));
    } catch (error) {
        yield put(privacyDeleteActions.failed(error.message));
    }
}
export default function* privacyDeleteSaga() {
    yield takeLatest(privacyDeleteActions.delete.type, Delete)
}