import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const privacyDeleteSlice = createSlice({
    name: "privacy_delete",
    initialState,
    reducers: {
        delete: (state, action) => {
            state.isFetching = true;
        },
        updateStatus: (state) => {
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        reset(state) {
            for(let key in state.data)
            {
                delete state.data[key];
            }
        },
        failed(state, action) {
            state.message = action.payload
        }
    },
});


// Actions

export const privacyDeleteActions = privacyDeleteSlice.actions;

// Reducer
const privacyDeleteReducer = privacyDeleteSlice.reducer;



export { privacyDeleteReducer };
