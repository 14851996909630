import { call, put, takeLatest } from "redux-saga/effects";
import appoinmentApi from "../../../api/appoinment/appoinmentApi";
import { appoinmentTableActions } from "../../slice/appoinment/appoinmentTableSlice";
function* getAllAppoinment(action) {
    try {
        const response = yield call(appoinmentApi.doctorAppoinments, action.payload)
        yield put(appoinmentTableActions.getAll(response))
    } catch (error) {
        yield put(appoinmentTableActions.failed(error.message))
    }
}
export default function* appoinmentTableSaga() {
    yield takeLatest(appoinmentTableActions.getAll.type, getAllAppoinment)
}