import { urls } from '../urls'
import axiosClient from '../axiosClient';
export const doctorApi = {
    getAll: (params) => {
        return (
            axiosClient.get(`${urls.GET_ALL_DOCTOR}`, { params })
        )
    },
    getOne: (params) => {
        return axiosClient.get(`${urls.GET_SINGLE_DOCTOR}/${params}`)
    },
    getAllAppoiments: (params) => {
        return axiosClient.get(`${urls.GET_ALL_APPOINMENTS_REF_DOCTOR}`, { params })
    },
    searchDoctors: (params) => {
        return axiosClient.get(`${urls.DOCTOR_SEARCH}`, { params })
    },
    searchAppoinments: (params) => {
        return axiosClient.get(`${urls.DOCTOR_APPOINMENTS_SEARCH}`, { params })
    }
}