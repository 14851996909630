import React from "react";
import { BaseRouting } from "./routing/BaseRouting";

function App() {
  return (
    <React.Fragment>
      <BaseRouting />
    </React.Fragment>
  );
}

export default App;
