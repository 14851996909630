import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Table from "../../components/table/Table";
import { ClinicColumns } from "../../config/Config";
import clinicSelector from "../../redux/selector/clinic/clinicSelector";
import { clinicActions } from "../../redux/slice/clinic/clinicSlice";
import { CLINIC_PUBLIC_ADD } from "../../routing/clinic/ClinicRouting";
import CircularProgress from '@mui/material/CircularProgress';
import '../home/home.scss'

export const Clinics = () => {
    const dispatch = useAppDispatch()
    const selector = useAppSelector(clinicSelector)
    const navigate = useNavigate()
    const [rows, setRows] = React.useState()
    const [searchVal, setSearchVal] = React.useState()
    React.useMemo(() => {
        if (selector && selector.data.rows instanceof Array && selector.data.rows.length > 0) {
            setRows(selector.data.rows)
        }
        // eslint-disable-next-line
    }, [selector, searchVal])
    React.useEffect(() => {
        dispatch(clinicActions.getAll({ 'limit': 5, 'page': 1 }))
    }, [dispatch])
    const [windowDimensions, setWindowDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth });
    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions({ height: window.innerHeight, width: window.innerWidth });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    const onSearchChange = (val) => {
        if (val.length > 0) {
            dispatch(clinicActions.searchClinic({ 'q': val, 'page': 1, 'limit': 5 }))
            setSearchVal(val)
        }
        else {
            dispatch(clinicActions.getAll({ 'limit': 5, 'page': 1 }))
            setSearchVal()
        }
    }
    return (
        <React.Fragment>
            {(rows && rows instanceof Array && rows.length > 0) ?
                <div className="table_wrap">
                    <Table
                        columns={ClinicColumns}
                        rows={rows}
                        tableAction={true}
                        addUrl={CLINIC_PUBLIC_ADD}
                        disableCheck={true}
                        totalRows={selector.data.total_rows}
                        onPageChange={(page, ps) => { searchVal ? dispatch(clinicActions.searchClinic({ 'q': searchVal, 'page': page, 'limit': ps })) : dispatch(clinicActions.getAll({ 'limit': ps, 'page': page })) }}
                        onPageSizeChange={(page, pageSize) => { searchVal ? dispatch(clinicActions.searchClinic({ 'q': searchVal, 'page': page, 'limit': pageSize })) : dispatch(clinicActions.getAll({ 'limit': pageSize, 'page': page })) }}
                        onSearch={onSearchChange}
                    />
                </div> :
                <React.Fragment>
                    {(selector && selector.isFetching === false && selector.status === false) &&
                        <div className="no_data_found_wrap">
                            <div className="no_data_found_img_wrap">
                                <img src={require('../../assets/img/no_data_found.png')} className="no_data_found_img" alt="no data found" />
                            </div>
                            <div className="no_data_found_text_wrap">
                                <span>No clinic data found....</span>
                                <Button variant="contained" size="small" onClick={() => {
                                    navigate(CLINIC_PUBLIC_ADD);
                                }} sx={{ textTransform: 'capitalize', ml: 2 }}>Create One</Button>
                            </div>
                        </div>
                    }
                </React.Fragment>
            }
            {
                selector && selector.isFetching &&
                <div style={{ height: windowDimensions.height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            }
        </React.Fragment>
    );
}