import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        edit: (state) => {
            state.isFetching = true;
        },
        delelePatient: (state) => {
            state.isFetching = true
        },
        deactivePatient: (state) => {
            state.isFetching = true
        },
        deleteClinic:(state)=>{
            state.isFetching = true
        },
        deactiveClinic:(state)=>{
            state.isFetching = true
        },
        updateEmail:(state)=>{
            state.isFetching = true
        },
        verifyEmail:(state)=>{
            state.isFetching = true
        },
        adminUpdatePassword:(state)=>{
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        failed(state, action) {
            state.data = action.payload
            state.isFetching = false
        },
        editSuccess(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        editFailed(state, action) {
            state.message = action.payload
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    },
});

// Actions
export const adminActions = adminSlice.actions;

// Reducer
const adminReducer = adminSlice.reducer;


export { adminReducer };