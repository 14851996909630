import './payments.scss'
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import HomeIcon from '@mui/icons-material/Home';
import { Divider } from '@mui/material';
import { CustomizedSteppers } from '../appoinments/AppoinmentsDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import paymentSelector from '../../redux/selector/payment/paymentSelector';
import { paymentActions } from '../../redux/slice/payment/paymentSlice';
import { BackButton } from '../../components/buttons/Buttons';
import { slashFormatDate } from '../../utils';

const steps = [
    'Booked',
    'Payment',
    'Confirmation',
];
export const PaymentsDetails = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const selector = useSelector(paymentSelector)
    React.useEffect(() => {
        if (params && params.id) {
            dispatch(paymentActions.paymentSingleGet(params.id))
        }
    }, [params, dispatch])
    const [appoinData, setAppoinData] = React.useState()
    React.useEffect(() => {
        if (selector && selector.data && typeof selector.data === 'object') {
            setAppoinData(selector.data)
        }
    }, [selector])
    return (
        <div className="payment_details_wrap">
            <BackButton />
            <div className="payment_header">
                <div className="loader">
                    <img src={require('../../assets/img/payment_verified.png')} alt="payment_success" />
                </div>
            </div>
            <div className='payment_body'>
                {appoinData && appoinData.appointment &&
                    <div className="payment_stepper">
                       <CustomizedSteppers steps={[
                    {
                        "item": "Booked",
                        "value":  appoinData?.appointment?.req_date?slashFormatDate(appoinData?.appointment?.req_date):"",
                        "status": appoinData?.appointment?.req_date ? true : false
                    },
                    {
                        "item": "Accepted",
                        "value": appoinData?.appointment?.booked_date ? slashFormatDate(appoinData?.appointment?.booked_date) : "",
                        "status": appoinData?.appointment?.booked_date ? true : false
                    },
                    {
                        "item": `Payment ${appoinData?.appointment?.booked_date && appoinData?.payment ? `(${appoinData?.payment?.clinic?.fees}/-)` : ''}`,
                        "value": appoinData?.appointment?.booked_date && (appoinData?.payment && (appoinData?.payment?.clinic?.status === "Paid" && appoinData?.payment?.date&&slashFormatDate(appoinData?.payment?.date))),
                        "status": appoinData?.appointment?.booked_date && (appoinData?.payment && ((appoinData?.appointment?.booked_date && appoinData?.payment?.clinic?.status==="Paid") ? true : false)),
                    },
                    {
                        "item":`Completed ${appoinData?.appointment?.booked_date&&appoinData?.payment?`(${appoinData?.payment?.doctor?.fees}/-)`:""}`,
                        "value": appoinData?.appointment?.appointment_date ? slashFormatDate(appoinData?.appointment?.appointment_date) : "",
                        "status": appoinData.payment && ((appoinData.payment.doctor.status === "Paid" && appoinData.payment.payment_status === "Success") && true)
                    },
                ]} />
                    </div>}
            </div>
            <div className="payment_body_details">
                <h1>Payment Details</h1>
                <div className="payment_body_details_wrap">
                    <div className="payment_body_details_wrap_left">
                        <h2 className='title'>Bank Info</h2>
                        <div className="payment_body_details_wrap_left_inner">
                            <BankInfoList data={appoinData?.payment} />
                        </div>
                        {appoinData && appoinData.patient &&
                            <React.Fragment>
                                <h2 className='title'>Patient Info</h2>
                                <div className="payment_body_details_wrap_left_inner">
                                    <PatientInfoList data={appoinData?.patient} />
                                </div>
                            </React.Fragment>}
                            {appoinData && appoinData.doctor &&
                            <React.Fragment>
                                <h2 className='title'>Doctor Info</h2>
                                <div className="payment_body_details_wrap_left_inner">
                                    <PatientInfoList data={appoinData?.doctor} />
                                </div>
                            </React.Fragment>}
                            {appoinData && appoinData.clinic &&
                            <React.Fragment>
                                <h2 className='title'>Clinic Info</h2>
                                <div className="payment_body_details_wrap_left_inner">
                                    <PatientInfoList data={appoinData?.clinic} />
                                </div>
                            </React.Fragment>}
                    </div>
                    {/* <div className="payment_body_details_wrap_right">
                        <h2 className='title'>Booking Info</h2>
                        <div className="payment_body_details_wrap_right_inner">
                            <div className="flex">
                                <span className='left'>Order Total</span>
                                <span className='right'>₹500</span>
                            </div>
                            <div className="flex">
                                <span className='left'>Taxes</span>
                                <span className='right'><span className="plus">+</span> ₹50</span>
                            </div>
                            <div className="flex">
                                <span className='left'>Commission</span>
                                <span className='right'><span className="plus">+</span> ₹25</span>
                            </div>
                            <div className="flex">
                                <span className='left'>Transaction fees</span>
                                <span className='right'><span className="plus">+</span> ₹5</span>
                            </div>
                            <Divider sx={{ marginTop: '12px', marginBottom: '6px' }} />
                            <div className="flex">
                                <span className='left bold'>Order Total :</span>
                                <span className='right bold'>₹580</span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

function PaymentStepper() {
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={steps.length} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel><span style={{ fontWeight: 'bold' }}>{label}</span><br /><span>{"22 Sep, 2022"}</span></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}

const BankInfoList=({data})=>{
    return (
        <React.Fragment>
            <div className="flex">
            
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem sx={{ borderRadius: '6px', backgroundColor: 'whitesmoke' }}>
                        <ListItemAvatar>
                            <Avatar>
                                <CreditCardIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Transaction Id" secondary={data?.transaction_id??""} />
                    </ListItem>
                </List>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem sx={{ borderRadius: '6px', backgroundColor: 'whitesmoke' }}>
                        <ListItemAvatar>
                            {/* <Avatar>
                                <AccountBalanceIcon />
                            </Avatar> */}
                            {
                                data?.clinic?.status==="Paid"?<Avatar src='https://static.vecteezy.com/system/resources/thumbnails/010/829/962/small/green-tick-checkbox-illustration-isolated-free-png.png' />:
                                <Avatar src='https://static-00.iconduck.com/assets.00/pending-icon-512x504-9zrlrc78.png' />
                            }
                        </ListItemAvatar>
                        <ListItemText primary="Clinic Fees" secondary={data?.clinic?.fees} />
                    </ListItem>
                </List>
            </div>
            <div className="flex">
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem sx={{ borderRadius: '6px', backgroundColor: 'whitesmoke' }}>
                        <ListItemAvatar>
                            {/* <Avatar>
                                <CurrencyRupeeIcon />
                            </Avatar> */}
                             {
                                data?.doctor?.status==="Paid"?<Avatar src='https://static.vecteezy.com/system/resources/thumbnails/010/829/962/small/green-tick-checkbox-illustration-isolated-free-png.png' />:
                                <Avatar src='https://static-00.iconduck.com/assets.00/pending-icon-512x504-9zrlrc78.png' />
                            }
                        </ListItemAvatar>
                        <ListItemText primary="Doctor Fees" secondary={data?.doctor?.fees} />
                    </ListItem>
                </List>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem sx={{ borderRadius: '6px', backgroundColor: 'whitesmoke' }}>
                        <ListItemAvatar>
                            {/* <Avatar src={require('../../assets/icon/stripe.png')} /> */}
                            <Avatar src="https://cdn-images-1.medium.com/max/184/1*NKfnk1UF9xGoR0URBEc6mw@2x.png" />
                        </ListItemAvatar>
                        <ListItemText primary="Payment Gateway" secondary="Razor Pay" />
                    </ListItem>
                </List>
            </div>
        </React.Fragment>
    );
}

function PatientInfoList({ data }) {
    const navigate = useNavigate()
    return (
        <React.Fragment>
            <div className="flex">
                {data && data.name && data.id && <List sx={{ width: '100%', bgcolor: 'background.paper',cursor:'pointer' }} onClick={() => { navigate(`/patients/details/${data.id}`) }}>
                    <ListItem sx={{ borderRadius: '6px', backgroundColor: 'whitesmoke' }}>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Name" secondary={data.name} />
                    </ListItem>
                </List>}
                {data && data.email && <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem sx={{ borderRadius: '6px', backgroundColor: 'whitesmoke' }}>
                        <ListItemAvatar>
                            <Avatar>
                                <EmailIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Email" secondary={data.email} />
                    </ListItem>
                </List>}
            </div>
            <div className="flex">
                {data && data.contact_no && <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem sx={{ borderRadius: '6px', backgroundColor: 'whitesmoke' }}>
                        <ListItemAvatar>
                            <Avatar>
                                <CallIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Phone No" secondary={data.contact_no} />
                    </ListItem>
                </List>}
                {data && data.address && <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem sx={{ borderRadius: '6px', backgroundColor: 'whitesmoke' }}>
                        <ListItemAvatar>
                            <Avatar>
                                <HomeIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Address" secondary={data.address} />
                    </ListItem>
                </List>}
            </div>
        </React.Fragment>
    );
}