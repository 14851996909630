import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const appoinmentSlice = createSlice({
    name: "appoinment",
    initialState,
    reducers: {
        getAll: (state) => {
            state.isFetching = true
        },
        getOne: (state) => {
            state.isFetching = true
        },
        search: (state) => {
            state.isFetching = true
        },
        searchDoctorAppoinments: (state) => {
            state.isFetching = true
        },
        performanceDoctor: (state) => {
            state.isFetching = true
        },
        doctorAppoinmentCount: (state) => {
            state.isFetching = true
        },
        performancePatient: (state) => {
            state.isFetching = true
        },
        patientAppoinmentsGetAll: (state) => {
            state.isFetching = true
        },
        patientAppoinmentsGetAllSearch: (state) => {
            state.isFetching = true
        },
        patientAppoinmentsCount: (state) => {
            state.isFetching = true
        },
        clinicAppointmentPerformance: (state) => {
            state.isFetching = true
        },
        clinicAppointmentGetAll: (state) => {
            state.isFetching = true
        },
        clinicAppointmentGetAllSearch: (state) => {
            state.isFetching = true
        },
        clinicAppointmentCount: (state) => {
            state.isFetching = true
        },
        appointmentGetAll: (state) => {
            state.isFetching = true
        },
        appointmentGetAllSearch: (state) => {
            state.isFetching = true
        },
        appointmentGetSingle: (state) => {
            state.isFetching = true
        },
        appointmentPerformance: (state) => {
            state.isFetching = true
        },
        appointmentCount: (state) => {
            state.isFetching = true
        },
        appointmentSingleGet:(state)=>{
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        failed(state, action) {
            state.message = action.payload
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    },
});

// Actions
export const appoinmentActions = appoinmentSlice.actions;

// Reducer
const appoinmentReducer = appoinmentSlice.reducer;


export { appoinmentReducer };