
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const privacySlice = createSlice({
    name: "privacy",
    initialState,
    reducers: {
        getOne: (state, action) => {
            state.isFetching = true;
        },
        updateStatus: (state) => {
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        reset(state) {
            Object.assign(state, initialState)
        },
        failed(state, action) {
            state.message = action.payload
        }
    },
});
export const privacyActions=privacySlice.actions;
const privacyReducer=privacySlice.reducer;
export {privacyReducer}