import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        getAll: (state) => {
            state.isFetching = true
        },
        search: (state) => {
            state.isFetching = true
        },
        performanceDoctor: (state) => {
            state.isFetching = true
        },
        doctorPaymentCount: (state) => {
            state.isFetching = true
        },
        searchDoctorPayments: (state) => {
            state.isFetching = true
        },
        performancePayment: (state) => {
            state.isFetching = true
        },
        performancePaymentGetAll: (state) => {
            state.isFetching = true
        },
        performancePaymentGetAllSearch: (state) => {
            state.isFetching = true
        },
        clinicPaymentGetAll:(state)=>{
            state.isFetching = true
        },
        clinicPaymentGetAllSearch:(state)=>{
            state.isFetching = true
        },
        clinicPaymentPerformance:(state)=>{
            state.isFetching = true
        },
        clinicPaymentCount:(state)=>{
            state.isFetching = true
        },
        paymentGetAll:(state)=>{
            state.isFetching = true
        },
        paymentGetAllSearch:(state)=>{
            state.isFetching = true
        },
        paymentPerformance:(state)=>{
            state.isFetching = true
        },
        paymentCount:(state)=>{
            state.isFetching = true
        },
        paymentSingleGet:(state)=>{
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        failed(state, action) {
            state.message = action.payload
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    },
});

// Actions
export const paymentActions = paymentSlice.actions;

// Reducer
const paymentReducer = paymentSlice.reducer;


export { paymentReducer };