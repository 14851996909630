import { call, put, takeLatest } from "redux-saga/effects";
import { termsUpdateActions } from "../../slice/terms/termsUpdateSlice";
import termsUpdateApi from "../../../api/terms/termsUpdateApi";
function* edit(action) {
    try {
        const response = yield call(termsUpdateApi.edit, action.payload);
        yield put(termsUpdateActions.success(response));
    } catch (error) {
        yield put(termsUpdateActions.failed(error.message));
    }
}
export default function* termsUpdateSaga() {
    yield takeLatest(termsUpdateActions.edit.type, edit)
}