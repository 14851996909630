import { Pagination, CircularProgress } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/buttons/Buttons";
import clinicSelector from "../../../redux/selector/clinic/clinicSelector";
import { doctorSelector } from "../../../redux/selector/doctor/doctorSelector";
import { doctorActions } from "../../../redux/slice/doctor/doctorSlice";
import '../style.scss'
import { debounce } from "lodash";
export const ClinicDoctor = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const selector = useSelector(doctorSelector)
    const clinicSelect = useSelector(clinicSelector)
    const params = useParams()
    const [rows, setRows] = React.useState([])
    const [searchVal, setSearchVal] = React.useState('')
    const [searchApi, setSearchApi] = React.useState('')
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(3)
    React.useEffect(() => {
        if (params && params.id) {
            if (searchApi) {
                dispatch(doctorActions.getSearchByClinic({ 'q': searchApi, 'clinic_id': clinicSelect.data.id, 'page': page, 'limit': limit }))
            }
            else {
                dispatch(doctorActions.getAllByClinic({ 'clinic_id': clinicSelect.data.id, 'page': page, 'limit': limit }))
            }
        }
    }, [params, dispatch, searchApi, page, limit])
    React.useEffect(() => {
        if (selector && selector.data && selector.data.rows && selector.data.rows instanceof Array && selector.data.rows.length > 0) {
            setRows(selector.data.rows)
        }
        else {
            setRows([])
        }
    }, [selector])
    const someFunction = (val) => {
        setSearchApi(val)
        if (val) {
            setPage(1)
        }
    }
    const handler = React.useCallback(debounce(someFunction, 500), []);
    const handleSearchChange = (val) => {
        setSearchVal(val);
        handler(val)
    }
    return (
        <React.Fragment>
            <div className='top_header'>
                <div className="left">
                    <BackButton />
                </div>
                <div className='right'>
                    <div className='input_wrapper'>
                        <div className='input'>
                            <input placeholder="Search Clinics" value={searchVal} onChange={(e) => { handleSearchChange(e.target.value) }} />
                        </div>
                        <div className='icon'>
                            <img alt="search" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABZklEQVRIidXUsWpTcRTH8U9SpFgo1g6xU5d2qX0CoZPdtBJwELL5Ap19gWyBdip0yyh0VMgkDi4dzBLzBsUpCC1dbId4O/zP1YAh994YKP7gcuCec8/3/M8958//rlqB/xFeYAfrGOErPuP2X8Cr6OAnsinPCIeoz5N8E8NI1EMTT/AgfG8xCP8HrFStfBiVt2bE1dEOyJniVv9WJz6alXxSxxH/pkzwmlR5r2w1eIgLqWWFakU1zQoA/rRqa5pzcgqehj2vCMjjd4sAa2GvKgIuwz4uAozCblQE5PE/igD9sM8rAvalf9AvClyWTjFQfkMbuManstUcRjXtErFL0kiPsVcWUJfWP8ORNOfT1IjkeWsq3Ukr0vpn0hK18RLP8BonUlvGkTxDtyqkJq3/N3/fpL+knu9F0u68kFzbeCXdogdSeya1EEiRajgNyHtpABauyZO8y18ukpThI27wBd8XmPsedQcLnFSiSu7vvQAAAABJRU5ErkJggg=="></img>
                        </div>
                    </div>
                </div>
            </div>
            {selector.isFetching === true ?
                <div style={{ height: 359, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
                :
                rows.length === 0 ?
                    <div className="card_shadow_out_padd" style={{ height: 359, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 22 }}>
                        <img src={require('../../../assets/img/no_data_found.png')} style={{ height: 250, maxWidth: '100%' }} alt="no data found" />
                        <span style={{ color: 'gray' }}>We Can't any doctors data.</span>
                    </div>
                    :
                    <React.Fragment>
                        <div className="card_shadow_out_padd main_table" style={{ marginTop: 22 }}>
                            <div className="appoinment">
                                <div className="table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>
                                                    <div className="th_cont">
                                                        <span>Name</span>
                                                    </div>
                                                </td>
                                                <td>Status</td>
                                                <td>Email</td>
                                                <td>Phone No</td>
                                                <td>Specializations</td>
                                                <td>Fees</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                rows && rows.length > 0 && rows.map((elem, index) => {
                                                    return (
                                                        <tr key={index} onClick={() => { navigate(`/doctor/details/${elem.id}`) }}>
                                                            <td>
                                                                <div className="usr_ico_n_tex">
                                                                    <div className="ico_wrp">
                                                                        <img src={require('../../../assets/icon/doctor.png')} alt="user" />
                                                                    </div>
                                                                    <span className="title">{elem.name}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="status_flex">
                                                                    <span className={elem.active_status ? "enabled status" : "disabled status"}></span>
                                                                    <span className="title">{elem.active_status ? "Active" : "Inactive"}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="usr_ico_n_tex">
                                                                    <div className="ico_wrp">
                                                                        <img src={require('../../../assets/icon/social/gmail.png')} alt="user" />
                                                                    </div>
                                                                    <span className="title">{elem.email ? elem.email : ''}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="usr_ico_n_tex">
                                                                    <div className="ico_wrp">
                                                                        <img src={require('../../../assets/icon/social/phone.png')} alt="user" />
                                                                    </div>
                                                                    <span className="title">{elem.contact_no ? elem.contact_no : ''}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="usr_ico_n_tex">
                                                                    <span className="title">{elem.specializations ? elem.specializations : ''}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="usr_ico_n_tex">
                                                                    <div className="ico_wrp">
                                                                        <img src={require('../../../assets/icon/rupee.png')} alt="user" />
                                                                    </div>
                                                                    <span className="title">{elem.fees ? elem.fees.includes('.') ? elem.fees : `${elem.fees}.00` : ''}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {selector && selector.data && selector.data.total_rows && <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
                            <Pagination count={Math.ceil(selector.data.total_rows / limit)} color='primary' page={page} onChange={(event, value) => { setPage(value) }} />
                        </div>}
                    </React.Fragment>
            }
        </React.Fragment>
    );
}