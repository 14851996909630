import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const privacyUpdateSlice = createSlice({
    name: "privacy_update",
    initialState,
    reducers: {
        edit: (state, action) => {
            state.isFetching = true;
        },
        updateStatus: (state) => {
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        reset(state) {
            Object.assign(state, initialState)
        },
        failed(state, action) {
            state.message = action.payload
        }
    },
});


// Actions

export const privacyUpdateActions = privacyUpdateSlice.actions;

// Reducer
const privacyUpdateReducer = privacyUpdateSlice.reducer;



export { privacyUpdateReducer };
