import { Button, TextField, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import React from 'react'
import './style.scss'

import { useForm } from "react-hook-form";
import * as yup from "yup";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AlertMsg from '../components/alert/AlertMsg';
import axiosClient from '../api/axiosClient';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomDialog from '../pages/settings/CustomDialog';

const validationSchema = yup.object({
    contact_no: yup.string().required("Please enter your contact no.").matches(/^[789]\d{9}$/,"Please enter valid contact no."),
    password: yup.string().required("Please enter your password")
});
export const UserDelete = () => {
    const { handleSubmit, register, formState: { errors },setValue,reset,clearErrors } = useForm({ resolver: yupResolver(validationSchema) });
    const [pswToggle, setPswToggle] = React.useState(false)
    const [message, setMessage] = React.useState(false)
    const [processing,setProcessing]=React.useState(false)
    const [msgText,setMsgText]=React.useState("")
    const [msgType,setMsgType]=React.useState("error")
    const onSubmit = (data) => {
        setProcessing(true)
        axiosClient.post("/user/block", data)
  .then(res => {
    setProcessing(false)
    console.log('Response:', res.data)
    if(res?.data?.status){
        setMsgType("success")
        reset({})
        clearErrors()
        setValue("contact_no", ""); 
        setValue("password", "");
    }
   
    setMessage(true)
        setMsgText(res?.data?.message)
        setTimeout(()=>{
   setMessage(false)
   setMsgText("")
   setMsgType("error")
        },5000)
        
    
  })
  .catch(error => {
    setProcessing(false)
    setMessage(true)
    setMsgText("Something went wrong")
    setTimeout(()=>{
setMessage(false)
setMsgText("")
setMsgType("error")
    },5000)
  });
    }

    return (
        <React.Fragment>

            <div className='delete_main_wrap' style={{ backgroundImage: `url(${require('../assets/img/login_back/login_bg.jpg')})` }}>
                <div className='center_wrap'>
                    <div className='center_top_section'>
                        <img src={require('../assets/logo.webp')} alt="img" />
                    </div>
                    <div className='center_right_wrap'>
                        <div className='text_wrap'>
                            <div className='input_wrap'>
                                <div className='textField'>
                                <TextField {...register("contact_no")} sx={{ width: '100%', mb: 2 }} size="small" placeholder='80XXXXXXXX' type="text" label="Contact Number" />
                                <span  style={{textAlign:'left',color:'red',width:'100%',margin:0,marginTop:'0px'}}>{errors.contact_no?.message}</span>
                                </div>
                                <div  className='textField'>
                                <TextField {...register("password")} sx={{ width: '100%' }} size="small" placeholder='Password' type={pswToggle ? "text" : "password"} label="Password"

                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={() => { setPswToggle(!pswToggle) }}
                                                onMouseDown={(e) => { e.preventDefault() }}
                                            >
                                                {pswToggle ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                                <span  style={{textAlign:'left',color:'red',width:'100%',margin:0,marginTop:'0px'}}>{errors.password?.message}</span>
                                </div>

                            </div>
                            <div className='sign_in_button'>
                                <Button onClick={handleSubmit(onSubmit)} variant="contained" sx={{ textTransform: 'capitalize' }} color="error">Delete</Button>
                            </div>
                           
                        </div>

                    </div>
                </div>
            </div>
            {
                <CustomDialog open={processing} children={<div style={{ padding: 32, backgroundColor: 'white', borderRadius: 6 }}><CircularProgress /></div>} />
            }
            {message && <AlertMsg open={message} onClose={() => { setMessage(false) }} type={msgType} msgText={msgText} />}
        </React.Fragment>
    );
}