import { Avatar, Button, Chip, CircularProgress } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AreaChart } from "../../components/area_chart/AreaChart";
import { LineChart } from "../../components/line_chart/Line_Chart";
import { BASE_URL } from "../../config/Config";
import { doctorSelector } from "../../redux/selector/doctor/doctorSelector";
import { doctorActions } from "../../redux/slice/doctor/doctorSlice";
import './style.scss'
import Slide from '@mui/material/Slide';
import Dialog from "@mui/material/Dialog";
import { BackButton } from "../../components/buttons/Buttons";
import { doctorUpdateSelector } from "../../redux/selector/doctor/doctorUpdateSelector";
import { doctorUpdateActions } from "../../redux/slice/doctor/doctorUpdateSlice";
import AlertMsg from "../../components/alert/AlertMsg";
import appoinmentSelector from "../../redux/selector/appoinment/appoinmentSelector";
import { appoinmentActions } from "../../redux/slice/appoinment/appoinmentSlice";
import paymentSelector from "../../redux/selector/payment/paymentSelector";
import { paymentActions } from "../../redux/slice/payment/paymentSlice";
import '../../components/toggle_button/style.scss'
import { appoinmentTableSelector } from "../../redux/selector/appoinment/appoinmentTableSelector";
import CustomDialog from "../settings/CustomDialog";

export const DoctorDetails = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const selector = useSelector(doctorSelector)
    const updateSelector = useSelector(doctorUpdateSelector)
    const appoinmentPerformanceSelector = useSelector(appoinmentSelector)
    const paymentPerformanceSelector = useSelector(paymentSelector)
    const [appoinToggle, setAppoinToggle] = React.useState('year')
    const appoinmentTablePerformanceSelector = useSelector(appoinmentTableSelector)
    React.useEffect(() => {
        if (params && params.id) {
            dispatch(doctorUpdateActions.reset())
            dispatch(doctorActions.getOne(params.id))
        }
    }, [params,dispatch])
    React.useEffect(() => {
        if (selector && selector.data && selector.data.id) {
            dispatch(appoinmentActions.performanceDoctor({ 'id': selector.data.id, "filter": appoinToggle }))
        }
    }, [selector])
  
    React.useEffect(() => {
        if (selector && selector.data && selector.data.id) {
            dispatch(paymentActions.performanceDoctor({ 'id': selector.data.id, 'filter': 'year' }))
        }
    }, [selector])
    React.useEffect(() => {
        if (params && params.id) {
            dispatch(appoinmentActions.getAll({ 'doctor_id': params.id, 'page': 1, 'limit': 4 }))
            dispatch(paymentActions.getAll({ 'doctor_id': params.id, 'page': 1, 'limit': 4 }))
        }
    }, [params])
    const [appoinmentPerformanceData, setAppoinmentPerformanceData] = React.useState([])
    const [appoinmentPerformanceTableData, setAppoinmentPerformanceTableData] = React.useState([])
    React.useEffect(() => {
        if (appoinmentPerformanceSelector && appoinmentPerformanceSelector.data && appoinmentPerformanceSelector.data instanceof Array) {
            if (appoinmentPerformanceSelector.data.length > 0) {
                setAppoinmentPerformanceData(appoinmentPerformanceSelector)
            }
            else {
                setAppoinmentPerformanceData([])
            }
        }
        else if (appoinmentPerformanceSelector.data.rows && appoinmentPerformanceSelector.data.rows instanceof Array) {
            if (appoinmentPerformanceSelector.data.rows.length > 0) {
                setAppoinmentPerformanceTableData(appoinmentPerformanceSelector)
            }
            else {
                setAppoinmentPerformanceTableData([])
            }
        }
    }, [appoinmentPerformanceSelector])
    const [paymentPerformanceData, setPaymentPerformanceData] = React.useState([])
    const [paymentPerformanceTableData, setPaymentPerformanceTableData] = React.useState([])
    React.useEffect(() => {
        if (paymentPerformanceSelector && paymentPerformanceSelector.data && paymentPerformanceSelector.data instanceof Array) {
            if (paymentPerformanceSelector.data.length > 0) {
                setPaymentPerformanceData(paymentPerformanceSelector)
            }
            else {
                setPaymentPerformanceData([])
            }
        }
        else if (paymentPerformanceSelector.data.rows && paymentPerformanceSelector.data.rows instanceof Array) {
            if (paymentPerformanceSelector.data.rows.length > 0) {
                setPaymentPerformanceTableData(paymentPerformanceSelector)
            }
            else {
                setPaymentPerformanceTableData([])
            }
        }
    }, [paymentPerformanceSelector])
    const navigate = useNavigate();
    const [fsc, setFsc] = React.useState(false)
    const toggleCerificateVerification = () => {
        if (selector && selector.data && selector.data.id) {
            dispatch(doctorUpdateActions.updateCertificate({ 'id': selector.data.id }))
        }
    }
    const [updateMsg, setUpdateMsg] = React.useState()
    React.useEffect(() => {
        console.log("update selector:",updateSelector)
        if (updateSelector && updateSelector.data && updateSelector.data.message) {
            setUpdateMsg(true)
        }
    }, [updateSelector])

  React.useEffect(()=>{
    if(updateMsg===true){
        if (params && params.id) {
            dispatch(doctorActions.getOne(params.id))
        }
    }
  },[updateMsg])

    // const resetDispatch = () => {
    //     if (updateSelector && updateSelector.data && updateSelector.data.message) {
    //         setUpdateMsg(false)
    //         dispatch(doctorUpdateActions.reset())
    //         if (selector && selector.data && selector.data.id) {
    //             dispatch(doctorActions.getOne(selector.data.id))
    //         }
    //     }
    // }
   
    return (
        <React.Fragment>
            <div className="doctor_details">
                <div className="header" >
               <BackButton/>
                </div>
                <div className="grid_3">
                    {
                        selector.isFetching ?
                            <div className="top_right card_shadow">
                                <CircularProgress />
                            </div> :
                            selector && selector.data && selector.data.name &&
                            <div className="top_right card_shadow" style={{ height: 240 }}>
                                <div style={{ position: 'relative' }}>
                                    <Avatar sx={{ width: 84, height: 84, fontSize: 36, bgcolor: `gray` }} className="shadow">{
                                        (selector.data.name && selector.data.name.split(' ') && selector.data.name.split(' ').length > 2) ? `${selector.data.name.split(' ')[1].charAt(0).toUpperCase()}${selector.data.name.split(' ')[2].charAt(0).toUpperCase()}` :
                                            (selector.data.name && selector.data.name.split(' ') && selector.data.name.split(' ').length === 2) ? `${selector.data.name.split(' ')[0].charAt(0).toUpperCase()}${selector.data.name.split(' ')[1].charAt(0).toUpperCase()}` : selector.data.name?.charAt(0).toUpperCase()
                                    }
                                    </Avatar>
                                    <img src={selector.data.active_status ? require('../../assets/icon/verified.png') : require('../../assets/icon/unverified.png')} alt="doctor certificate" style={{ width: 32, height: 32, boxShadow: 'none', padding: 0, background: 'none', position: 'absolute', bottom: -4, right: -4 }} />
                                </div>
                                <div className='title' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6 }}>
                                    <h1>{selector.data.name}</h1>
                                </div>
                                {selector?.data?.mci_reg_no && selector?.data?.qualification && <div className="mciRegistration">

                                    <div style={{ paddingBottom: "6px" }}><Chip size="small" key={1} style={{ backgroundColor: "rgb(23, 186, 134)", color: "white" }} label={selector?.data?.qualification} /></div>
                                
                                </div>}
                            </div>
                    }
                    {
                        selector.isFetching ?
                            <div className="top_right card_shadow">
                                <CircularProgress />
                            </div> :
                            <React.Fragment>
                                {selector && selector.data && selector.data.name &&
                                    <div className="top_right card_shadow" style={{ height: 240 }}>
                                        {/* <div style={{ position: 'relative' }}>
                                            <Avatar sx={{ width: 84, height: 84, fontSize: 36, bgcolor: `gray` }} className="shadow">{
                                                (selector.data.name && selector.data.name.split(' ') && selector.data.name.split(' ').length > 2) ? `${selector.data.name.split(' ')[1].charAt(0).toUpperCase()}${selector.data.name.split(' ')[2].charAt(0).toUpperCase()}` :
                                                    (selector.data.name && selector.data.name.split(' ') && selector.data.name.split(' ').length === 2) ? `${selector.data.name.split(' ')[0].charAt(0).toUpperCase()}${selector.data.name.split(' ')[1].charAt(0).toUpperCase()}` : selector.data.name?.charAt(0).toUpperCase()
                                            }
                                            </Avatar>
                                            <img src={selector.data.active_status ? require('../../assets/icon/verified.png') : require('../../assets/icon/unverified.png')} alt="doctor certificate" style={{ width: 32, height: 32, boxShadow: 'none', padding: 0, background: 'none', position: 'absolute', bottom: -4, right: -4 }} />
                                        </div>
                                        <div className='title' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6 }}>
                                            <h1>{selector.data.name}</h1>
                                        </div>
                                        {selector?.data?.mci_reg_no && selector?.data?.qualification && <div className="mciRegistration">
                                            
                                            <div style={{paddingBottom:"6px"}}><Chip size="small" key={1} style={{backgroundColor:"rgb(23, 186, 134)",color:"white"}}  label={selector?.data?.qualification} /></div>
                                            <b>Reg: <span className="mci_label">{selector?.data?.mci_reg_no}</span></b>
                                        </div>} */}
                                            <b>Reg: <span className="mci_label">{selector?.data?.mci_reg_no}</span></b>
                                        <div className="spl">
                                            <div className='spl_flex'>
                                                {
                                                    selector.data.specializations && selector.data.specializations.split(',') && selector.data.specializations.split(',').length > 0 && selector.data.specializations.split(',').map((splItem, splIndex) => {
                                                        return (
                                                            <React.Fragment>
                                                                {splIndex < 2 && <Chip size="small" key={splIndex} label={splItem} />}
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="spl">
                                            <div className='spl_flex'>
                                                {
                                                    selector.data.day && selector.data.day instanceof Array && selector.data.day.length > 0 && selector.data.day.map((splItem, splIndex) => {
                                                        return (
                                                            <React.Fragment>
                                                                {splIndex < 2 && <Chip size="small" color="secondary" key={splIndex} label={`${splItem.data.from.text} - ${splItem.data.to.text} (${splItem.item})`} />}
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className='social_icons'>
                                            {selector.data.contact_no && <a href={`tel:${selector.data.contact_no}`}>
                                                <img src={require('../../assets/icon/social/phone.png')} alt={selector.data.contact_no} />
                                            </a>}
                                            {selector.data.contact_no && <a href={`mailto:${selector.data.email}`}>
                                                <img src={require('../../assets/icon/social/gmail.png')} alt="gmail" />
                                            </a>}
                                        </div>
                                        <button
                                        onClick={toggleCerificateVerification}
                                        style={{
                                            boxShadow: 'none',
                                            background: selector?.data?.active_status?"gray":"rgb(23, 186, 134)",
                                            color: 'white'
                                        }}>{selector.data.active_status ? "Unverify" : "Verify"}</button>
                                    </div>}
                            </React.Fragment>
                    }
                    {
                        selector.isFetching ?
                            <div className="top_right card_shadow">
                                <CircularProgress />
                            </div> : selector && selector.data && selector.data.certificate_image && <div className="card_shadow mci_certificate" style={{ height: 240 }}>
                                <div style={{ position: 'relative', height: '100%' }}>
                                    <img src={`${BASE_URL}${selector.data.certificate_image.slice(2)}`} alt="certificate_image" style={{ height: '100%', width: '100%', cursor: 'pointer' }} onClick={() => { setFsc(true) }} />
                                    
                                </div>
                            </div>
                    }
                </div>
                <div className="top">
                    <div>
                        <div className="top_left card_shadow">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                                <p style={{ paddingBottom: 0 }}>Appointment Performance</p>

                                {appoinmentPerformanceData.data && appoinmentPerformanceData.data.length > 0 &&
                                    <div style={{ display: 'flex', gap: "22px", alignItems: "center" }}>

                                        <button onClick={() => { navigate(`/doctor/appoinment/${selector.data.id}`) }} style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14, color: '#314c7c' }}>View All</button></div>}
                            </div>
                            {
                                appoinmentPerformanceSelector.isFetching ?
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', margin: '48px 0' }}>
                                        <CircularProgress />
                                    </div> : appoinmentPerformanceData.data && appoinmentPerformanceData.data instanceof Array && appoinmentPerformanceData.data.length > 0 ? <AreaChart data={
                                        {
                                            'labels': appoinmentPerformanceData.data.map(e => e._id),
                                            'datasets': [
                                                {
                                                    'fill': true,
                                                    'label': "Count",
                                                    'lineTension': 0.5,
                                                    'data': appoinmentPerformanceData.data.map(e => e.count),
                                                    'borderColor': 'rgb(0, 179, 254)',
                                                    'backgroundColor': 'rgba(1, 237, 201, 0.2)',
                                                }
                                            ]
                                        }
                                    } /> :
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 320, maxWidth: '100%' }} />
                                        </div>
                            }
                        </div>
                        {appoinmentPerformanceSelector.isFetching ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', width: '100%', background: 'white', borderRadius: 6, margin: '48px 0' }}>
                                <CircularProgress />
                            </div> :
                            <React.Fragment>
                                {appoinmentPerformanceData.data && appoinmentPerformanceData.data.length > 0 &&
                                    <div className="appoinment">
                                        {
                                            appoinmentPerformanceTableData.data && appoinmentPerformanceTableData.data.rows && appoinmentPerformanceTableData.data.rows instanceof Array && appoinmentPerformanceTableData.data.rows.length > 0 ?
                                                <div className="table">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <td>
                                                                    <div className="th_cont">
                                                                        <span>Name</span>
                                                                    </div>
                                                                </td>
                                                                <td>Status</td>
                                                                <td>Clinic</td>
                                                                <td>Fees</td>
                                                                <td>Date</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {appoinmentPerformanceTableData.data && appoinmentPerformanceTableData.data.rows && appoinmentPerformanceTableData.data.rows instanceof Array && appoinmentPerformanceTableData.data.rows.length > 0 && appoinmentPerformanceTableData.data.rows.map((elem, index) => {
                                                                return (
                                                                    <tr onClick={() => { navigate(`/appointment/details/${elem.id}`) }} key={index}>
                                                                        <td>
                                                                            <div className="usr_ico_n_tex">
                                                                                <div className="ico_wrp">
                                                                                    <img src={require('../../assets/icon/patient.png')} alt="user" />
                                                                                </div>
                                                                                <span className="title">{(elem.patient && elem.patient.name) ? elem.patient.name : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="status_flex">
                                                                                <span className={(elem.appointment_status.trim() === "Booked" || elem.appointment_status.trim() === "Completed") ? "enabled status" : "disabled status"}></span>
                                                                                <span className="title">{elem.appointment_status ? elem.appointment_status : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="usr_ico_n_tex">
                                                                                <div className="ico_wrp">
                                                                                    <img src={require('../../assets/icon/clinic.png')} alt="user" />
                                                                                </div>
                                                                                <span className="title">{(elem.clinic && elem.clinic.name) ? elem.clinic.name : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="usr_ico_n_tex">
                                                                                <div className="ico_wrp">
                                                                                    <img src={require('../../assets/icon/rupee.png')} alt="user" />
                                                                                </div>
                                                                                <span className="title">{elem.fees ? elem.fees : null}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {elem.appointment_date ?
                                                                                <React.Fragment>
                                                                                    {`${new Date(elem.appointment_date).toDateString()}, ${new Date(elem.appointment_date).toLocaleTimeString()}`}
                                                                                </React.Fragment> : null}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div> : null
                                        }
                                    </div>}
                            </React.Fragment>
                        }
                    </div>
                    <div>
                        <div className="top_left card_shadow">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                                <p style={{ paddingBottom: 0 }}>Payment Performance</p>
                                {paymentPerformanceData.data && paymentPerformanceData.data.length > 0 && <button onClick={() => { navigate(`/doctor/payment/${selector.data.id}`) }} style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14, color: '#314c7c' }}>View All</button>}
                            </div>
                            {
                                paymentPerformanceSelector.isFetching ?
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', margin: '48px 0' }}>
                                        <CircularProgress />
                                    </div> : paymentPerformanceData.data && paymentPerformanceData.data.length > 0 ? <AreaChart data={
                                        {
                                            'labels': paymentPerformanceData.data.map(e => e._id),
                                            'datasets': [
                                                {
                                                    'fill': false,
                                                    'label': "Count",
                                                    'lineTension': 0.5,
                                                    'data': paymentPerformanceData.data.map(e => e.count),
                                                    borderColor: "#742774"

                                                },
                                                {
                                                    'fill': true,
                                                    'label': "Amount",
                                                    'lineTension': 0.5,
                                                    'data': paymentPerformanceData.data.map(e => e.fees),
                                                    backgroundColor: "rgba(75,192,192,0.2)",
                                                    borderColor: "rgba(75,192,192,1)"
                                                }
                                            ]
                                        }
                                    } /> :
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 320, maxWidth: '100%' }} />
                                        </div>
                            }
                        </div>
                        {paymentPerformanceSelector.isFetching ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', width: '100%', background: 'white', borderRadius: 6, margin: '48px 0' }}>
                                <CircularProgress />
                            </div> :
                            <React.Fragment>
                                {paymentPerformanceData.data && paymentPerformanceData.data.length > 0 &&
                                    <div className="appoinment">

                                        {paymentPerformanceTableData && paymentPerformanceTableData.data && paymentPerformanceTableData.data.rows && paymentPerformanceTableData.data.rows instanceof Array && paymentPerformanceTableData.data.rows.length > 0 ?
                                            <div className="table">

                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td style={{ whiteSpace: 'nowrap' }}>Appointment Id</td>
                                                            <td>
                                                                <div className="th_cont">
                                                                    <span>Name</span>
                                                                </div>
                                                            </td>
                                                            <td>Status</td>
                                                            <td>Amount</td>
                                                            <td>Date</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paymentPerformanceTableData && paymentPerformanceTableData.data && paymentPerformanceTableData.data.rows && paymentPerformanceTableData.data.rows instanceof Array && paymentPerformanceTableData.data.rows.length > 0 && paymentPerformanceTableData.data.rows.map((elem, index) => {
                                                            return (
                                                                <tr onClick={() => { navigate(`/payment/details/${elem.id}`) }} key={index}>
                                                                    <td>
                                                                        <div className="usr_ico_n_tex">
                                                                            <span className="title">{elem.appointment_id ? elem.appointment_id : null}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="usr_ico_n_tex">
                                                                            <div className="ico_wrp">
                                                                                <img src={require('../../assets/icon/patient.png')} alt="user" />
                                                                            </div>
                                                                            <span className="title">{elem.patient ? elem.patient.name ? elem.patient.name : null : null}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="status_flex">
                                                                            <span className={elem.payment_status === "Success" ? "enabled status" : "disabled status"}></span>
                                                                            <span className="title">{elem.payment_status ? elem.payment_status : null}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="usr_ico_n_tex">
                                                                            <div className="ico_wrp">
                                                                                <img src={require('../../assets/icon/rupee.png')} alt="user" />
                                                                            </div>
                                                                            <span className="title">{elem.fees ? elem.fees : null}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {elem.date ?
                                                                            <React.Fragment>
                                                                                {`${new Date(elem.date).toDateString()}, ${new Date(elem.date).toLocaleTimeString()}`}
                                                                            </React.Fragment> : null}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div> : null}
                                    </div>}
                            </React.Fragment>
                        }
                    </div>



                </div>
                {
                    updateMsg &&
                    <AlertMsg open={updateMsg} onClose={() => { setUpdateMsg(false) }}  type={updateSelector.data.status ? "success" : "error"} msgText={updateSelector.data.message} />
                }
                {selector.isFetching === true ?
                    <div style={{ height: 359, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </div>
                    :
                    selector.data.clinicDetails
                        && selector.data.clinicDetails
                            .length === 0 ?
                        <div className="card_shadow_out_padd main_table" style={{ height: 359, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 22 }}>
                            <img src={require('../../assets/img/no_data_found.png')} style={{ height: 250, maxWidth: '100%' }} alt="no data found" />
                            <span style={{ color: 'gray' }}>We Can't find any chember.</span>
                        </div>
                        : selector.data.clinicDetails
                        && <div className="card_shadow_out_padd main_table" style={{ marginTop: 22, backgroundColor: 'white', borderRadius: '6px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 12 }}>
                                <p style={{ paddingBottom: 0, margin: 0, fontWeight: 'bold', color: '#314c7c' }}>Chembers</p>

                            </div>
                            <div className="appoinment" style={{ marginTop: 0 }}>
                                <div className="table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>
                                                    <div className="th_cont">
                                                        <span>Clinic Name</span>
                                                    </div>
                                                </td>
                                                <td>Address</td>
                                                <td>Date</td>
                                                <td>Time</td>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                selector.data && selector.data.clinicDetails
                                                    .length > 0 && selector.data.clinicDetails
                                                        .map((elem, index) => {
                                                            return (
                                                                <tr key={index} onClick={() => { navigate(`/clinic/details/${elem?.clinicData?.id}`) }}>
                                                                    <td>
                                                                        <div className="usr_ico_n_tex">
                                                                            <div className="ico_wrp">
                                                                                <img src={require('../../assets/icon/clinic.png')} alt="clinic" />
                                                                            </div>
                                                                            <span className="title">{elem?.clinicData?.name}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="usr_ico_n_tex">
                                                                            <span className="title">{elem?.clinicData?.address ? elem?.clinicData?.address : ''}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="usr_ico_n_tex">
                                                                            {
                                                                                elem?.dayData?.day?.map((elem) => {
                                                                                    return (
                                                                                        <span className="title">{elem?.item}</span>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="usr_ico_n_tex">
                                                                            {elem?.dayData?.day?.map((elem) => {
                                                                                return (
                                                                                    <span className="title">{elem?.data?.from?.text}-{elem?.data?.to?.text}</span>
                                                                                )
                                                                            })

                                                                            }

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                }
            </div>
            {
                fsc &&
                <FullScreenCertificate open={fsc} onClose={() => { setFsc(false) }} children={
                    <div style={{
                        background: 'white',
                        boxShadow: '3px 3px 10px #d9d9d9,-3px -3px 10px #ffffff',
                        padding: 16,
                        borderRadius: 6
                    }}>
                        <img src={`${BASE_URL}${selector.data.certificate_image.slice(2)}`} alt="full_certificate" style={{ height: 'auto', width: '100%' }} />
                    </div>
                } />
            }
            {
                <CustomDialog open={updateSelector?.isFetching} children={<div style={{ padding: 32, backgroundColor: 'white', borderRadius: 6 }}><CircularProgress /></div>} />
            }
        </React.Fragment >
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenCertificate = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
        >
            {props.children}
        </Dialog>
    );
}


// {"id":"0df4daeb4d04",
// "name":"Dr. Saikat Mandal",
// "contact_no":"8145796473",
// "email":"saikat@gmail.com",
// "address":"Daspur",
// "qualification":"M.B.B.S.",
// "certificate_image":{"fieldname":"certificate_image",
//                     "originalname":"Screenshot (161).png",
//                     "encoding":"7bit",
//                     "mimetype":"image/png",
//                     "destination":"./images/doctor/certificate",
//                     "filename":"certificate_image_1674479111455.png",
//                     "path":"images/doctor/certificate/certificate_image_1674479111455.png",
//                     "size":247365},
// "fees":"200",
// "certificate_iamge":"./images/doctor/certificate/certificate_image_1674479111455.png","specializations":"Radiologists,Family Medicine","clinic":{"id":"194df9c7b42d","name":"Mamata Medical"},"day":[{"data":{"from":{"hours":"03","minutes":"00","text":"3:00pm","type":"pm"},"to":{"hours":"05","minutes":"00","text":"05:00pm","type":"pm"}},"item":"Sunday (Last Week)","_id":"63ce8608da36d10cfbc3b9b7"}]}