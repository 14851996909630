import React from "react";
import './style.scss'
import { useNavigate } from "react-router-dom";
function useScrollTop() {
    const [scrollTop, setScrollTop] = React.useState(0);
    const onScroll = (event) => setScrollTop(event.target.scrollTop);
    return [scrollTop, { onScroll }];
  }
export const NewTerms = () => {
    const [scrollTop, scrollProps] = useScrollTop();
    const navigate=useNavigate()
    return (
        <React.Fragment>
            <div className="main">
                <div className="header" >
                    <h1 onClick={() => {navigate('/policies')  }}>DNA<span>LIVE</span></h1>
                </div>
                <div className="body" {...scrollProps} style={{ boxShadow: scrollTop > 0 ? "inset 0 8px 5px -1px #e9e9e9" : "none", transition: "box-shadow 0.3s"}}>
                     
                        <><div className="body_wrap">
                            <img src={require("../assets/logo.jpg")} />
                            <h1>Coming Soon....</h1>
                        </div>
                        </>
                   
                </div>
                <div className="footer" >
                    <ul>
                    <li onClick={() => {navigate('/policies/terms-condition') }}>Terms and Condition</li>
                        <li style={{ color: 'black' }}>|</li>
                        <li onClick={() => {navigate('/policies/privacy-policy') }}>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}