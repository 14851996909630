import { call, put, takeLatest } from "redux-saga/effects";
import { doctorActions } from "../../slice/doctor/doctorSlice";
import { doctorApi } from "../../../api/doctor/doctorApi";
import clinicApi from "../../../api/clinic/clinicApi";
import { doctorUpdateApi } from "../../../api/doctor/doctorUpdateApi";
import { doctorUpdateActions } from "../../slice/doctor/doctorUpdateSlice";
function* getAll(action) {
    try {
        const response = yield call(doctorApi.getAll, action.payload);
        yield put(doctorActions.success(response));
    } catch (error) {
        yield put(doctorActions.failed(error.message));
    }
}
function* getOne(action) {
    try {
        const response = yield call(doctorApi.getOne, action.payload);
        yield put(doctorActions.success(response));
    } catch (error) {
        yield put(doctorActions.failed(error.message));
    }
}
function* getAllAppoiments(action) {
    try {
        const response = yield call(doctorApi.getAllAppoiments, action.payload)
        yield put(doctorActions.success(response))
    } catch (error) {
        yield put(doctorActions.failed(error.message))
    }
}
function* searchDoctors(action) {
    try {
        const response = yield call(doctorApi.searchDoctors, action.payload)
        yield put(doctorActions.success(response))
    } catch (error) {
        yield put(doctorActions.failed(error.message))
    }
}
function* searchAppoinments(action) {
    try {
        const response = yield call(doctorApi.searchAppoinments, action.payload)
        yield put(doctorActions.success(response))
    } catch (error) {
        yield put(doctorActions.failed(error.message))
    }
}
function* getAllByClinic(action) {
    try {
        const response = yield call(clinicApi.getDoctor, action.payload)
        yield put(doctorActions.success(response))
    } catch (error) {
        yield put(doctorActions.failed(error.message))
    }
}
function* getSearchByClinic(action) {
    try {
        const response = yield call(clinicApi.searchDoctors, action.payload)
        yield put(doctorActions.success(response))
    } catch (error) {
        yield put(doctorActions.failed(error.message))
    }
}
function* updateCertificate(action) {
    try {
        const response = yield call(doctorUpdateApi.cerificateUpdate, action.payload)
        yield put(doctorUpdateActions.success(response))
    } catch(error){
        yield put(doctorUpdateActions.failed(error.message))
    }
}
export default function* doctorSaga() {
    yield takeLatest(doctorActions.getAll.type, getAll)
    yield takeLatest(doctorActions.getOne.type, getOne)
    yield takeLatest(doctorActions.getAllAppoinments.type, getAllAppoiments)
    yield takeLatest(doctorActions.doctorSearch.type, searchDoctors)
    yield takeLatest(doctorActions.appoinmentSearch.type, searchAppoinments)
    yield takeLatest(doctorActions.getAllByClinic.type, getAllByClinic)
    yield takeLatest(doctorActions.getSearchByClinic.type, getSearchByClinic)

    yield takeLatest(doctorUpdateActions.updateCertificate.type,updateCertificate)
}