import React from 'react'
import { Button } from '@mui/material';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
const containerStyle = {
    width: '100%',
    height: '450px'
};
export default function MapGoogle(props) {
    const [position, setPosition] = React.useState({ lat:props.lat , lng:props.lng })
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAIWIDlIt-Bpjn6lvvWxvx2BtVEFCAg7AE"
    })
    const [map, setMap] = React.useState(null)
    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(position);
        map.fitBounds(bounds);
        setMap(map)
    }, [])
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    const PositionChange = (lat, lng) => {
        setPosition({ lat: lat, lng: lng })
    }
    const getValueFromMarker=()=>{
        props.value(position)
        props.onClose()
    }
    return (
        isLoaded &&
        <React.Fragment>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={position}
                zoom={5}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={(e) => { PositionChange(e.latLng.lat(), e.latLng.lng()) }}
            >

                <Marker position={position} />
            </GoogleMap>
            <div style={{width:'calc(100% - 15px)',height:'50px',backgroundColor:"white",display:'flex',alignItems:'center',justifyContent:'flex-end',gap:15}}>
                <Button variant='outlined' onClick={()=>{props.onClose()}}>Cancel</Button>
                <Button variant='contained' onClick={()=>{getValueFromMarker()}}>OK</Button>
            </div>
        </React.Fragment>
    )
}