import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    plugins: {
        legend: {
            position: 'top left',
        }
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
        x: {
          grid: {
            display: false,
          }
        },
      }
};

const labels = ['', '', '', '', '', '', '', '', '', '', '', ''];

export const data = {
    labels,
    datasets: [
        {
            label: "Dataset 2",
            data: [21, 54, 23, 123, 30, 66, 99, 23, 56, 21, 56, 23, 123, 56],
            borderColor: 'rgb(5, 91, 92)',
            backgroundColor: 'rgba(5, 91, 92,0.2)',
        },
    ],
};

export function LineChart(props) {
    return <Line options={options} data={data} />;
}
