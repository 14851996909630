import { Route, Routes } from "react-router-dom";
import { Payments } from "../../pages/payments/Payments";
import { PaymentsDetails } from "../../pages/payments/PaymentsDetails";

export const PaymentRouting = () => {
    return (
        <Routes>
            <Route path={PAYMENT_HOME} element={<Payments />} />
            <Route path={`${PAYMENT_DETAILS}/:id`} element={<PaymentsDetails />} />
        </Routes>
    );
}

export const PAYMENT_HOME = "/"
export const PAYMENT_PUBLIC_HOME = "/payments"
export const PAYMENT_DETAILS = "/details"
export const PAYMENT_PUBLIC_DETAILS = `${PAYMENT_PUBLIC_HOME}${PAYMENT_DETAILS}`