import { call, put, takeLatest } from "redux-saga/effects";
import { aboutActions } from "../../slice/about/aboutSlice";
import aboutApi from "../../../api/about/aboutApi";
function* getOne(action) {
    try {
        const response = yield call(aboutApi.getOne,action.payload);
        yield put(aboutActions.success(response));
    } catch (error) {
        yield put(aboutActions.failed(error.message));
    }
}
export default function* aboutSaga() {
    yield takeLatest(aboutActions.getOne.type, getOne)
}