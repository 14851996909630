import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const aboutUpdateSlice = createSlice({
    name: "about_update",
    initialState,
    reducers: {
        edit: (state, action) => {
            state.isFetching = true;
        },
        updateStatus: (state) => {
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        reset(state) {
            for(let key in state.data)
            {
                delete state.data[key];
            }
        },
        failed(state, action) {
            state.message = action.payload
        }
    },
});


// Actions

export const aboutUpdateActions = aboutUpdateSlice.actions;

// Reducer
const aboutUpdateReducer = aboutUpdateSlice.reducer;



export { aboutUpdateReducer };
