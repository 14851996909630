import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate,useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { SmallCard } from '../../components/small_card/SmallCard';
import { HomeRouting } from '../../routing/HomeRouting';
import { doctorSelector } from '../../redux/selector/doctor/doctorSelector'
import { doctorActions } from '../../redux/slice/doctor/doctorSlice';
import './new_home.scss'
import { Avatar, Chip, CircularProgress, Pagination, Skeleton } from '@mui/material';
import { debounce } from "lodash";
import { patientSelector } from '../../redux/selector/patient/patientSelector';
import { patientActions } from '../../redux/slice/patient/patientSlice';
import { BASE_URL } from '../../config/Config';
import { clinicActions } from '../../redux/slice/clinic/clinicSlice';
import clinicSelector from '../../redux/selector/clinic/clinicSelector';
import { dashboardSelector } from '../../redux/selector/dashboard/dashboardSelector'
import { dashboardActions } from '../../redux/slice/dashboard/dashboardSlice'
import { CustomSelect } from '../../components/custom_select/CustomSelect';
import { AreaChart } from '../../components/area_chart/AreaChart';
import appoinmentSelector from '../../redux/selector/appoinment/appoinmentSelector';
import { appoinmentActions } from '../../redux/slice/appoinment/appoinmentSlice';
import paymentSelector from '../../redux/selector/payment/paymentSelector';
import { paymentActions } from '../../redux/slice/payment/paymentSlice';



export const NewHome = () => {
    const [, setFocusStatus] = React.useState(false);
    const [menuToggle, setMenuToggle] = React.useState(false)
    const navigate=useNavigate()
    // const [firstRender,setFirstRender]=React.useState(true)
    React.useEffect(() => {
        if (menuToggle) {
            document.body.style.overflow = "hidden"
        }
        else {
            document.body.style.overflow = "auto"
        }
    }, [menuToggle])
    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 0) {
                document.getElementById('bar').classList.add("shadow")
            }
            else {
                document.getElementById('bar').classList.remove("shadow")
            }
        })
    })
    React.useEffect(()=>{
        
        if(localStorage?.getItem("login_count")==="1"){
           navigate("/")
        }
    },[])
    return (
        <React.Fragment>
            <div className="header">
                <div className="bar" id="bar">
                    <div className="bar_content">
                        <Header onFocus={() => { setFocusStatus(false) }} menuClick={() => { setMenuToggle(true) }} />
                    </div>
                </div>
                <div className="content">
                    <div className='main_content'>
                        <HomeRouting />
                    </div>
                </div>
            </div>
            <div className={`left_sidebar ${menuToggle ? "mobile_sidebar" : ""}`}>
                <div className="comb_brand_n_sidebar">
                    <BrandHead />
                    <Sidebar onClose={() => { setMenuToggle(false) }} />
                </div>
            </div>
            {menuToggle && <div className='full_overlay' onClick={() => { setMenuToggle(false) }}></div>}

        </React.Fragment>
    );
}

const Header = (props) => {
    const [profileClick, setProfileClick] = React.useState(false)
    const [selectValue, setSelectValue] = React.useState()
    function takeValueFromSelect(value) {
        setSelectValue(value)
        setProfileClick(false)
    }
    function profileToggle() {
        profileClick === false ? setProfileClick(true) : setProfileClick(false)
    }
    React.useEffect(() => {
    }, [selectValue])
    return (
        <div className="header_main">
            <div className='header_flex'>
                <div className="left">
                    <div className='left_menu' style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                        <img alt="menu" src={require('../../assets/icon/menu.png')} onClick={props.menuClick} />
                        <Link to='/' style={{ fontSize: '22px', fontWeight: '700', color: 'blue' }}>DNA <span style={{ color: 'red' }}>LIVE</span></Link>
                    </div>
                </div>
                <div className='right'>
                    <div className='right_wrapper'>

                        <div className='right_user_icon'>
                            <img alt="user" src={require('../../assets/icon/person.png')} onClick={() => { profileToggle() }} />
                        </div>
                        <div>
                            <CustomSelect getValue={takeValueFromSelect} toggle={profileToggle} view={profileClick} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const BrandHead = () => {
    return (
        <div className='brand_main'>
            <Link to='/' className="brand_full_text">
                <span className='first'>dna</span>
                <span className='last'>live</span>
            </Link>
        </div>
    );
}

const Sidebar = (props) => {
    const [activeIndex, setActiveIndex] = React.useState(0)
    const location = useLocation()
    const onClickSidebarItem = (index) => {
        setActiveIndex(index)
        setTimeout(() => {
            props.onClose(true)
        }, 500);
    }
   
    React.useEffect(() => {
        if (location.pathname.split('/')[1] === "") {
            setActiveIndex(0)
        }
        if (location.pathname.split('/')[1] === "doctor") {
            setActiveIndex(2)
        }
        else if (location.pathname.split('/')[1] === "appointment") {
            setActiveIndex(1)
        }
        else if (location.pathname.split('/')[1] === "patients") {
            setActiveIndex(3)
        }
        else if (location.pathname.split('/')[1] === "clinic") {
            setActiveIndex(4)
        }
        else if (location.pathname.split('/')[1] === "specialization") {
            setActiveIndex(5)
        }
        else if (location.pathname.split('/')[1] === "payment") {
            setActiveIndex(6)
        }
        else if (location.pathname.split('/')[1] === "setting") {
            setActiveIndex(7)
        }
        else if (location.pathname.split('/')[1] === "terms_and_condition") {
            setActiveIndex(8)
        }
        else if (location.pathname.split('/')[1] === "about") {
            setActiveIndex(9)
        }
        else if (location.pathname.split('/')[1] === "about") {
            setActiveIndex(10)
        }
        else if (location.pathname.split('/')[1] === "privacy-policy") {
            setActiveIndex(10)
        }
    }, [location.pathname])
    return (
        <div className='sidebar_main'>
            {
                SidebarItems.map((elem, index) => {
                    return (
                        <Link to={elem.link} className={'sidebar_item'} key={index} onClick={() => { onClickSidebarItem(index) }}>
                            <div className='left'>
                                <img alt={elem.text} src={activeIndex === index ? elem.imgUrl2 : elem.imgUrl} />
                            </div>
                            <div className='right' style={{ color: activeIndex === index ? "White" : Colors.heading, fontFamily: Font.Sidebar }}>
                                {elem.text}
                            </div>
                        </Link>
                    );
                })
            }
            <div className='animated_shadow' style={{ top: (activeIndex * 52) + (activeIndex * 6), backgroundColor: "#17ba86" }}>
            </div>
        </div>
    );
}
export const ProfileCard = (props) => {
    const [dropStatus, setDropStatus] = React.useState(false)
    return (
        <React.Fragment>
            {<Link className='profile_card_as_link' style={{ position: "relative" }} to={props.onClick && props.link}>
                <div className={props.view === 'grid' ? "card" : "card_list"}>
                    <div className="menu">
                        <img src={require('../../assets/icon/dot.png')} alt="card menu" onClick={() => { setDropStatus(!dropStatus) }} />
                    </div>
                    <div className="profile_pic">
                        <img src={props.for.img} alt={props.for.alt} />
                    </div>
                    <div className={props.view === 'grid' ? "gridview_card_content" : "listview_card_content"}>
                        <div className='content_item'>
                            <div className='title' >{props.data.name}</div>
                            {props.data && props.data.for === "appointments" && <div className='clinic_name'>{props.data.clinic}</div>}
                            {props.data && (props.data.dep || props.data.doctor) && <div className='spl'>{props.data.dep ? props.data.dep : props.data.doctor && props.data.doctor}</div>}
                            {props.data && props.data.for === "appointments" && props.data.clinic_address && <div className='clinic_address'>{props.data.clinic_address}</div>}
                            {props.data && props.data.for && props.data.for === "patient" && <div className='patient_details'>
                                <p>Total payments: <span>{props.data.total_payment}/-</span></p>
                                <p>Last visited on: <span>{props.data.last_visited_on}</span></p>
                                <p>Last visited: <span>{props.data.last_visited}</span></p>
                            </div>}
                        </div>
                        {props.socialMedia && <div className='social_icons' style={{ gap: window.innerWidth <= 768 && '4px' }}>
                            {
                                props.socialMedia.map((elem, index) => {
                                    return (
                                        <img src={elem.img} key={index} alt={elem.alt} />
                                    )
                                })
                            }
                        </div>}
                    </div>

                    {props.message && props.message === true && <div className='message'>
                        Message
                    </div>}
                </div>
                {(props.activeStatus === true || props.activeStatus === false) && <div style={{ position: "absolute", display: "flex", alignItems: "center", gap: 6, right: 12, top: 12 }}>
                    <p style={{ margin: 0, border: "1px solid", backgroundColor: props.activeStatus === true ? "mediumseagreen" : "red", color: "white", padding: "6px 8px", borderRadius: "13px", fontSize: "10px" }}>{props.activeStatus === true ? "Active" : "Inactive"}</p>
                </div>}
            </Link>}
        </React.Fragment>
    );
}

export const DoctorProfileCard = (props) => {
    const [rColor, setRColor] = React.useState()
    React.useMemo(() => {
        if (props) {
            setRColor(Math.floor(Math.random() * 999999))
        }
    }, [props])
    const navigate = useNavigate()
    return (
        <React.Fragment>
            <div className='profile_card_as_link' onClick={() => { navigate(`/doctor/details/${props.data.id}`) }}  style={{ backgroundColor: props.background && props.background }}>
                <div className={props.view === 'grid' ? "card" : "card_list"}>
                    <div  className="profile_pic">
                        <div style={{ position: 'relative' }}>
                            <Avatar sx={{ width: 84, height: 84, fontSize: 36, bgcolor: `#${rColor}` }} className="shadow">{
                                (props.data.name && props.data.name.split(' ') && props.data.name.split(' ').length > 2) ? `${props.data.name.split(' ')[1].charAt(0).toUpperCase()}${props.data.name.split(' ')[2].charAt(0).toUpperCase()}` :
                                    (props.data.name && props.data.name.split(' ') && props.data.name.split(' ').length === 2) ? `${props.data.name.split(' ')[0].charAt(0).toUpperCase()}${props.data.name.split(' ')[1].charAt(0).toUpperCase()}` : props?.data?.name.split(' ')[0]?.charAt(0).toUpperCase()
                            }</Avatar>
                             <img src={props.data.active_status ? require('../../assets/icon/verified.png') : require('../../assets/icon/unverified.png')} alt={props.data.active_status ? "verified" : "Unverified"} style={{ width: 32, height: 32, boxShadow: 'none', padding: 0, background: 'none',position:'absolute',bottom:-4,right:0 }} />
                        </div>
                    </div>
                    <div className={props.view === 'grid' ? "gridview_card_content" : "listview_card_content"}>
                        <div className='content_item'>
                            <div className='title' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6 }}>
                                <span >{props.data.name}</span>
                            </div>
                            <div className="spl">
                                <div className='spl_flex'>
                                    {
                                        props.data.specializations && props.data.specializations.split(',') && props.data.specializations.split(',').length > 0 && props.data.specializations.split(',').map((splItem, splIndex) => {
                                           
                                                return (
                                                    splIndex < 2&& <Chip key={splIndex} label={splItem} />
                                                )
                                            
                                        })
                                    }
                                </div>
                            </div>
                            {props.data && (props.data.dep || props.data.doctor) && <div className='spl'>{props.data.dep ? props.data.dep : props.data.doctor && props.data.doctor}</div>}
                        </div>
                        <div className='social_icons'>
                            {props.data.contact_no && <a href={`tel:${props.data.contact_no}`}>
                                <img src={require('../../assets/icon/social/phone.png')} alt={props.data.contact_no} />
                            </a>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export const DoctorSocialMedia = [
    {
        img: require('../../assets/icon/social/facebook.png'),
        alt: 'facebook'
    },
    {
        img: require('../../assets/icon/social/instagram.png'),
        alt: 'instagram'
    },
    {
        img: require('../../assets/icon/social/twitter.png'),
        alt: 'twitter'
    },
    {
        img: require('../../assets/icon/social/gmail.png'),
        alt: 'gmail'
    }
]
export const ClinicSocialMedia = [
    {
        img: require('../../assets/icon/social/phone.png'),
        alt: 'facebook'
    },
    {
        img: require('../../assets/icon/social/gmail.png'),
        alt: 'instagram'
    },
    {
        img: require('../../assets/icon/social/google-maps.png'),
        alt: 'twitter'
    }
]
const SidebarItems = [
    {
        'imgUrl': require('../../assets/icon/sidebar_icon/dashboard.png'),
        'imgUrl2': require('../../assets/icon/sidebar_icon/dashboard_white.png'),
        'text': 'Dashboard',
        'link': '/'
    },
    {
        'imgUrl': require('../../assets/icon/sidebar_icon/calendar.png'),
        'imgUrl2': require('../../assets/icon/sidebar_icon/calender_white.png'),
        'text': 'Appointments',
        'link': '/appointment'
    },
    {
        'imgUrl': require('../../assets/icon/sidebar_icon/doctor.png'),
        'imgUrl2': require('../../assets/icon/sidebar_icon/doctor_white.png'),
        'text': 'Doctors',
        'link': '/doctor'
    },
    {
        'imgUrl': require('../../assets/icon/sidebar_icon/patient.png'),
        'imgUrl2': require('../../assets/icon/sidebar_icon/patient_white.png'),
        'text': 'Patients',
        'link': '/patients'
    },
    {
        'imgUrl': require('../../assets/icon/sidebar_icon/clinic.png'),
        'imgUrl2': require('../../assets/icon/sidebar_icon/clinic_white.png'),
        'text': 'Clinics',
        'link': '/clinic'
    },
    {
        'imgUrl': require('../../assets/icon/sidebar_icon/specialization.png'),
        'imgUrl2': require('../../assets/icon/sidebar_icon/specialization_white.png'),
        'text': 'Specializations',
        'link': '/specialization'
    },
    {
        'imgUrl': require('../../assets/icon/sidebar_icon/payment.png'),
        'imgUrl2': require('../../assets/icon/sidebar_icon/payment_white.png'),
        'text': 'Payments',
        'link': '/payment'
    },
    {
        'imgUrl': require('../../assets/icon/sidebar_icon/setting.png'),
        'imgUrl2': require('../../assets/icon/sidebar_icon/setting_white.png'),
        'text': 'Settings',
        'link': '/setting'
    },
    {
        'imgUrl': require('../../assets/icon/sidebar_icon/terms.png'),
        'imgUrl2': require('../../assets/icon/sidebar_icon/terms_white.png'),
        'text': 'Terms & Conditions',
        'link': '/terms_and_condition'
    },
    {
        'imgUrl': require('../../assets/icon/sidebar_icon/about.png'),
        'imgUrl2': require('../../assets/icon/sidebar_icon/about_white.png'),
        'text': 'About',
        'link': '/about'
    },
    {
        'imgUrl': require('../../assets/icon/sidebar_icon/privacy.png'),
        'imgUrl2': require('../../assets/icon/sidebar_icon/privacy_white.png'),
        'text': 'Privacy & Policies',
        'link': '/privacy-policy'
    }
]

export const Colors = {
    heading: "#00b3fe",
    background: "#01edc9"
}
export const Font = {
    Sidebar: `'Poppins', sans-serif`
}
export const DashboardHomePage = () => {
    const dispatch = useDispatch()
    const selector = useSelector(dashboardSelector)
    const appointmentSelect = useSelector(appoinmentSelector)
    const paymentSelect = useSelector(paymentSelector)
    const [countData, setCountData] = React.useState([])
    React.useEffect(() => {
        dispatch(dashboardActions.getAllCount())
    }, [dispatch])
    React.useEffect(() => {
        dispatch(appoinmentActions.appointmentPerformance({ "filter": "year" }))
    }, [dispatch])
    React.useEffect(() => {
        if (selector && selector.data) {
            let arr = [
                {
                    "header": "Appoinments",
                    "count": selector.data.total_appointment === 0 ? selector.data.total_appointment : `${selector.data.total_appointment} +`,
                    "img": require('../../assets/img/small_cards_icon/appointment.png'),
                    "link":"/appointment"
                },
                {
                    "header": "Payments",
                    "count": selector.data.total_payment === 0 ? selector.data.total_payment : `${selector.data.total_payment} +`,
                    "img": require('../../assets/img/small_cards_icon/payment.png'),
                    "link":"/payment"
                },
                {
                    "header": "Doctors",
                    "count": selector.data.total_doctor === 0 ? selector.data.total_doctor : `${selector.data.total_doctor} +`,
                    "img": require('../../assets/img/small_cards_icon/doctor.png'),
                    "link":"/doctor"
                },
                {
                    "header": "Patients",
                    "count": selector.data.total_patient === 0 ? selector.data.total_patient : `${selector.data.total_patient} +`,
                    "img": require('../../assets/img/small_cards_icon/wheel-chair.png'),
                    "link":"/patients"
                }
            ]
            setCountData(arr)
        }
    }, [selector])
    const [appointmentFilterData, setAppointmentFilterData] = React.useState()
    React.useEffect(() => {
        if (appointmentSelect && appointmentSelect.data && appointmentSelect.data instanceof Array) {
            if (appointmentSelect.data.length > 0) {
                setAppointmentFilterData(appointmentSelect)
            }
            else {
                setAppointmentFilterData([])
            }
        }
    }, [appointmentSelect])
    React.useEffect(() => {
        dispatch(paymentActions.paymentPerformance({ "filter": "year" }))
    }, [dispatch])
    const [paymentFilterData, setPaymentFilterData] = React.useState()
    React.useEffect(() => {
        if (paymentSelect && paymentSelect.data && paymentSelect.data instanceof Array) {
            if (paymentSelect.data.length > 0) {
                setPaymentFilterData(paymentSelect)
            }
            else {
                setPaymentFilterData([])
            }
        }
    }, [paymentSelect])
    return (
        <React.Fragment>
            {selector && selector.data && selector.isFetching === false &&
                <div className={'small_card_wrapper'}>
                    {
                        countData.map((elem, index) => {
                            return (
                                <SmallCard data={elem} key={index} />
                            )
                        })
                    }
                </div>}
            <div className="chart_flex">
                <div className='card_shadow'>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                        <p style={{ paddingBottom: 0, margin: 0, fontWeight: 'bold' }}>Appointments</p>
                        <Link to="/appointment" style={{ color: '#314c7c' }}><button style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14 }}>View All</button></Link>
                    </div>
                    {
                        appointmentSelect.isFetching ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '48px 0' }}>
                                <CircularProgress />
                            </div> : appointmentFilterData && appointmentFilterData.data && appointmentFilterData.data.length > 0 ? <AreaChart data={
                                {
                                    'labels': appointmentFilterData.data.map(e => e._id),
                                    'datasets': [
                                        {
                                            'fill': true,
                                            'label': "Count",
                                            'lineTension': 0.5,
                                            'data': appointmentFilterData.data.map(e => e.count),
                                            backgroundColor: "rgba(75,192,192,0.2)",
                                            borderColor: "rgba(75,192,192,1)"
                                        }
                                    ]
                                }
                            } /> :
                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 240, maxWidth: '100%' }} />
                                </div>
                    }
                </div>
                <div className='card_shadow'>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                        <p style={{ paddingBottom: 0, margin: 0, fontWeight: 'bold' }}>Payments</p>
                        <Link to="/payment" style={{ color: '#314c7c' }}><button style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14 }}>View All</button></Link>
                    </div>
                    {
                        paymentSelect.isFetching ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '48px 0' }}>
                                <CircularProgress />
                            </div> : paymentFilterData && paymentFilterData.data && paymentFilterData.data.length > 0 ? <AreaChart data={
                                {
                                    'labels': paymentFilterData.data.map(e => e._id),
                                    'datasets': [
                                        {
                                            'fill': true,
                                            'label': "Count",
                                            'lineTension': 0.5,
                                            'data': paymentFilterData.data.map(e => e.count),
                                             borderColor:"rgb(23, 113, 101)",
                                             backgroundColor: "rgba(23, 113, 101,0.2)"
                                        },
                                        {
                                            'fill': true,
                                            'label': "Amount",
                                            'lineTension': 0.5,
                                            'data': paymentFilterData.data.map(e => e.fees),
                                            borderColor:"rgb(0, 179, 254)",
                                            backgroundColor: "rgba(0, 179, 254,0.2)"
                                        }
                                    ]
                                }
                            } /> :
                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 240, maxWidth: '100%' }} />
                                </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export const DoctorHomePage = () => {
    const [viewStatus, setViewStatus] = React.useState('grid')
    const dispatch = useDispatch()
    const selector = useSelector(doctorSelector)
    const [rows, setRows] = React.useState([])
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState()
    const [searchVal, setSearchVal] = React.useState('')
    const [searchApi, setSearchApi] = React.useState('')
    React.useEffect(() => { setLimit(6) }, [])
    React.useEffect(() => {
        if (searchApi) {
            dispatch(doctorActions.doctorSearch({ 'q': searchApi, 'page': page, 'limit': limit }))
        }
        else {
            dispatch(doctorActions.getAll({ 'page': page, 'limit': limit }))
        }
    }, [dispatch, page, limit, searchApi])
    React.useEffect(() => {
        if (selector && selector.data && selector.data.rows && selector.data.rows instanceof Array && selector.data.rows.length > 0) {
            setRows(selector.data.rows)
        }
        else {
            setRows([])
        }
    }, [selector])
    const someFunction = (val) => {
        setSearchApi(val)
        if (val) {
            setPage(1)
        }
    }
    const handler = React.useCallback(debounce(someFunction, 500), []);
    const handleSearchChange = (val) => {
        setSearchVal(val);
        handler(val)
    }

    return (
        <React.Fragment>
            <div className='top_header'>
                <div className='left'>
                    <h1>View</h1>
                    <div className='view_status'>
                        <img src={require('../../assets/icon/grid.png')} alt="grid" onClick={() => { setViewStatus('grid') }} />
                        <img src={require('../../assets/icon/list.png')} alt="list" onClick={() => { setViewStatus('list') }} />
                        <div className='animate' style={{ left: viewStatus === 'grid' ? 0 : 40.988 }}></div>
                    </div>
                </div>
                <div className="right">
                    <div className='input_wrapper'>
                        <div className='input'>
                            <input placeholder="Search Doctors" value={searchVal} onChange={(e) => { handleSearchChange(e.target.value) }} />
                        </div>
                        <div className='icon'>
                            <img alt="search" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABZklEQVRIidXUsWpTcRTH8U9SpFgo1g6xU5d2qX0CoZPdtBJwELL5Ap19gWyBdip0yyh0VMgkDi4dzBLzBsUpCC1dbId4O/zP1YAh994YKP7gcuCec8/3/M8958//rlqB/xFeYAfrGOErPuP2X8Cr6OAnsinPCIeoz5N8E8NI1EMTT/AgfG8xCP8HrFStfBiVt2bE1dEOyJniVv9WJz6alXxSxxH/pkzwmlR5r2w1eIgLqWWFakU1zQoA/rRqa5pzcgqehj2vCMjjd4sAa2GvKgIuwz4uAozCblQE5PE/igD9sM8rAvalf9AvClyWTjFQfkMbuManstUcRjXtErFL0kiPsVcWUJfWP8ORNOfT1IjkeWsq3Ukr0vpn0hK18RLP8BonUlvGkTxDtyqkJq3/N3/fpL+knu9F0u68kFzbeCXdogdSeya1EEiRajgNyHtpABauyZO8y18ukpThI27wBd8XmPsedQcLnFSiSu7vvQAAAABJRU5ErkJggg=="></img>
                        </div>
                    </div>
                </div>
            </div>
            {selector.isFetching ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '128px 0', width: '100%' }}>
                <CircularProgress />
            </div> :
                <React.Fragment>
                    {rows.length === 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={require('../../assets/img/no_data.png')} style={{ height: 'auto', maxWidth: '100%' }} alt="img" />
                        </div>
                        : <div className={`${viewStatus === "grid" ? "list_grid" : "without_grid"}`}>
                            {
                                rows && rows.length > 0 && rows.map((elem, index) => {
                                    return (
                                        <DoctorProfileCard key={index} message={true} onClick={true} for={{ img: require('../../assets/icon/male_avatar.png'), alt: 'profile' }} socialMedia={DoctorSocialMedia} data={elem} view={viewStatus} />
                                    )
                                })
                            }
                        </div>}
                </React.Fragment>
            }
            {(selector && selector.data && selector.data.total_rows && rows.length > 0) ? <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
                <Pagination count={Math.ceil(selector.data.total_rows / limit)} color='primary' page={page} onChange={(event, value) => { setPage(value) }} />
            </div> : null}
        </React.Fragment>
    );
}
export const ClinicProfileCard = (props) => {
    const [rColor, setRColor] = React.useState()
    React.useMemo(() => {
        if (props) {
            setRColor(Math.floor(Math.random() * 999999))
        }
    }, [props])
    const navigate = useNavigate()
    return (
        <React.Fragment>
            <div className='profile_card_as_link' style={{ backgroundColor: props.background && props.background }}>
                <div className={props.view === 'grid' ? "card" : "card_list"}>
                    <div onClick={() => { navigate(`/clinic/details/${props.data.id}`) }} className="profile_pic">
                        <div className='clinic_profile_' style={{ position: 'relative' }}>
                            <Avatar sx={{ width: 84, height: 84, fontSize: 36, bgcolor: `#${rColor}` }} className="shadow">{
                                (props.data.name && props.data.name.split(' ') && props.data.name.split(' ').length > 2) ? `${props.data.name.split(' ')[1].charAt(0).toUpperCase()}${props.data.name.split(' ')[2].charAt(0).toUpperCase()}` :
                                    (props.data.name && props.data.name.split(' ') && props.data.name.split(' ').length === 2) ? `${props.data.name.split(' ')[0].charAt(0).toUpperCase()}${props.data.name.split(' ')[1].charAt(0).toUpperCase()}` : props.data.name.charAt(0).toUpperCase()
                            }</Avatar>
                             <img src={require(`../../assets/icon/${props.data.active_status ? "verified.png" : "unverified.png"}`)} alt={props.data.active_status ? "Verified" : "Unverified"} style={{ width: 32, height: 32, boxShadow: 'none', padding: 0, background: 'none',position:"absolute",bottom:-4,right:-4}} />
                        </div>
                    </div>
                    <div className={props.view === 'grid' ? "gridview_card_content" : "listview_card_content"}>
                        <div className='content_item' >
                            <div className='title' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6 }}>
                                <span>{props.data.name}</span>
                            </div>
                            <span style={{textAlign:"center",width:"100%", color: 'gray' }}>{props.data.address}</span>
                        </div>
                        <div className='social_icons'>
                            {props.data.contact_no && <a href={`tel:${props.data.contact_no}`}>
                                <img src={require('../../assets/icon/social/phone.png')} alt={props.data.contact_no} />
                            </a>}
                            {props.data.email && <a href={`mailto:${props.data.email}`}>
                                <img src={require('../../assets/icon/social/gmail.png')} alt={props.data.email} />
                            </a>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export const ClinicHomePage = () => {
    const [viewStatus, setViewStatus] = React.useState('grid')
    const dispatch = useDispatch()
    const selector = useSelector(clinicSelector)
    const [rows, setRows] = React.useState([])
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(6)
    const [searchVal, setSearchVal] = React.useState('')
    const [searchApi, setSearchApi] = React.useState('')
    React.useEffect(() => {
        setLimit(6)
    }, [])
    React.useEffect(() => {
        if (searchApi) {
            dispatch(clinicActions.searchClinic({ 'q': searchApi, 'page': page, 'limit': limit }))
        }
        else {
            dispatch(clinicActions.getAll({ 'page': page, 'limit': limit }))
        }
    }, [dispatch, page, limit, searchApi])
    React.useEffect(() => {
        if (selector && selector.data && selector.data.rows && selector.data.rows instanceof Array && selector.data.rows.length > 0) {
            setRows(selector.data.rows)
        }
        else {
            setRows([])
        }
    }, [selector])
    const someFunction = (val) => {
        setSearchApi(val)
        if (val) {
            setPage(1)
        }
    }
    const handler = React.useCallback(debounce(someFunction, 500), []);
    const handleSearchChange = (val) => {
        setSearchVal(val);
        handler(val)
    }
    return (
        <React.Fragment>
            <div className='top_header'>
                <div className='left'>
                    <h1>View</h1>
                    <div className='view_status'>
                        <img src={require('../../assets/icon/grid.png')} alt="grid" onClick={() => { setViewStatus('grid') }} />
                        <img src={require('../../assets/icon/list.png')} alt="list" onClick={() => { setViewStatus('list') }} />
                        <div className='animate' style={{ left: viewStatus === 'grid' ? 0 : 40.988 }}></div>
                    </div>
                </div>
                <div className='right' style={{ alignItems: 'center', flexDirection: 'row' }}>
                    <div className='input_wrapper'>
                        <div className='input'>
                            <input placeholder="Search Clinics" value={searchVal} onChange={(e) => { handleSearchChange(e.target.value) }} />
                        </div>
                        <div className='icon'>
                            <img alt="search" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABZklEQVRIidXUsWpTcRTH8U9SpFgo1g6xU5d2qX0CoZPdtBJwELL5Ap19gWyBdip0yyh0VMgkDi4dzBLzBsUpCC1dbId4O/zP1YAh994YKP7gcuCec8/3/M8958//rlqB/xFeYAfrGOErPuP2X8Cr6OAnsinPCIeoz5N8E8NI1EMTT/AgfG8xCP8HrFStfBiVt2bE1dEOyJniVv9WJz6alXxSxxH/pkzwmlR5r2w1eIgLqWWFakU1zQoA/rRqa5pzcgqehj2vCMjjd4sAa2GvKgIuwz4uAozCblQE5PE/igD9sM8rAvalf9AvClyWTjFQfkMbuManstUcRjXtErFL0kiPsVcWUJfWP8ORNOfT1IjkeWsq3Ukr0vpn0hK18RLP8BonUlvGkTxDtyqkJq3/N3/fpL+knu9F0u68kFzbeCXdogdSeya1EEiRajgNyHtpABauyZO8y18ukpThI27wBd8XmPsedQcLnFSiSu7vvQAAAABJRU5ErkJggg=="></img>
                        </div>
                    </div>
                    <Link style={{ color: "white", background: '#8999b4', padding: 12, borderRadius: 6, width: 110 }} to="/clinic/create">Add Clinic</Link>
                </div>
            </div>
            {selector.isFetching ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '128px 0', width: '100%' }}>
                <CircularProgress />
            </div> :
                <React.Fragment>
                    {rows.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={require('../../assets/img/no_data.png')} style={{ height: 'auto', maxWidth: '100%' }} alt='img' />
                    </div> :
                        <div className={`${viewStatus === "grid" ? "list_grid" : "without_grid"}`}>
                            {
                                rows && rows.length > 0 && rows.map((elem, index) => {
                                    return (
                                        <ClinicProfileCard key={index} message={true} onClick={true} for={{ img: require('../../assets/icon/male_avatar.png'), alt: 'profile' }} socialMedia={DoctorSocialMedia} data={elem} view={viewStatus} />
                                    )
                                })
                            }
                        </div>}
                </React.Fragment>
            }
            {(selector && selector.data && selector.data.total_rows && rows.length > 0) ? <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
                <Pagination count={Math.ceil(selector.data.total_rows / limit)} color='primary' page={page} onChange={(event, value) => { setPage(value) }} />
            </div> : null}
        </React.Fragment>
    );
}

export const PatientProfileCard = (props) => {
    const [rColor, setRColor] = React.useState()
    React.useMemo(() => {
        if (props) {
            setRColor(Math.floor(Math.random() * 999999))
        }
    }, [props])
    const navigate = useNavigate()
    const [imgLoading, setImgLoading] = React.useState(true)
    return (
        <React.Fragment>
            <div className='profile_card_as_link'>
                <div className={props.view === 'grid' ? "card" : "card_list"}>
                    <div onClick={() => { navigate(`/patients/details/${props.data.id}`) }} className="profile_pic">
                        <div style={{ position: 'relative' }}>
                            {
                                props.data && props.data.profile_pic ?
                                    <div style={{ position: 'relative' }}>
                                        <Avatar sx={{ width: 84, height: 84, fontSize: 36 }} src={`${BASE_URL}${props.data.profile_pic.slice(2)}`} onLoad={(e) => { setImgLoading(false) }} />
                                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                                            {imgLoading && <Skeleton variant="circular" width={84} height={84} />}
                                        </div>
                                    </div>
                                    :
                                    <Avatar sx={{ width: 84, height: 84, fontSize: 36, bgcolor: `#${rColor}` }} className="shadow">{
                                        (props.data.name && props.data.name.split(' ') && props.data.name.split(' ').length > 2) ? `${props.data.name.split(' ')[1].charAt(0).toUpperCase()}${props.data.name.split(' ')[2].charAt(0).toUpperCase()}` :
                                            (props.data.name && props.data.name.split(' ') && props.data.name.split(' ').length === 2) ? `${props.data.name.split(' ')[0].charAt(0).toUpperCase()}${props.data.name.split(' ')[1].charAt(0).toUpperCase()}` : props.data.name.charAt(0).toUpperCase()
                                    }</Avatar>
                            }
                        </div>
                    </div>
                    <div className={props.view === 'grid' ? "gridview_card_content" : "listview_card_content"}>
                        <div className='content_item'>
                            <div className='title' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6 }}>
                                <span onClick={() => { navigate(`/patients/details/${props.data.id}`) }}>{props.data.name}</span>
                            </div>
                            {props.data && (props.data.dep || props.data.doctor) && <div className='spl'>{props.data.dep ? props.data.dep : props.data.doctor && props.data.doctor}</div>}
                        </div>
                        <div className='social_icons'>
                            {props.data.contact_no && <a href={`tel:${props.data.contact_no}`}>
                                <img src={require('../../assets/icon/social/phone.png')} alt={props.data.contact_no} />
                            </a>}
                            {props.data.email && <a href={`mailto:${props.data.email}`}>
                                <img src={require('../../assets/icon/social/gmail.png')} alt={props.data.email} />
                            </a>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export const PaitentHomePage = () => {
    const [viewStatus, setViewStatus] = React.useState('grid')
    const dispatch = useDispatch()
    const selector = useSelector(patientSelector)
    const [rows, setRows] = React.useState([])
    const [page, setPage] = React.useState(1)
    const [limit,] = React.useState(6)
    const [searchVal, setSearchVal] = React.useState('')
    const [searchApi, setSearchApi] = React.useState('')
    React.useEffect(() => {
        if (searchApi) {
            dispatch(patientActions.searchPatients({ 'q': searchApi, 'page': page, 'limit': limit }))
        }
        else {
            dispatch(patientActions.getAll({ 'page': page, 'limit': limit }))
        }
    }, [dispatch, page, limit, searchApi])
    React.useEffect(() => {
        if (selector && selector.data && selector.data.rows && selector.data.rows instanceof Array && selector.data.rows.length > 0) {
            setRows(selector.data.rows)
        }
        else {
            setRows([])
        }
    }, [selector])
    const someFunction = (val) => {
        setSearchApi(val)
        if (val!=='') {
            dispatch(patientActions.searchPatients({ 'q': val, 'page': 1, 'limit': limit }))
            setPage(1)
        }
    }
    const handler=React.useCallback(debounce(someFunction,500),[])
    const handleSearchChange = (val) => {
        setSearchVal(val);
        handler(val)
    }
  
    return (
        <React.Fragment>
            <div className='top_header'>
                <div className='left'>
                    <h1>View</h1>
                    <div className='view_status'>
                        <img src={require('../../assets/icon/grid.png')} alt="grid" onClick={() => { setViewStatus('grid') }} />
                        <img src={require('../../assets/icon/list.png')} alt="list" onClick={() => { setViewStatus('list') }} />
                        <div className='animate' style={{ left: viewStatus === 'grid' ? 0 : 40.988 }}></div>
                    </div>
                </div>
                <div className="right">
                    <div className='input_wrapper'>
                        <div className='input'>
                            <input placeholder="Search Patients" value={searchVal} onChange={(e) => { handleSearchChange(e.target.value) }} />
                        </div>
                        <div className='icon'>
                            <img alt="search" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABZklEQVRIidXUsWpTcRTH8U9SpFgo1g6xU5d2qX0CoZPdtBJwELL5Ap19gWyBdip0yyh0VMgkDi4dzBLzBsUpCC1dbId4O/zP1YAh994YKP7gcuCec8/3/M8958//rlqB/xFeYAfrGOErPuP2X8Cr6OAnsinPCIeoz5N8E8NI1EMTT/AgfG8xCP8HrFStfBiVt2bE1dEOyJniVv9WJz6alXxSxxH/pkzwmlR5r2w1eIgLqWWFakU1zQoA/rRqa5pzcgqehj2vCMjjd4sAa2GvKgIuwz4uAozCblQE5PE/igD9sM8rAvalf9AvClyWTjFQfkMbuManstUcRjXtErFL0kiPsVcWUJfWP8ORNOfT1IjkeWsq3Ukr0vpn0hK18RLP8BonUlvGkTxDtyqkJq3/N3/fpL+knu9F0u68kFzbeCXdogdSeya1EEiRajgNyHtpABauyZO8y18ukpThI27wBd8XmPsedQcLnFSiSu7vvQAAAABJRU5ErkJggg=="></img>
                        </div>
                    </div>
                </div>
            </div>
            {selector.isFetching ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '128px 0', width: '100%' }}>
                <CircularProgress />
            </div> :
                <React.Fragment>
                    {rows.length === 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={require('../../assets/img/no_data.png')} style={{ height: 'auto', maxWidth: '100%' }} alt="img" />
                        </div> :
                        <div className={`${viewStatus === "grid" ? "list_grid" : "without_grid"}`}>
                            {
                                rows && rows.length > 0 && rows.map((elem, index) => {
                                    return (
                                        <PatientProfileCard key={index} message={true} onClick={true} for={{ img: require('../../assets/icon/male_avatar.png'), alt: 'profile' }} socialMedia={DoctorSocialMedia} data={elem} view={viewStatus} />
                                    )
                                })
                            }
                        </div>}
                </React.Fragment>
            }
            {(selector && selector.data && selector.data.total_rows && rows.length > 0) ? <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
                <Pagination count={Math.ceil(selector.data.total_rows / limit)} color='primary' page={page} onChange={(event, value) => { setPage(value) }} />
            </div> : null}
        </React.Fragment>
    );
}
