import { call, put, takeLatest } from "redux-saga/effects";
import { termsActions } from "../../slice/terms/termsSlice";
import termsApi from "../../../api/terms/termsApi";
function* getOne(action) {
    try {
        const response = yield call(termsApi.getOne,action.payload);
        yield put(termsActions.success(response));
    } catch (error) {
        yield put(termsActions.failed(error.message));
    }
}
export default function* termsSaga() {
    yield takeLatest(termsActions.getOne.type, getOne)
}