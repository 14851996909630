import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Home } from '../pages/home/Home';
import { Login } from '../pages/login/Login';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import authSelector from '../redux/selector/auth/authSelector';
import React from 'react';
import axiosClient from '../api/axiosClient';
import { authActions } from '../redux/slice/auth/authSlice';
import { ForgotPassword } from '../pages/forgot_password/ForgotPassword';
import {NewTerms}  from '../terms-privacy/terms_privacy';
// import { NewTerms } from '../../terms-privacy/terms_privacy';
// import { Main } from '../../terms-privacy/main';
 import {Terms} from '../terms-privacy/terms/Terms'
 import {PrivacyPolicy} from '../terms-privacy/privacy/Privacy'
import { UserDelete } from '../userDelete/UserDelete';
export const BaseRouting = () => {
    const authSelect = useAppSelector(authSelector)
    const [accessToken,setAccessToken]=React.useState()
    const dispatch = useAppDispatch()
    React.useEffect(() => {
        if (localStorage.getItem("refresh_token") && localStorage.getItem("access_token")) {
            dispatch(authActions.refreshLogin({ "token": localStorage.getItem("refresh_token") }))
            axiosClient.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` }
        }
    }, [dispatch])
    React.useEffect(() => {
        console.log("auth selector:",authSelect)
          if (authSelect && authSelect.data && authSelect.data.status && authSelect.data.access_token !== null && authSelect.data.access_token !== undefined) {
              localStorage.setItem("refresh_token", authSelect.data.refresh_token)
              localStorage.setItem("access_token", authSelect.data.access_token)
              
              
          } 
          if (authSelect && authSelect.data && authSelect.data.status && authSelect.data.access_token !== null && authSelect.data.access_token !== undefined) {
              axiosClient.defaults.headers.common = { 'Authorization': `Bearer ${authSelect.data.access_token}` }
          }
          if(localStorage.getItem("access_token")){
            console.log("called inner")
            setAccessToken(localStorage.getItem("access_token"))
        }
      }, [authSelect,authSelect?.isFetching])
      React.useEffect(()=>{
if(localStorage.getItem("access_token")){
    setAccessToken(localStorage.getItem("access_token"))
}

      },[authSelect,authSelect?.isFetching])
    return (
        <BrowserRouter basename="/">
            <Routes>
                {
                    authSelect && authSelect.data && authSelect.data.status && authSelect.data.access_token !== null && authSelect.data.access_token !== undefined &&accessToken?
                        <Route path="/*" element={<Home />} /> :
                        authSelect.isFetching === false && <Route path="/*" element={<Login />} />
                }
                <Route path="/forgot_password" element={<ForgotPassword />} />
                
                <Route path="/policies" element={<NewTerms />} />
                <Route path="/policies/terms-condition" element={<Terms />} />
                <Route path="/policies/privacy-policy" element={<PrivacyPolicy />} /> 
                <Route path="/user_delete" element={<UserDelete />}/> 
            </Routes>
        </BrowserRouter>
    );
}