import { Button, CircularProgress } from "@mui/material";
import React from "react";
import './style.scss'
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../../components/buttons/Buttons";
import aboutSelector from "../../redux/selector/about/aboutSelector";
import { aboutActions } from "../../redux/slice/about/aboutSlice";
import CustomDialog from "../settings/CustomDialog";
import { Tiptap } from "../../components/html_generator/Tiptap";
import AlertMsg from "../../components/alert/AlertMsg";
import { aboutDeleteActions } from "../../redux/slice/about/aboutDeleteSlice";
import aboutDeleteSelector from "../../redux/selector/about/aboutDeleteSelector";
import { aboutAddActions } from "../../redux/slice/about/aboutAddSlice";
import { aboutUpdateActions } from "../../redux/slice/about/aboutUpdateSlice";
export const About = () => {
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = React.useState(false)
    const [type, setType] = React.useState()
    const [response, setResponse] = React.useState()
    const [emptyMsg, setEmptyMsg] = React.useState(false)
    const [processing, setProcessing] = React.useState()
    const selector = useSelector(aboutSelector)
    const deleteSelector = useSelector(aboutDeleteSelector)
    const [createMsg, setCreateMsg] = React.useState(false)
    const [updateMsg, setUpdateMsg] = React.useState(false)
    const getResponse = (props) => {
        setResponse(props)
        console.log(props)
        if (props.data.type === "update") {
            setEmptyMsg(false)
            setCreateMsg(false)
            setUpdateMsg(true)
            dispatch(aboutUpdateActions.reset())
        }
        if (props.data.type === "add") {
            setEmptyMsg(false)
            setCreateMsg(true)
            setUpdateMsg(false)
            dispatch(aboutAddActions.reset())
        }
        if (props.data.type === "delete") {
            
            setCreateMsg(false)
            setUpdateMsg(false)
            dispatch(aboutDeleteActions.reset())
            deleteSelector&&deleteSelector.data.status&&processing===false&&setEmptyMsg(true)

        }
        ModalClose()

    }
    const create = () => {
        setModalOpen(true)
        setType("create")
    }
    const ModalClose = () => {
        setModalOpen(false)
    }
    const deleteData = () => {
        dispatch(aboutDeleteActions.delete({ url: 'about-us' }))
        setType("delete")
        getResponse(deleteSelector)
    }
    React.useEffect(() => {
        if (modalOpen === false) {
            deleteSelector&&deleteSelector.isFetching === true&& !deleteSelector.data.status && setProcessing(true)
            deleteSelector&&deleteSelector.isFetching === false&&deleteSelector.data&&deleteSelector.data.status && setProcessing(false)
            dispatch(aboutActions.getOne('about-us'))
           
        }
    }, [dispatch, modalOpen, createMsg, updateMsg, emptyMsg, type,deleteSelector.isFetching,processing])
    
    return (
        <React.Fragment>
            <div className="top_header">
                <BackButton />
            </div>
            <div className='top_header' >
                <div className='left'>
                    <h1>About</h1>
                </div>
            </div>

            {selector && selector.data &&selector?.data?.data && selector?.data?.data?.content && <React.Fragment>
                <div dangerouslySetInnerHTML={{ __html: selector&&selector?.data?.data?.content }} id="content" style={{ width: 'calc(100% - 24px)', backgroundColor: 'white', borderRadius: '6px', padding: 12 }}>
                </div>
                <div className='settings_footer'>
                    <Button className='btn_delete' onClick={() => { deleteData() }}>DELETE</Button>
                    <Button className='btn_change' onClick={() => { setModalOpen(true); setType("edit") }}>UPDATE</Button>
                </div>
            </React.Fragment>}
            {selector && selector.isFetching && selector.isFetching === true && selector?.data?.content === undefined && <React.Fragment>
                <div id="progress" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            </React.Fragment>}
            {selector.isFetching===false&&selector && selector.data && selector.data.status === false && <React.Fragment>
                <div className="no_data_found_wrap">
                    <div className="no_data_found_img_wrap">
                        <img src={require('../../assets/img/no_data_found.png')} className="no_data_found_img" alt="no data found" />
                    </div>
                    <div className="no_data_found_text_wrap" style={{ paddingBottom: 16 }}>
                        <span>{"You don't have any about. "}</span>
                        <Button variant="contained" size="small" sx={{ textTransform: 'capitalize', ml: 1 }} onClick={() => { create() }}>Create One</Button>
                    </div>
                </div>
            </React.Fragment>}

            {
                modalOpen &&
                <React.Fragment>
                    <CustomDialog from={'about'} open={modalOpen} onClose={ModalClose} children={<Tiptap response={getResponse} onClose={ModalClose} from="about" type={type} content={selector?.data?.data?.content} />} />
                </React.Fragment>
            }
            {updateMsg && response.data.status && <AlertMsg autoHide="true" authHide='2000' type={response.data.status === true ? "success" : "error"} open={updateMsg} onClose={() => { setUpdateMsg(false) }} msgText={response.data.message} />}
            {emptyMsg && response.data.status && <AlertMsg autoHide="true" authHide='2000' type={response.data.status === true ? "success" : "error"} open={emptyMsg} onClose={() => { setEmptyMsg(false) }} msgText={response.data.message} />}
            {createMsg && response.data.status && <AlertMsg autoHide="true" authHide='2000' type={response.data.status === true ? "success" : "error"} open={createMsg} onClose={() => { setCreateMsg(false) }} msgText={response.data.message} />}
            {
                <CustomDialog open={processing} children={<div style={{ padding: 32, backgroundColor: 'white', borderRadius: 6 }}><CircularProgress /></div>} />
            }
        </React.Fragment >
    )
}