import { Route, Routes } from 'react-router-dom'
import {  ClinicHomePage, DashboardHomePage, DoctorHomePage, PaitentHomePage } from '../pages/home/NewHome';
import { ApointmentHomePage } from '../pages/appointment_home/AppointmentHome';
import { PatientDetailsView } from '../pages/patient_details_view/PatientDetailsView';
import { ClinicDetails } from '../pages/clinic_details/ClinicDetails';
import { DoctorDetails } from '../pages/doctor_details/DoctorDetails'
import { DoctorAppoinment } from '../pages/doctor_details/appoinment/DoctorAppoinment'
import { DoctorPayment } from '../pages/doctor_details/payment/DoctorPayment'
import { NewPayment } from '../pages/new_payment/NewPayment';
import { PatientAppoinment } from '../pages/patient_details_view/appoinment/PatientAppoinment';
import { PatientPayment } from '../pages/patient_details_view/payment/PatientPayment';
import { AddClinic } from '../pages/clinic/add/AddClinic';
import { ClinicEditProfile } from '../pages/clinic_details/clinic_edit_profile/ClinicEditProfile';
import { ClinicAppoinments } from '../pages/clinic_details/appoinment/ClinicAppoinments';
import { ClinicPayments } from '../pages/clinic_details/payment/ClinicPayments';
import { ClinicDoctor } from '../pages/clinic_details/doctor/ClinicDoctor';
import { Settings } from '../pages/settings/Settings';
import { AllAppoinment } from '../pages/appoinments/all_appointments/AllAppointment';
import { ClinicBankInfo } from '../pages/clinic_details/clinic_bank_info/ClinicBankInfo';
import { SingleAppointment } from '../pages/appoinments/single_appointment/SingleAppointment';
import { SinglePayment } from '../pages/new_payment/single_payment/SinglePayment';
import { PaymentsDetails } from '../pages/payments/PaymentsDetails';
import { Terms } from '../pages/terms_condition/Terms';
import { About } from '../pages/about/About';
import { Privacy_Policy } from '../pages/privacy-policy/PrivacyPolicy';
import { Specialization } from '../pages/specialization/Specialization';

export const HomeRouting = () => {
    return (
        <Routes>
            <Route path="/" element={<DashboardHomePage />} />

            <Route path="/doctor" element={<DoctorHomePage />} />
            <Route path="/doctor/details/:id" element={<DoctorDetails />} />
            <Route path="/doctor/appoinment/:id" element={<DoctorAppoinment />} />
            <Route path="/doctor/payment/:id" element={<DoctorPayment />} />
            
            <Route path="/clinic" element={<ClinicHomePage />} />
            <Route path="/clinic/create" element={<AddClinic />} />
            <Route path="/clinic/details/:id" element={<ClinicDetails />} />
            <Route path="/clinic/details/edit" element={<ClinicEditProfile />} />
            <Route path="/clinic/appoinment/:id" element={<ClinicAppoinments />} />
            <Route path="/clinic/payment/:id" element={<ClinicPayments />} />
            <Route path="/clinic/doctor/:id" element={<ClinicDoctor />} />
            <Route path="/clinic/bank_information/:id" element={<ClinicBankInfo/>}/>

            <Route path="/appointment" element={<ApointmentHomePage />} />
            <Route path="/appointment/details" element={<AllAppoinment />} />
            <Route path="/appointment/details/:id" element={<SingleAppointment />} />

            <Route path="/patients" element={<PaitentHomePage />} />
            <Route path="/patients/details/:id" element={<PatientDetailsView />} />
            <Route path="/patients/appoinment/:id" element={<PatientAppoinment />} />
            <Route path="/patients/payment/:id" element={<PatientPayment />} />

            <Route path="/clinic" element={<ClinicHomePage />} />
            <Route path="/clinic/details" element={<ClinicDetails />} />

            <Route path="/specialization" element={<Specialization/>}/>
            <Route path="/payment" element={<NewPayment />} />
            <Route path="/payment/details" element={<SinglePayment />} />
            <Route path="/payment/details/:id" element={<PaymentsDetails />} />
            <Route path="/terms_and_condition" element={<Terms/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/setting" element={<Settings />} />
            <Route path="/privacy-policy" element={<Privacy_Policy />} />
        </Routes>
    );
}
