import { call, put, takeLatest } from "redux-saga/effects";
import { termsActions } from "../../slice/terms/termsSlice";
import termsApi from "../../../api/terms/termsApi";
import { privacyActions } from "../../slice/privacy/privacySlice";
function* getOne(action) {
    try {
        const response = yield call(termsApi.getOne,action.payload);
        yield put(privacyActions.success(response));
    } catch (error) {
        yield put(privacyActions.failed(error.message));
    }
}
export default function* privacySaga() {
    yield takeLatest(privacyActions.getOne.type, getOne)
}