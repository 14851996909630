import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialog({ open, onClose, children,from }) {
    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose&&onClose}
                maxWidth={(from==='terms'||from==='about'||from==="privacy")?"lg":"sm"}
            >
                {children}
            </Dialog>
        </React.Fragment>
    );
}