export const urls = {
    LOGIN_URL: "/admin/login",
    REFRESH_LOGIN: "/admin/login/refresh",
    CLINIC_ADD: "/admin/clinic/create",
    CLINIC_GET_ALL: "/admin/clinic/get-all",
    CLINIC_SINGLE_GET: "/admin/clinic/get",
    CLINIC_BANK_GET: "/admin/clinic/bank/get",
    CLINIC_BANK_ADD: "/admin/clinic/bank/add",
    CLINIC_BANK_EDIT: "/admin/clinic/update-profile",
    CLINIC_EDIT: "/clinic/update-profile",
    CLINIC_PASSWORD_CHANGE: "/admin/clinic/change-password",
    CLINIC_STATUS_UPDATE: "/admin/clinic/update-status",
    ADMIN_PROFILE_EDIT: "/admin/update",
    CLINIC_ALL_APPOINMENTS: "/admin/clinic/appointment/get",
    CLINIC_ALL_PAYMENTS: "/admin/clinic/payment/get",
    GET_DOCTOR_BY_CLINIC_ID: "/admin/clinic/doctor/get-by-clinicId",
    GET_ALL_APPOINMENTS: "/admin/clinic/appointment/get-all",
    GET_SINGLE_APPOINMENTS: "/admin/clinic/appointment/get",
    GET_ALL_PAYMENTS: "/admin/clinic/payment/get-all",
    GET_ALL_PATIENT: "/admin/patient/get-all",
    GET_SINGLE_PATIENT: "/admin/patient/get",
    GET_ALL_APPOINMENTS_REF_PATIENT: "/admin/patient/appointment/get-by-patientId",
    GET_ALL_PAYMENTS_REF_PATIENT: "/admin/patient/payment/get-by-patientId",
    GET_ALL_DOCTOR: "/admin/doctor/get-all",
    GET_SINGLE_DOCTOR: "/admin/doctor/get",
    GET_ALL_APPOINMENTS_REF_DOCTOR: "/admin/doctor/appointment/get-by-doctorId",
    CLINIC_SEARCH: "/admin/clinic/search",
    CLINIC_APPOINMENTS_SEARCH: "/admin/clinic/appointment/search-by-clinicId",
    CLINIC_DOCTORS_SEARCH: "/admin/clinic/doctor/search-by-clinicId",
    CLINIC_PAYMENTS_SEARCH: "/admin/clinic/payment/search-by-clinicId",
    PATIENT_SEARCH: "/admin/patient/search",
    DOCTOR_SEARCH: "/admin/doctor/search",
    PATIENT_APPOINMENTS_SEARCH: "/admin/patient/appointment/search-by-patientId",
    PATIENT_PAYMENTS_SEARCH: "/admin/patient/payment/search-by-patientId",
    DOCTOR_APPOINMENTS_SEARCH: "/admin/doctor/appointment/search-by-doctorId",
    APPOINMENTS_SEARCH: "/admin/appointment/search",
    PAYMENTS_SEARCH: "/admin/payment/search",
    ABOUT_US_CREATE:"/admin/static/create",
    ABOUT_US_GET:"/admin/static/get",
    ABOUT_US_UPDATE:"/admin/static/update",
    ABOUT_US_DELETE:"/admin/static/delete",
    DASHBOARD_COUNT_ALL: "/admin/dashboard/count",
    DOCTOR_CERIFICATE_VERIFY: "/admin/doctor/verify-certificate",
    APPOINMENT_PERFORMANCE_DOCTOR: "/admin/doctor/appointment/performance",
    PAYMENT_PERFORMANCE_DOCTOR: "/admin/doctor/payment/performance",
    DOCTOR_APPOINMENT: "/admin/doctor/appointment/ref-by-doctorId",
    DOCTOR_APPOINMENT_COUNT: "/admin/doctor/appointment/count-ref-by-doctor",
    DOCTOR_PAYMENT: "/admin/doctor/payment/ref-by-doctorId",
    DOCTOR_PAYMENT_SEARCH: "/admin/doctor/payment/search-by-doctorId",

    APPOINMENT_PATIENT_PERFORMANCE: "/admin/patient/payment/performance-ref-patient",
    APPOINMENT_PATIENT_GET_ALL: "/admin/patient/appointment/get-by-patientId",
    APPOINMENT_PATIENT_GET_ALL_SEARCH: "/admin/patient/appointment/search-by-patientId",
    APPOINMENT_PATIENT_COUNT_ALL: "/admin/patient/appointment/count-ref-patient",

    PAYMENT_PATIENT_PERFORMANCE: "/admin/patient/appointment/peformance-ref-patient",
    PAYMENT_PATIENT_GET_ALL: "/admin/patient/payment/get-by-patientId",
    PAYMENT_PATIENT_SEARCH_ALL: "/admin/patient/payment/search-by-patientId",

    PATIENT_DEACTIVATE: "/admin/patient/user/deactive",
    PATIENT_DELETE: "/admin/patient/user/delete",

    SPECIALIZATION_CREATE:"/admin/specialization/add",
    SPECIALIZATION_GET_ALL:"/admin/specialization/get-all",
    SPECIALIZATION_UPDATE:"/admin/specialization/update",
    SPECIALIZATION_DELETE:"/admin/specialization/delete",

    CLINIC_DEACTIVATE:"/admin/clinic/user/deactive",
    CLINIC_DELETE:"/admin/clinic/user/delete",
    CLINIC_APPOINTMENT_PERFORMANCE:"/admin/clinic/appointment/performance-ref-clinic",
    CLINIC_APPOINTMENT_GET_ALL:"/admin/clinic/appointment/get",
    CLINIC_APPOINTMENT_GET_ALL_SEARCH:"/admin/clinic/appointment/search-by-clinicId",
    CLINIC_APPOINTMENT_COUNT:"/admin/clinic/appointment/count-ref-clinic",
    CLINIC_PAYMENT_GET_ALL:"/admin/clinic/payment/get",
    CLINIC_PAYMENT_GET_ALL_SEARCH:"/admin/clinic/payment/search-by-clinicId",
    CLINIC_PAYMENT_PERFORMANCE:"/admin/clinic/payment/performance-ref-clinic",

    APPOINTMENT_GET_ALL:"/admin/appointment/get-all",
    APPOINTMENT_GET_ALL_SEARCH:"/admin/appointment/search",
    APPOINTMENT_GET_SINGLE:"/admin/appointment/get-details",
    APPOINTMENT_PERFORMANCE:"/admin/appointment/performance-ref-admin",
    APPOINTMENT_COUNT:"/admin/appointment/count",
    APPOINTMENT_SINGLE_GET:"/admin/appointment/get-details",

    PAYMENT_GET_ALL:"/admin/payment/get-all",
    PAYMENT_GET_ALL_SEARCH:"/admin/payment/search",
    PAYMENT_PERFORMANCE:"/admin/payment/performance-ref-admin",
    PAYMENT_SINGLE_GET:"/admin/payment/get-details",

    ADMIN_UPDATE_EMAIL:"/admin/verify-email",
    ADMIN_VERIFY_EMAIL:"/admin/update-email",
    ADMIN_PASSWORD_UPDATE:"/admin/update"
}