import React from "react";
import './style.scss'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ToggleButton } from "@mui/material";


export const ChartToggleButton = (props) => {
    return (
        <React.Fragment>
            <ToggleButtonGroup color="success" value={props.value} className="toggle_button_group" size="small" style={{ boxShadow: "none" }}>
                {
                    data.map((elem, index) => {
                        return (
                            <ToggleButton
                                onClick={() => { props.onChange(elem.value) }}
                                key={index}
                                value={elem.value}
                                sx={{ textTransform: 'capitalize' }}
                                style={{ backgroundColor: props.value === elem.value && '#17BA86', color: props.value === elem.value && 'white', borderRadius: '4px', BoxShadow: 'none', fontSize: '14px', padding: '4px 10px' }}>
                                {elem.text}
                            </ToggleButton>
                        );
                    })
                }

            </ToggleButtonGroup>
        </React.Fragment>

    )
}

const data = [
    {
        'text': "Daily",
        "value": "today"
    },
    {
        'text': "Weekly",
        "value": "week"
    },
    {
        'text': "Monthly",
        "value": "month"
    },
    {
        'text': "Yearly",
        "value": "year"
    },
]