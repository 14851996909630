import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import './table.scss'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import BasicMenu from '../menu/DynamicMenu';
var _ = require('lodash');

function customCheckbox(theme) {
    return {
        '& .MuiCheckbox-root svg': {
            width: 16,
            height: 16,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
                }`,
            borderRadius: 2,
        },
        '& .MuiCheckbox-root svg path': {
            display: 'none',
        },
        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
            backgroundColor: '#1890ff',
            borderColor: '#1890ff',
        },
        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
            position: 'absolute',
            display: 'table',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-50%,-50%)',
            opacity: 1,
            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
            content: '""',
            top: '50%',
            left: '39%',
            width: 5.71428571,
            height: 9.14285714,
        },
        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
            width: 8,
            height: 8,
            backgroundColor: '#1890ff',
            transform: 'none',
            top: '39%',
            border: 0,
        },
    };
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
            }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
            }`,
    },
    '& .MuiDataGrid-cell': {
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
        borderRadius: 0,
    },
    ...customCheckbox(theme),
}));


export default function Table(props) {
    const [, setSelectedRows] = React.useState([])
    const navigation = useNavigate()
    const [pageSize, setPageSize] = React.useState(5)
    const [page, setPage] = React.useState(1)
    const [searchValue, ] = React.useState()
    const handleSearchChange = _.debounce(change => {
        props.onSearch(change)
    }, 1000);

    return (
        <React.Fragment>
            {
                props.tableAction &&
                <div className="table_actions_wrap">
                    <div className="action_left_wrap">
                    
                    </div>
                    <div className='action_right_wrap'>
                        {props.addUrl && <Button variant="contained" size="small" sx={{ textTransform: 'capitalize' }} onClick={() => { navigation(props.addUrl) }}>Add</Button>}
                    </div>
                </div>
            }
            <div className="search_bar">
                <TextField
                    id="filled-start-adornment"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <Search />
                        </InputAdornment>,
                    }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => { handleSearchChange(e.target.value) }}
                />
                <BasicMenu />
            </div>
            <div className="own_table_layout_wrap">
                <div className='own_table_layout' style={{ height: props.rows.length ? (props.rows.length * 52) + 109 : '369px' }}>
                    <StyledDataGrid
                        checkboxSelection={props.disableCheck ? false : true}
                        disableSelectionOnClick={props.disableCheck ? true : false}
                        pageSize={pageSize}
                        rowsPerPageOptions={[5, 10, 20]}
                        pagination
                        onPageChange={(p) => { props.onPageChange(p + 1, pageSize); setPage(p + 1) }}
                        onPageSizeChange={(ps) => { props.onPageSizeChange(page, ps); setPageSize(ps) }}
                        columns={props.columns}
                        rows={props.rows}
                        onSelectionModelChange={setSelectedRows}
                        rowCount={props.totalRows}
                        paginationMode="server"
                        disableColumnMenu
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
