import { call, put, takeLatest } from "redux-saga/effects";
import specializationUpdateApi from "../../../api/specialization/specializationUpdateApi";
import { specializationUpdateActions } from "../../slice/specialization/specializationUpdateSlice";

function* edit(action) {
    try {
        const response = yield call(specializationUpdateApi.edit, action.payload);
        yield put(specializationUpdateActions.success(response));
    } catch (error) {
        yield put(specializationUpdateActions.failed(error.message));
    }
}
export default function* specializationUpdateSaga() {
    yield takeLatest(specializationUpdateActions.edit.type, edit)
}