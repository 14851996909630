import { Route, Routes } from "react-router-dom";
import { Appoinments } from "../../pages/appoinments/Appoinments";
import { AppoinmentsDetails } from "../../pages/appoinments/AppoinmentsDetails";

export const AppoinmentRouting = () => {
    return (
        <Routes>
            <Route path={APPOINMENT_HOME} element={<Appoinments />} />
            <Route path={`${APPOINMENT_DETAILS}/:id`} element={<AppoinmentsDetails />} />
        </Routes>
    );
}

export const APPOINMENT_HOME = "/"
export const APPOINMENT_PUBLIC_HOME = "/appoinments"
export const APPOINMENT_DETAILS = "/details"
export const APPOINMENT_PUBLIC_DETAILS = `${APPOINMENT_PUBLIC_HOME}${APPOINMENT_DETAILS}`