import React from "react";
import './home.scss';
import { NewHome } from "./NewHome";



export const Home = () => {
    return (<NewHome  />);
}

