import { call, put, takeLatest } from "redux-saga/effects";
import specializationGetAllApi from "../../../api/specialization/specializationGetAllApi";
import { specializationGetAllActions } from "../../slice/specialization/specializationGetAllSlice";

function* getAll(action) {
    try {
        const response = yield call(specializationGetAllApi.getAll,action.payload);
        yield put(specializationGetAllActions.success(response));
    } catch (error) {
        yield put(specializationGetAllActions.failed(error.message));
    }
}
export default function* specializationGetAllSaga() {
    yield takeLatest(specializationGetAllActions.getAll.type,getAll);
}