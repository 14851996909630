import React from "react";
import CustomDialog from "../CustomDialog";
import { DialogTitle, DialogContentText, DialogContent, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { adminSelector } from "../../../redux/selector/admin/adminSelector";
import { adminActions } from "../../../redux/slice/admin/adminSlice";
import { authActions } from "../../../redux/slice/auth/authSlice";
import axiosClient from "../../../api/axiosClient";

const nameSchema = yup.object({
    name: yup.string().required("Please enter your full name"),
})

export const ChangeNameModal = ({ open, onClose }) => {
    const { handleSubmit, register, reset, formState: { errors } } = useForm({ resolver: yupResolver(nameSchema) })
    const dispatch = useDispatch()
    const selector = useSelector(adminSelector)
    React.useEffect(() => {
        dispatch(adminActions.reset())
        reset()
    }, [open, reset, dispatch])
    const onSubmit = (data) => {
        dispatch(adminActions.adminUpdatePassword(data))
    }
    React.useEffect(() => {
        if (selector && selector.data) {
            if (selector.data.status && selector.data.message) {
                onClose()
                dispatch(adminActions.reset())
                if (localStorage.getItem("refresh_token") && localStorage.getItem("access_token")) {
                    dispatch(authActions.refreshLogin({ "token": localStorage.getItem("refresh_token") }))
                    axiosClient.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` }
                }
            }
            else if (!selector.data.status && selector.data.message) {
                alert(selector.data.message)
                dispatch(adminActions.reset())
                onClose()
            }
        }
    }, [selector, dispatch, onClose])
    return (
        <React.Fragment>
            <CustomDialog open={open} onClose={onClose} children={
                <React.Fragment>
                    <DialogTitle style={{ color: 'black', paddingBottom: 10 }}>Update your name</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ marginBottom: 6 }}>Please fill correct information that are directly update on database.</DialogContentText>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <React.Fragment>
                                <TextField {...register("name")} fullWidth size="small" placeholder='Full Name' type='text' label="Full Name" />
                                {errors && errors["name"] &&
                                    <p style={{ margin: 0, color: 'red' }}>{errors["name"]?.message}</p>}
                            </React.Fragment>
                        </form>
                    </DialogContent>
                    <div className="dialog_footer">
                        <div className="dialog_footer_child_wrap">
                            <button className="b_d_f_close" onClick={onClose}>Close</button>
                            <button className='b_d_f_update' onClick={handleSubmit(onSubmit)}>Update</button>
                        </div>
                    </div>
                </React.Fragment>
            } />
        </React.Fragment>
    );
}