import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,

} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  plugins: {
    legend: {
      position: 'left top',
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  scaleShowLabels: false,
  scales: {
    x: {
      display: false,
      ticks: {
        display: false
      }
    }
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        console.log(tooltipItem)
        return `$ ${tooltipItem.yLabel}`;
      }
    }
  }
};

const labels = [86, 114, 106, 106, 107, 111, 133, 221, 783, 2478];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'Amount',
      data: [86, 114, 106, 106, 107, 111, 133, 221, 783, 2478],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ]
};

export function AreaChart(props) {
  const [prevData, setPrevData] = React.useState()
  React.useEffect(() => {
    if (props && props.data) {
      setPrevData(props.data)
    }
  }, [props])
  return (
    <Line options={options} data={props.data ? props.data : data} />
  )
}
