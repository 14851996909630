import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        getAllCount: (state) => {
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        failed(state, action) {
            state.message = action.payload
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    },
});

// Actions
export const dashboardActions = dashboardSlice.actions;

// Reducer
const dashboardReducer = dashboardSlice.reducer;


export { dashboardReducer };