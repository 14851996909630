import { Pagination } from "@mui/material";
import { CircularProgress } from '@mui/material'
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AreaChart } from "../../../components/area_chart/AreaChart";
import { BackButton } from "../../../components/buttons/Buttons";
import '../../doctor_details/appoinment/style.scss'
import { useDispatch, useSelector } from "react-redux";
import { ChartToggleButton } from "../../../components/toggle_button/ToggleButton";
import appoinmentSelector from "../../../redux/selector/appoinment/appoinmentSelector";
import { appoinmentActions } from "../../../redux/slice/appoinment/appoinmentSlice";
import { debounce } from "lodash";
import paymentSelector from "../../../redux/selector/payment/paymentSelector";
import { paymentActions } from "../../../redux/slice/payment/paymentSlice";

export const ClinicPayments = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const [toggleValue, setToggleValue] = React.useState('year')
    const appoinmentPerformanceSelector = useSelector(paymentSelector)
    const [appoinmentPerformanceData, setAppoinmentPerformanceData] = React.useState([])
    const [appoinmentPerformanceTableData, setAppoinmentPerformanceTableData] = React.useState([])
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [searchVal, setSearchVal] = React.useState('')
    const [searchApi, setSearchApi] = React.useState('')
    const navigate = useNavigate()
    const [countData, setCountData] = React.useState()
    React.useEffect(() => {
        if (params && params.id) {
            dispatch(paymentActions.clinicPaymentPerformance({ 'id': params.id, 'filter': toggleValue }))
        }
    }, [params, toggleValue])
    React.useEffect(() => {
        if (params && params.id) {
            if (searchApi) {
                dispatch(paymentActions.clinicPaymentGetAllSearch({ 'clinic_id': params.id, 'q': searchApi, 'page': page, 'limit': limit }))
            }
            else {
                dispatch(paymentActions.clinicPaymentGetAll({ 'clinic_id': params.id, 'page': page, 'limit': limit }))
            }
        }
    }, [params, page, limit, searchApi])
    React.useEffect(() => {
        if (params && params.id) {
            dispatch(paymentActions.clinicPaymentCount(params.id))
        }
    }, [params])
    React.useEffect(() => {
        if (appoinmentPerformanceSelector && appoinmentPerformanceSelector.data && appoinmentPerformanceSelector.data instanceof Array) {
            setAppoinmentPerformanceData(appoinmentPerformanceSelector)
        }
        else if (appoinmentPerformanceSelector.data.rows && appoinmentPerformanceSelector.data.rows instanceof Array) {
            setAppoinmentPerformanceTableData(appoinmentPerformanceSelector)
        }
        else if (appoinmentPerformanceSelector.data.data && appoinmentPerformanceSelector.data.data.total_rows === 0 && appoinmentPerformanceSelector.data.data.rows instanceof Array && appoinmentPerformanceSelector.data.data.rows.length === 0) {
            setAppoinmentPerformanceTableData([])
        }
        else if (appoinmentPerformanceSelector.data && typeof appoinmentPerformanceSelector.data.pending_app) {
            setCountData(appoinmentPerformanceSelector.data)
        }
    }, [appoinmentPerformanceSelector])
    const someFunction = (val) => {
        setSearchApi(val)
        if (val) {
            setPage(1)
        }
    }
    const handler = React.useCallback(debounce(someFunction, 500), []);
    const handleSearchChange = (val) => {
        setSearchVal(val);
        handler(val)
    }
    return (
        <React.Fragment>
            <div className='top_header'>
                <BackButton />
                <div className='left'>
                    <h1>Payments Performance</h1>
                </div>
            </div>
            <div className="doctor_appoinment">
                <div className="top">
                    <div className="top_left" >
                        <div className="card_shadow">
                            {
                                appoinmentPerformanceSelector.isFetching && !searchApi ?
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', margin: '48px 0' }}>
                                        <CircularProgress />
                                    </div> :
                                    <React.Fragment>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <ChartToggleButton value={toggleValue} onChange={(v) => { setToggleValue(v) }} />
                                        </div>
                                        {
                                            appoinmentPerformanceData.data && appoinmentPerformanceData.data.length > 0 ?
                                                <AreaChart key={appoinmentPerformanceData.data} data={
                                                    {
                                                        'labels': appoinmentPerformanceData.data.map(e => e._id),
                                                        'datasets': [
                                                            {
                                                                'fill': false,
                                                                'label': "Count",
                                                                'lineTension': 0.5,
                                                                'data': appoinmentPerformanceData.data.map(e => e.count),
                                                                borderColor: "#742774"
                                                            },
                                                            {
                                                                'fill': true,
                                                                'label': "Amount",
                                                                'lineTension': 0.5,
                                                                'data': appoinmentPerformanceData.data.map(e => e.fees),
                                                                'borderColor': 'rgb(53, 162, 235)',
                                                                'backgroundColor': 'rgba(53, 162, 235, 0.5)',
                                                            }
                                                        ]
                                                    }} /> :
                                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={require('../../../assets/img/no_data.png')} alt="not found" style={{ height: 320, maxWidth: '100%' }} />
                                                </div>
                                        }
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                    {countData ?
                        <div className="top_right">
                            <div className="card_shadow black_shade appointments_small_cards" style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${require('../../../assets/img/card_back/image2.jpg')})` }}>
                                <h2>{(countData.completed_app && countData.completed_app > 0) ? `${countData.completed_app} +` : countData.completed_app}</h2>
                                <p style={{ textAlign: 'center' }}>Completed Appoinements</p>
                            </div>
                            <div className="card_shadow white_shade appointments_small_cards" style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${require('../../../assets/img/card_back/image1.jpg')})` }}>
                                <h2>{(countData.pending_app && countData.pending_app > 0) ? `${countData.pending_app} +` : countData.pending_app}</h2>
                                <p style={{ textAlign: 'center' }}>Pending Appoinements</p>
                            </div>
                            <div className="card_shadow black_shade appointments_small_cards" style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${require('../../../assets/img/card_back/image4.jpeg')})` }}>
                                <h2><sup>₹</sup>{(countData.fees && countData.fees > 0) ? `${countData.fees} +` : countData.fees}</h2>
                                <p style={{ textAlign: 'center' }}>Payments Received</p>
                            </div>
                            <div className="card_shadow white_shade appointments_small_cards" style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${require('../../../assets/img/card_back/image3.jpg')})` }}>
                                <h2>{(countData.cancelled_app && countData.cancelled_app > 0) ? `- ${countData.cancelled_app} ` : countData.cancelled_app}</h2>
                                <p style={{ textAlign: 'center' }}>Cancelled Appoinements</p>
                            </div>
                        </div> : null}
                </div>
                {(appoinmentPerformanceTableData.data && appoinmentPerformanceTableData.data.rows && appoinmentPerformanceTableData.data.rows instanceof Array && appoinmentPerformanceTableData.data.rows.length > 0) || searchApi.length > 0 ?
                    <div className='top_header' style={{ marginTop: 22 }}>
                        <div className='left'>
                        </div>
                        <div className="right">
                            <div className='input_wrapper'>
                                <div className='input'>
                                    <input placeholder="Search patient, clinic" value={searchVal} onChange={(e) => { handleSearchChange(e.target.value) }} />
                                </div>
                                <div className='icon'>
                                    <img alt="search" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABZklEQVRIidXUsWpTcRTH8U9SpFgo1g6xU5d2qX0CoZPdtBJwELL5Ap19gWyBdip0yyh0VMgkDi4dzBLzBsUpCC1dbId4O/zP1YAh994YKP7gcuCec8/3/M8958//rlqB/xFeYAfrGOErPuP2X8Cr6OAnsinPCIeoz5N8E8NI1EMTT/AgfG8xCP8HrFStfBiVt2bE1dEOyJniVv9WJz6alXxSxxH/pkzwmlR5r2w1eIgLqWWFakU1zQoA/rRqa5pzcgqehj2vCMjjd4sAa2GvKgIuwz4uAozCblQE5PE/igD9sM8rAvalf9AvClyWTjFQfkMbuManstUcRjXtErFL0kiPsVcWUJfWP8ORNOfT1IjkeWsq3Ukr0vpn0hK18RLP8BonUlvGkTxDtyqkJq3/N3/fpL+knu9F0u68kFzbeCXdogdSeya1EEiRajgNyHtpABauyZO8y18ukpThI27wBd8XmPsedQcLnFSiSu7vvQAAAABJRU5ErkJggg=="></img>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                <div className="table_wrap">
                    {appoinmentPerformanceSelector.isFetching ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '140px', width: '100%', background: 'white', borderRadius: 6, margin: '48px 0' }}>
                            <CircularProgress />
                        </div> :
                        <div className="appoinment">
                            {appoinmentPerformanceTableData.data && appoinmentPerformanceTableData.data.rows && appoinmentPerformanceTableData.data.rows instanceof Array && appoinmentPerformanceTableData.data.rows.length > 0 ?
                                <div className="table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>
                                                    <div className="th_cont">
                                                        <span>Patient Name</span>
                                                    </div>
                                                </td>
                                                <td>Status</td>
                                                <td>Doctor Name</td>
                                                <td>Fees</td>
                                                <td>Date</td>
                                            </tr>
                                        </thead>
                                        <tbody id="table_body">
                                            {appoinmentPerformanceTableData.data && appoinmentPerformanceTableData.data.rows && appoinmentPerformanceTableData.data.rows instanceof Array && appoinmentPerformanceTableData.data.rows.length > 0 && appoinmentPerformanceTableData.data.rows.map((elem, index) => {
                                                return (
                                                    <tr onClick={() => { navigate(`/appointment/details/${elem.id}`) }} key={index}>
                                                        <td>
                                                            <div className="usr_ico_n_tex">
                                                                <div className="ico_wrp">
                                                                    <img src={require('../../../assets/icon/patient.png')} alt="user" />
                                                                </div>
                                                                <span className="title">{(elem.patient && elem.patient.name) ? elem.patient.name : null}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="status_flex">
                                                                <span className={elem.payment_status === "Success" ? "enabled status" : "disabled status"}></span>
                                                                <span className="title">{elem.payment_status ? elem.payment_status : null}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="usr_ico_n_tex">
                                                                <div className="ico_wrp">
                                                                    <img src={require('../../../assets/icon/doctor.png')} alt="user" />
                                                                </div>
                                                                <span className="title">{(elem.doctor && elem.doctor.name) ? elem.doctor.name : null}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="usr_ico_n_tex">
                                                                <div className="ico_wrp">
                                                                    <img src={require('../../../assets/icon/rupee.png')} alt="user" />
                                                                </div>
                                                                <span className="title">{elem.fees ? elem.fees : null}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {elem.date ?
                                                                <React.Fragment>
                                                                    {`${new Date(elem.date).toDateString()}, ${new Date(elem.date).toLocaleTimeString()}`}
                                                                </React.Fragment> : null}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div> :
                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={require('../../../assets/img/no_data.png')} alt="not found" style={{ height: 320, maxWidth: '100%' }} />
                                </div>
                            }
                        </div>}
                </div>
            </div>
            {(appoinmentPerformanceTableData && appoinmentPerformanceTableData.data && appoinmentPerformanceTableData.data.total_rows && appoinmentPerformanceTableData.data.rows && appoinmentPerformanceTableData.data.rows instanceof Array && appoinmentPerformanceTableData.data.rows.length > 0) ? <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
                <Pagination count={Math.ceil(appoinmentPerformanceTableData.data.total_rows / limit)} color='primary' page={page} onChange={(event, value) => { setPage(value) }} />
            </div> : null}
        </React.Fragment>
    );
}