import './appoinments.scss'
import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DoneIcon from '@mui/icons-material/Done';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Typography } from '@mui/material'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate, useParams } from 'react-router-dom';
import { CLINIC_PUBLIC_DETAILS } from '../../routing/clinic/ClinicRouting';
import { DOCTOR_PUBLIC_DETAILS } from '../../routing/doctor/DoctorRouting';
import { PATIENT_PUBLIC_DETAILS } from '../../routing/patient/PatientRouting';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { appoinmentActions } from '../../redux/slice/appoinment/appoinmentSlice';
import appoinmentSelector from '../../redux/selector/appoinment/appoinmentSelector';
import UpdateIcon from '@mui/icons-material/Update';
import { BASE_URL } from '../../config/Config';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: props.status ? <DoneIcon /> : <UpdateIcon />,
        2: props.status ? <DoneIcon /> : <UpdateIcon />,
        3: props.status ? <DoneIcon /> : <UpdateIcon />,
        4: props.status ? <DoneIcon /> : <UpdateIcon />
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export function CustomizedSteppers(props) {
    const [windowDimensions, setWindowDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth });
    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions({ height: window.innerHeight, width: window.innerWidth });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    const [activeStep, setActiveStep] = React.useState()
    React.useEffect(() => {
        if (props && props.steps && props.steps instanceof Array && props.steps.length > 0) {
            let filtered_arr = props.steps.filter((elem) => elem.status === true)
            setActiveStep(filtered_arr.length-1)
        }
    }, [props])
    return (
        <Stack sx={{ width: '100%' }}>
            <Stepper alternativeLabel={windowDimensions.width > 768 ? true : false} activeStep={activeStep} connector={<ColorlibConnector />} orientation={windowDimensions.width < 768 ? "vertical" : "horizontal"}>
                {props.steps && props.steps instanceof Array && props.steps.map((elem, index) => (
                    <Step key={index}>
                        <StepLabel StepIconComponent={(props) => { return (<ColorlibStepIcon {...props} status={elem?.status} />) }}>
                            <span className="title_label">{elem.item}</span><br />
                            <div style={{display:"flex",flexDirection:"column",gap:2}}>
                            {elem?.isCancel && <span className='content_label' style={{color:"red"}}>Cancelled</span>}
                            {elem.value && <span className='content_label'>{elem.value}</span>}
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
}

export const AppoinmentsDetails = () => {
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useAppDispatch()
    const selector = useAppSelector(appoinmentSelector)
    const [appoinData, setAppoinData] = React.useState()
    React.useEffect(() => {
        if (params && params.id) {
            dispatch(appoinmentActions.appointmentSingleGet(params.id))
        }
    }, [dispatch, params.id])
    React.useEffect(() => {
        if (selector && selector.data && typeof selector.data === 'object') {
            setAppoinData(selector.data)
            console.log(selector.data)
        }
    }, [selector])
    return (
        <React.Fragment>
            {(appoinData) &&
                <div className='appoin_wrap'>
                    {appoinData.req_date && <div className="appoin_progress">
                        <CustomizedSteppers steps={[
                            {
                                "item": "Booked",
                                "value": `${new Date(appoinData.req_date).getDate()}/${new Date(appoinData.req_date).getMonth()}/${new Date(appoinData.req_date).getFullYear()}`,
                                "status": appoinData.req_date ? true : false
                            },
                            {
                                "item": "Accepted",
                                "value": appoinData.booked_date ? `${new Date(appoinData.booked_date).getDate()}/${new Date(appoinData.booked_date).getMonth()}/${new Date(appoinData.booked_date).getFullYear()}` : "",
                                "status": appoinData.booked_date ? true : false
                            },
                            {
                                "item": `Payment ${appoinData.booked_date && appoinData.payment ? `(₹${appoinData.fees})` : ''}`,
                                "value": appoinData.booked_date && (appoinData.payment && (appoinData.payment.payment_status === "Success" && (`${new Date(appoinData.payment.date).getDate()}/${new Date(appoinData.payment.date).getMonth()}/${new Date(appoinData.payment.date).getFullYear()}`))),
                                "status":appoinData.payment &&  appoinData.payment?.clinic?.status === "Paid" ? true : false,
                            },
                            {
                                "item": "Completed",
                                "value": appoinData.payment && ((appoinData.payment.payment_status === "Success" && appoinData.appointment_status === "Completed") && `${new Date(appoinData.booked_date).getDate()}/${new Date(appoinData.booked_date).getMonth()}/${new Date(appoinData.booked_date).getFullYear()}`),
                                "status": appoinData.payment && ((appoinData.payment.payment_status === "Success" && appoinData.appointment_status === "Completed") && true)
                            },
                        ]} />
                    </div>
                    }
                    <div className="details_wrap">
                        {(appoinData.patient && typeof appoinData.patient === 'object') &&
                            <div className="customer_wrap">
                                <Card sx={{ maxWidth: '100%' }} onClick={() => { navigate(`${PATIENT_PUBLIC_DETAILS}/${appoinData.patient.id}`) }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="220"
                                            width="auto"
                                            image={"https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=740&t=st=1663584775~exp=1663585375~hmac=93cd7cf84cef841f9155b010c827808d8829d4f9b4b7948cd679d0b23edd8f23"}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Patient
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" component="div">
                                                <Grid alignItems="center" container spacing={1}>
                                                    <Grid item xs={2} md={2}>
                                                        <PersonIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.patient.name}</div>
                                                    </Grid>
                                                    <Grid item xs={2} md={2}>
                                                        <EmailIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.patient.email}</div>
                                                    </Grid>
                                                    <Grid item xs={2} md={2}>
                                                        <PhoneIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.patient.contact_no}</div>
                                                    </Grid>
                                                    <Grid item xs={2} md={2}>
                                                        <LocationOnIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.patient.address}</div>
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        }
                        {(appoinData.doctor && typeof appoinData.doctor === 'object') &&
                            <div className="customer_wrap">
                                <Card sx={{ maxWidth: '100%' }} onClick={() => { navigate(`${DOCTOR_PUBLIC_DETAILS}/${appoinData.doctor}`) }}>
                                    <CardActionArea>
                                        {appoinData.doctor.profile_pic && <CardMedia
                                            component="img"
                                            height="220"
                                            width="auto"
                                            image={`${BASE_URL}${appoinData.doctor.profile_pic.slice(2)}`}
                                            alt="green iguana"
                                        />}
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Doctor
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" component="div">
                                                <Grid alignItems="center" container spacing={1}>
                                                    <Grid item xs={2} md={2}>
                                                        <PersonIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.doctor.name}</div>
                                                    </Grid>
                                                    <Grid item xs={2} md={2}>
                                                        <EmailIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.doctor.email}</div>
                                                    </Grid>
                                                    <Grid item xs={2} md={2}>
                                                        <PhoneIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.doctor.contact_no}</div>
                                                    </Grid>
                                                    <Grid item xs={2} md={2}>
                                                        <LocationOnIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.doctor.address}</div>
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        }
                        {(appoinData.clinic && typeof appoinData.clinic === 'object') &&
                            <div className="customer_wrap">
                                <Card sx={{ maxWidth: '100%' }} onClick={() => { navigate(`${CLINIC_PUBLIC_DETAILS}/${appoinData.clinic.id}`) }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="220"
                                            width="auto"
                                            image={"https://img.freepik.com/free-photo/empty-modern-teethcare-stomatology-hospital-office-with-nobody-it-equipped-with-dental-intruments-ready-orthodontist-healthcare-treatment-tooth-radiography-images-display_482257-9418.jpg?w=740&t=st=1663584435~exp=1663585035~hmac=7ac991b3db5cda28f0d9adb26682afe35abc8a44c370d77f06c601a98af24fd7"}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Clinic
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" component="div">
                                                <Grid alignItems="center" container spacing={1}>
                                                    <Grid item xs={2} md={2}>
                                                        <PersonIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.clinic.name}</div>
                                                    </Grid>
                                                    <Grid item xs={2} md={2}>
                                                        <EmailIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.clinic.email}</div>
                                                    </Grid>
                                                    <Grid item xs={2} md={2}>
                                                        <PhoneIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.clinic.contact_no}</div>
                                                    </Grid>
                                                    <Grid item xs={2} md={2}>
                                                        <LocationOnIcon />
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <div>{appoinData.clinic.address}</div>
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        }
                    </div>
                </div>
            }
            {
                (appoinData && appoinData.status === false) &&
                <div className="no_data_found_wrap">
                    <div className="no_data_found_img_wrap">
                        <img src={require('../../assets/img/no_data_found.png')} className="no_data_found_img" alt="no data found" />
                    </div>
                    <div className="no_data_found_text_wrap">
                        <span>{"This appoinment does not exist. Try again..."}</span>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}