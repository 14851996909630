import React from 'react'
import { Button, Modal, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { bankSchema } from '../../clinic/ClinicsDetails';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BusinessIcon from '@mui/icons-material/Business';
import PinIcon from '@mui/icons-material/Pin';
import { ClinicBankDetailsInputs, ClinicDoctorColumns, ClinicAppoinmentsColumns, ClinicPaymentsColumns, ClinicAddInputs } from '../../../config/Config'
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import clinicSelector from '../../../redux/selector/clinic/clinicSelector';
import { clinicActions } from '../../../redux/slice/clinic/clinicSlice';
import { useForm } from "react-hook-form";
import { CustomModal } from '../../clinic/ClinicsDetails';
import { yupResolver } from '@hookform/resolvers/yup';
import { BackButton } from '../../../components/buttons/Buttons';

export const ClinicBankInfo = () => {
    const { handleSubmit, register, formState: { errors }, reset, setValue } = useForm({ resolver: yupResolver(bankSchema) });
    const dispatch = useAppDispatch()
    const selector = useAppSelector(clinicSelector)
    const params = useParams()
    React.useEffect(() => {
        if(params&&params.id){
            dispatch(clinicActions.getBankDetails(params.id))
        }
    }, [dispatch])
    const [addModal, setAddModal] = React.useState(false)
    const [editModal, setEditModal] = React.useState(false)
    const onSubmit = (data) => {
        data["user_id"] = params.id
        if (addModal) {
            dispatch(clinicActions.addBank(data))
        }
        else {
            let obj={...data}
            obj["updation_type"]="bank";
            obj['id']=data.user_id;
            delete obj.user_id;
            dispatch(clinicActions.editBank(obj))
        }
    }
    React.useEffect(() => {
        if (selector && selector.data && selector.data.status) {
            dispatch(clinicActions.getBankDetails(params.id))
            setAddModal(false)
            setEditModal(false)
        }
    }, [selector])
    const openEditModal = () => {
        setEditModal(true)
        setValue("bank_name", selector.data.bank_name)
        setValue("account_no", selector.data.account_no)
        setValue("ifsc_code", selector.data.ifsc_code)
        setValue("account_holder_name", selector.data.account_holder_name)
        setValue("branch_name", selector.data.branch_name)
    }
    return (
        <React.Fragment>
            <div className="top_header" >
                    <BackButton />
                   { selector && selector.data && typeof selector.data === 'object' && selector.data.account_no &&<Button variant="contained" size="small" onClick={openEditModal} sx={{ textTransform: 'capitalize',padding:'2px 16px !important',boxShadow:'none !important',backgroundColor:'#314c7c !important',color:'white !important',width:window.innerWidth<500&&'150px',alignSelf:'end'}}>Edit</Button>}
                </div>
            {(selector && selector.data && typeof selector.data === 'object' && selector.data.account_no) &&
                <React.Fragment>
                    <Box sx={{ bgcolor: 'background.paper',borderRadius: '6px', padding: "12px" }}>
                        <div className="doctor_profile_wrap">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <AccountBalanceIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Bank Name"
                                                secondary={selector.data.bank_name}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PinIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Account No"
                                                secondary={selector.data.account_no}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PinIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="IFSC Code"
                                                secondary={selector.data.ifsc_code}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <BusinessIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Branch Name"
                                                secondary={selector.data.branch_name}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Account Holder"
                                                secondary={selector.data.account_holder_name}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </React.Fragment>
            }
            <React.Fragment>
                {(selector && selector.data && typeof selector.data === 'object' && selector.data.status === false) &&
                    <div className="no_data_found_wrap">
                        <div className="no_data_found_img_wrap">
                            <img src={require('../../../assets/img/no_data_found.png')} className="no_data_found_img" alt="no data found" />
                        </div>
                        <div className="no_data_found_text_wrap" style={{paddingBottom:16}}>
                            <span>{"You don't have any account. "}</span>
                            <Button variant="contained" size="small" onClick={() => {
                                setAddModal(true)
                            }} sx={{ textTransform: 'capitalize', ml: 1 }}>Create One</Button>
                        </div>
                    </div>
                }
            </React.Fragment>
            {
                addModal &&
                <CustomModal open={addModal} onClose={() => { setAddModal(false) }} children={
                    <div className="add_wrap" style={{ paddingTop: 12 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1 className="form_header" style={{ marginBottom: 0 }}>Add a Bank Details</h1>
                            <span style={{ cursor: 'pointer' }} onClick={() => { setAddModal(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" style={{ height: 24, width: 24 }} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>
                        </div>
                        <form className="form_wrap" style={{ padding: 0 }} onSubmit={handleSubmit(onSubmit)}>
                            <div className="form_body">
                                {
                                    ClinicBankDetailsInputs.map((elem, index) => {
                                        return (
                                            <div className="form_body_item" key={index}>
                                                <TextField multiline={elem.multiline ? true : false} rows={elem.multiline ? elem.rows : 1} fullWidth={elem.fullWidth ? true : false} {...register(elem.name)} size={elem.size} label={elem.label} placeholder={elem.placeholder} variant={elem.variant} />
                                                {errors[elem.name] && <span className="error_text">{errors[elem.name].message}</span>}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </form>
                        <div className="submit_wrap">
                            <div className="submit_grid">
                                <Button variant="outlined" type="reset" onClick={() => { reset() }} size="small" sx={{ textTransform: 'capitalize', background: 'white' }}>Clear</Button>
                                <Button variant="contained" size="small" sx={{ textTransform: 'capitalize' }} onClick={handleSubmit(onSubmit)}>Submit</Button>
                            </div>
                        </div>
                    </div>
                } />
            }
            {
                editModal &&
                <CustomModal open={editModal} onClose={() => { setEditModal(false) }} children={
                    <div className="add_wrap" style={{ paddingTop: 12 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1 className="form_header" style={{ marginBottom: 0 }}>Edit a Bank Details</h1>
                            <span style={{ cursor: 'pointer' }} onClick={() => { setEditModal(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" style={{ height: 24, width: 24 }} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>
                        </div>
                        <form className="form_wrap" style={{ padding: 0 }} onSubmit={handleSubmit(onSubmit)}>
                            <div className="form_body">
                                {
                                    ClinicBankDetailsInputs.map((elem, index) => {
                                        return (
                                            <div className="form_body_item" key={index}>
                                                <TextField multiline={elem.multiline ? true : false} rows={elem.multiline ? elem.rows : 1} fullWidth={elem.fullWidth ? true : false} {...register(elem.name)} size={elem.size} label={elem.label} placeholder={elem.placeholder} variant={elem.variant} />
                                                {errors[elem.name] && <span className="error_text">{errors[elem.name].message}</span>}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </form>
                        <div className="submit_wrap">
                            <div className="submit_grid">
                                <Button variant="outlined" type="reset" onClick={() => { reset() }} size="small" sx={{ textTransform: 'capitalize', background: 'white' }}>Clear</Button>
                                <Button variant="contained" size="small" sx={{ textTransform: 'capitalize' }} onClick={handleSubmit(onSubmit)}>Submit</Button>
                            </div>
                        </div>
                    </div>
                } />
            }
        </React.Fragment>
    );
}