import React from "react";
import '../style.scss'
import { useNavigate } from "react-router-dom";
function useScrollTop() {
    const [scrollTop, setScrollTop] = React.useState(0);
    const onScroll = (event) => setScrollTop(event.target.scrollTop);
    return [scrollTop, { onScroll }];
  }
export const PrivacyPolicy = () => {
    const [scrollTop, scrollProps] = useScrollTop();
    const navigate=useNavigate()
    return (
        <React.Fragment>
             <div className="main">
                <div className="header" >
                    <h1 onClick={() => {navigate('/policies')  }}>DNA<span>LIVE</span></h1>
                </div>
                <div className="body" {...scrollProps} style={{ boxShadow: scrollTop > 0 ? "inset 0 8px 5px -1px #e9e9e9" : "none", transition: "box-shadow 0.3s"}}>
                     
                <div className="card_content">
                <div className="logo_container">
                    <img src={require("../../assets/logo.jpg")} />
                </div>
                <div className="t_c">
                    <div className="t_c_header">
                        <p>Privacy Policy</p>
                    </div>
                    <div className="t_c_main_content">
                        <p className="list">
                            We may collect data when you create or update your user account. We may check background in some cases. We may collect demographic information through surveys and/or from third parties. We may collect information that you submit when you contact The AppRaiser, provide ratings or compliments. We may collect your location information, usage information, transaction information, device information and such other information in relation to requested service.
                            <p>We use your data to continue functioning and improving our product and services, to provide applicable customer support, to improve relevant research and development, to communicate with you and for legal proceedings and requirements.
                            </p>
                            <p className="sub_list">Contact Us</p>
                            <p>Email-ID: <span ><a href={`mailto:${"mail.theappraiser@gmail.com"}`} style={{ color: 'blue' }}>mail.theappraiser@gmail.com</a></span></p>
                            <p>Contact Number: <span ><a href={`tel:${9330694739}`}>+91 9330694739</a></span></p>
                            <p>Our Office: <span >118, M.G. Road, Kolkata-104, West Bengal, India</span></p>
                            <p className="sub_list">Cancellation & Refund Policy</p>
                            <p>Booking Charge, once paid is final and non refundable. Regarding Clinic Charges, rule of the Third Party Provider is final.</p>
                            <p className="sub_list">Shipping & Delivery Policy</p>
                            <p>Shipping & Delivery is as expressed in Terms and Condition.</p>
                        </p>
                    </div>
                </div>
            </div>
                   
                </div>
                <div className="footer" >
                    <ul>
                        <li onClick={() => {navigate('/policies/terms-condition') }}>Terms and Condition</li>
                        <li style={{ color: 'black' }}>|</li>
                        <li onClick={() => {navigate('/policies/privacy-policy') }}>Privacy Policy</li>
                    </ul>
                </div>
            </div>
            
        </React.Fragment>
    )
}