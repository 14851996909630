import { urls } from '../urls'
import axiosClient from '../axiosClient';

const adminApi = {
    edit(params) {
        return axiosClient.put(`${urls.ADMIN_PROFILE_EDIT}`, params);
    },
    patientDelete(params) {
        return axiosClient.post(`${urls.PATIENT_DELETE}`, params);
    },
    patientDeactivate(params) {
        return axiosClient.post(`${urls.PATIENT_DEACTIVATE}`, params);
    },
    clinicDelete(params) {
        return axiosClient.post(`${urls.CLINIC_DELETE}`, params)
    },
    clinicDeactivate(params) {
        return axiosClient.post(urls.CLINIC_DEACTIVATE, params)
    },
    updateEmail(params) {
        return axiosClient.post(urls.ADMIN_UPDATE_EMAIL, params)
    },
    verifyEmail(params){
        return axiosClient.put(urls.ADMIN_VERIFY_EMAIL,params)
    },
    adminPasswordUpdate(params){
        return axiosClient.put(urls.ADMIN_PASSWORD_UPDATE,params)
    }
};

export default adminApi;