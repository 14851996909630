import { call, put, takeLatest } from "redux-saga/effects";
import privacyUpdateApi from "../../../api/privacy/privacyUpdateApi";
import { privacyUpdateActions } from "../../slice/privacy/privacyUpdateSlice";
function* edit(action) {
    try {
        const response = yield call(privacyUpdateApi.edit, action.payload);
        yield put(privacyUpdateActions.success(response));
    } catch (error) {
        yield put(privacyUpdateActions.failed(error.message));
    }
}
export default function* privacyUpdateSaga() {
    yield takeLatest(privacyUpdateActions.edit.type, edit)
}