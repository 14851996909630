import React from 'react'
import { CircularProgress, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate} from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import clinicSelector from '../../../redux/selector/clinic/clinicSelector';
import { clinicActions } from '../../../redux/slice/clinic/clinicSlice';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { BackButton } from '../../../components/buttons/Buttons';
import { Home} from '@mui/icons-material';
import CustomDialog from '../../settings/CustomDialog';
import { DetailsEdit } from './Details_Edit_Modal';

export const ClinicEditProfile = (props) => {
    
    const dispatch = useAppDispatch()
    const selector = useAppSelector(clinicSelector)
    const [modalFor,setModalFor]=React.useState('')
    const[clinicId,setClinicId]=React.useState()
    const [nameModal, setNameModal] = React.useState()

    React.useEffect(() => {
        if (selector && selector.data && selector.data.active_status) {
            if (selector.data.id) {
                dispatch(clinicActions.getOne(selector.data.id))
                setClinicId(selector.data.id)
            }
        }
    }, [])
    const [windowDimensions, setWindowDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth });
     React.useEffect(() => {
         function handleResize() {
             setWindowDimensions({ height: window.innerHeight, width: window.innerWidth });
         }

         window.addEventListener('resize', handleResize);
         return () => window.removeEventListener('resize', handleResize);
     });
     React.useEffect(()=>{},[nameModal])
    return (
        <React.Fragment>
        <div className="settings_wrap">
        <div className="header" >
                    <BackButton />
                </div>
            <h1>Edit Account</h1>
            <div className="settings_main">
                {selector && selector.data && selector.data.name && <div className="single_item">
                    <div className="left_icon">
                        <PersonIcon />
                        <div className='primary_text'>
                            <span className='user_title'>Name</span>
                            <span className="user_content">{selector.data.name}</span>
                        </div>
                    </div>
                    <div className='right_action'>
                        <button onClick={() => { setNameModal(true);setModalFor('name') }}>
                            <EditIcon />
                        </button>
                    </div>
                </div>}
                {/* {selector && selector.data && selector.data.email && <div className="single_item">
                    <div className="left_icon">
                        <EmailIcon />
                        <div className='primary_text'>
                            <span className='user_title'>Email</span>
                            <span className="user_content">{selector.data.email}</span>
                        </div>
                    </div>
                    <div className='right_action'>
                        <button onClick={() => {setToggleModal("email"); setOtpModal(); reset(); setEmailUpdatedData(); }}>
                            <EditIcon />
                        </button>
                    </div>
                </div>} */}
                {selector && selector.data && selector.data.reg_no && <div className="single_item">
                    <div className="left_icon">
                        <AppRegistrationIcon />
                        <div className='primary_text'>
                            <span className='user_title'>Registration number</span>
                            <span className="user_content">{selector.data.reg_no}</span>
                        </div>
                    </div>
                    <div className='right_action'>
                        <button onClick={() => { setNameModal(true);setModalFor('registration no')}}>
                            <EditIcon />
                        </button>
                    </div>
                </div>}
                {selector && selector.data && selector.data.lat&&selector.data.long && <div className="single_item">
                    <div className="left_icon">
                        <LocationOnIcon />
                        <div className='primary_text'>
                            <span className='user_title'>Location</span>
                            <span className="user_content">lat:{selector.data.lat}  long:{selector.data.long}</span>
                        </div>
                    </div>
                    <div className='right_action'>
                        <button onClick={() => {setNameModal(true);setModalFor("location")}}>
                            <EditIcon />
                        </button>
                    </div>
                </div>}
                {selector && selector.data && selector.data.address && <div className="single_item">
                    <div className="left_icon">
                        <Home />
                        <div className='primary_text'>
                            <span className='user_title'>Address</span>
                            <span className="user_content">{selector.data.address}</span>
                        </div>
                    </div>
                    <div className='right_action'>
                        <button onClick={() => { setNameModal(true);setModalFor('address')}}>
                            <EditIcon />
                        </button>
                    </div>
                </div>}
            </div>
        </div>
        {nameModal && <DetailsEdit modalFor={modalFor} open={nameModal} lat={selector.data&&selector.data.lat&&selector.data.lat} lng={selector.data&&selector.data.long&&selector.data.long} id={clinicId} onClose={()=>{setNameModal(false) }} />}
        
    </React.Fragment>
    );
}