import { urls } from '../urls'
import axiosClient from '../axiosClient';

const clinicApi = {
    add(params) {
        return axiosClient.post(`${urls.CLINIC_ADD}`, params)
    },
    edit(params) {
        return axiosClient.put(urls.CLINIC_EDIT, params)
    },
    getAll(params) {
        return axiosClient.get(`${urls.CLINIC_GET_ALL}`, { params })
    },
    getOne(params) {
        return axiosClient.get(`${urls.CLINIC_SINGLE_GET}/${params}`)
    },
    getBank(params) {
        return axiosClient.get(`${urls.CLINIC_BANK_GET}/${params}`)
    },
    addBank(params) {
        return axiosClient.post(urls.CLINIC_BANK_ADD, params)
    },
    editBank(params) {
        return axiosClient.put(urls.CLINIC_BANK_EDIT, params)
    },
    changePassword(params) {
        return axiosClient.put(urls.CLINIC_PASSWORD_CHANGE, params)
    },
    getDoctor(params) {
        return axiosClient.get(`${urls.GET_DOCTOR_BY_CLINIC_ID}`, { params })
    },
    getAllAppoinments(params) {
        return axiosClient.get(`${urls.CLINIC_ALL_APPOINMENTS}`, { params })
    },
    getAllPayments(params) {
        return axiosClient.get(`${urls.CLINIC_ALL_PAYMENTS}`, { params })
    },
    searchClinic(params) {
        return axiosClient.get(`${urls.CLINIC_SEARCH}`, { params })
    },
    searchAppoinments(params) {
        return axiosClient.get(`${urls.CLINIC_APPOINMENTS_SEARCH}`, { params })
    },
    searchDoctors(params) {
        return axiosClient.get(`${urls.CLINIC_DOCTORS_SEARCH}`, { params })
    },
    searchPayments(params) {
        return axiosClient.get(`${urls.CLINIC_PAYMENTS_SEARCH}`, { params })
    },
    updateStatus(params){
        return axiosClient.put(`${urls.CLINIC_STATUS_UPDATE}`,params)
    }
};

export default clinicApi;