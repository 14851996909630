import { Button, TextField, InputAdornment, IconButton } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom'
import './login.scss'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { authActions } from '../../redux/slice/auth/authSlice'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useYupValidationResolver } from '../../components/yup/YupResolver'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AlertMsg from '../../components/alert/AlertMsg'
import authSelector from '../../redux/selector/auth/authSelector'
import axiosClient from '../../api/axiosClient';

const validationSchema = yup.object({
    username: yup.string().required("Please enter your email").email("Please enter a valid email"),
    password: yup.string().required("Please enter your password")
});
export const Login = () => {
    const resolver = useYupValidationResolver(validationSchema);
    const { handleSubmit, register } = useForm({ resolver });
    const [pswToggle, setPswToggle] = React.useState(false)
    const [message, setMessage] = React.useState(false)
    const dispatch = useAppDispatch()
    const selector = useAppSelector(authSelector)
    const onSubmit = (data) => {
        dispatch(authActions.login(data))
    }
    React.useEffect(() => {
        if (selector && selector.data && selector.data.status === false && selector.data.message && selector.data.message !== "Invalid user!") {
            setMessage(true)
            // if (selector && selector.data && selector.data.status && selector.data.access_token !== null && selector.data.access_token !== undefined) {
            //     localStorage.setItem("refresh_token", selector.data.refresh_token)
            //     localStorage.setItem("access_token", selector.data.access_token)
                
            // } 
            // if (selector && selector.data && selector.data.status && selector.data.access_token !== null && selector.data.access_token !== undefined) {
            //     axiosClient.defaults.headers.common = { 'Authorization': `Bearer ${selector.data.access_token}` }
            // }
        }
        else {
            setMessage(false)
        }
    }, [selector])
    return (
        <React.Fragment>

            <div className='login_main_wrap' style={{ backgroundImage: `url(${require('../../assets/img/login_back/login_bg.jpg')})` }}>
                <div className='login_top'>
                    <h1>DNA<span>LIVE</span></h1>
                </div>
                <div className='center_wrap'>
                    <div className='center_left_section'>
                        <img src={require('../../assets/img/login_png/left_img.png')} alt="img" />
                    </div>
                    <div className='center_right_wrap'>
                        <div className='text_wrap'>
                            <h1>Login <span>Here</span></h1>
                            <div className='input_wrap'>
                                <TextField {...register("username")} sx={{ width: '80%', mb: 2 }} size="small" placeholder='Email' type="text" label="Email" />
                                <TextField {...register("password")} sx={{ width: '80%' }} size="small" placeholder='Password' type={pswToggle ? "text" : "password"} label="Password"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={() => { setPswToggle(!pswToggle) }}
                                                onMouseDown={(e) => { e.preventDefault() }}
                                            >
                                                {pswToggle ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />

                            </div>
                            <div className='sign_in_button'>
                                <Button onClick={handleSubmit(onSubmit)} variant="contained" sx={{ textTransform: 'capitalize' }}>Sign In</Button>
                            </div>
                            <div className='forgot_password_button'>
                                <p>Forgot Password? <Link to="/forgot_password" style={{ color: "blue" }}>Click Here</Link> </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {message && <AlertMsg open={message} onClose={() => { setMessage(false) }} type={"error"} msgText={selector.data.message} />}
        </React.Fragment>
    );
}