import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from '../redux/slice/auth/authSlice'
import { clinicReducer } from '../redux/slice/clinic/clinicSlice';
import { adminReducer } from '../redux/slice/admin/adminSlice'
import { appoinmentReducer } from '../redux/slice/appoinment/appoinmentSlice'
import { paymentReducer } from '../redux/slice/payment/paymentSlice'
import { patientReducer } from '../redux/slice/patient/patientSlice'
import { doctorReducer } from '../redux/slice/doctor/doctorSlice'
import {dashboardReducer} from '../redux/slice/dashboard/dashboardSlice'
import { doctorUpdateReducer } from '../redux/slice/doctor/doctorUpdateSlice';
import { appoinmentTableReducer } from '../redux/slice/appoinment/appoinmentTableSlice';
import { aboutReducer } from '../redux/slice/about/aboutSlice';
import { termsReducer } from '../redux/slice/terms/termsSlice';
import { aboutUpdateReducer } from '../redux/slice/about/aboutUpdateSlice';
import { aboutAddReducer } from '../redux/slice/about/aboutAddSlice';
import { termsAddReducer } from '../redux/slice/terms/termsAddSlice';
import { termsUpdateReducer } from '../redux/slice/terms/termsUpdateSlice';
import { aboutDeleteReducer } from '../redux/slice/about/aboutDeleteSlice';
import { termsDeleteReducer } from '../redux/slice/terms/termsDeleteSlice';
import { privacyReducer } from '../redux/slice/privacy/privacySlice';
import { privacyAddReducer } from '../redux/slice/privacy/privacyAddSlice';
import { privacyUpdateReducer } from '../redux/slice/privacy/privacyUpdateSlice';
import { privacyDeleteReducer } from '../redux/slice/privacy/privacyDelete';
import { specializationAddReducer } from '../redux/slice/specialization/specializationCreateSlice';
import { specializationGetAllReducer } from '../redux/slice/specialization/specializationGetAllSlice';
import { specializationDeleteReducer } from '../redux/slice/specialization/specializationDeleteSlice';
import { specializationUpdateReducer } from '../redux/slice/specialization/specializationUpdateSlice';
const rootReducer = combineReducers({
    auth: authReducer,
    clinic: clinicReducer,
    admin: adminReducer,
    appoinment: appoinmentReducer,
    payment: paymentReducer,
    patient: patientReducer,
    doctor: doctorReducer,
    dashboard:dashboardReducer,
    doctor_update:doctorUpdateReducer,
    appoinment_table:appoinmentTableReducer,
    about:aboutReducer,
    terms:termsReducer,
    about_update:aboutUpdateReducer,
    about_add:aboutAddReducer,
    terms_add:termsAddReducer,
    terms_edit:termsUpdateReducer,
    about_delete:aboutDeleteReducer,
    terms_delete:termsDeleteReducer,
    privacy:privacyReducer,
    privacy_add:privacyAddReducer,
    privacy_update:privacyUpdateReducer,
    privacy_delete:privacyDeleteReducer,
    specializationAdd:specializationAddReducer,
    specializationGetAll:specializationGetAllReducer,
    specializationDelete:specializationDeleteReducer,
    specializationUpdate:specializationUpdateReducer
})

export default rootReducer;