import { Link } from "react-router-dom"
import { CLINIC_PUBLIC_DETAILS, CLINIC_PUBLIC_HOME } from "../routing/clinic/ClinicRouting"
import { DOCTOR_PUBLIC_DETAILS, DOCTOR_PUBLIC_HOME } from "../routing/doctor/DoctorRouting"
import { PATIENT_PUBLIC_DETAILS, PATIENT_PUBLIC_HOME } from "../routing/patient/PatientRouting"
import { APPOINMENT_PUBLIC_HOME, APPOINMENT_PUBLIC_DETAILS } from '../routing/appoinments/AppoinmentRouting'
import { PAYMENT_PUBLIC_DETAILS, PAYMENT_PUBLIC_HOME } from '../routing/payments/PaymentRouting'
import { Button } from "@mui/material"
import { ReGeneratePassword, UpdateStatus } from "../components/buttons/Buttons"
import React from 'react'

export const SidebarItems = [
    {
        'item': 'Home',
        'icon': function () {
            return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z" clipRule="evenodd" />
            </svg>)
        },
        'link': '/'
    },
    {
        'item': 'Doctors',
        'icon': function () {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                </svg>

            )
        },
        'link': DOCTOR_PUBLIC_HOME
    },
    {
        'item': 'Patients',
        'icon': function () {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                </svg>

            )
        },
        'link': PATIENT_PUBLIC_HOME
    },
    {
        'item': 'Clinics',
        'icon': function () {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
                </svg>
            )
        },
        'link': CLINIC_PUBLIC_HOME
    },
    // {
    //     'item': 'Categories',
    //     'subMenus': [
    //         {
    //             'item': 'Add',
    //             'icon': function () {
    //                 return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
    //                     <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" clipRule="evenodd" />
    //                 </svg>
    //                 )
    //             },
    //             'link': '/categories/add'
    //         },
    //         {
    //             'item': 'Edit',
    //             'icon': function () {
    //                 return (
    //                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
    //                         <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
    //                         <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
    //                     </svg>
    //                 )
    //             },
    //             'link': '/categories/edit'
    //         },
    //         {
    //             'item': 'Remove',
    //             'icon': function () {
    //                 return (
    //                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
    //                         <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
    //                     </svg>

    //                 )
    //             },
    //             'link': '/categories/remove'
    //         }
    //     ]
    // },
    {
        'item': 'Appoinments',
        'icon': function () {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path d="M2 4.5A2.5 2.5 0 014.5 2h11a2.5 2.5 0 010 5h-11A2.5 2.5 0 012 4.5zM2.75 9.083a.75.75 0 000 1.5h14.5a.75.75 0 000-1.5H2.75zM2.75 12.663a.75.75 0 000 1.5h14.5a.75.75 0 000-1.5H2.75zM2.75 16.25a.75.75 0 000 1.5h14.5a.75.75 0 100-1.5H2.75z" />
                </svg>
            )
        },
        'link': APPOINMENT_PUBLIC_HOME
    },
    {
        'item': 'Payments',
        'icon': function () {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path fillRule="evenodd" d="M1 4a1 1 0 011-1h16a1 1 0 011 1v8a1 1 0 01-1 1H2a1 1 0 01-1-1V4zm12 4a3 3 0 11-6 0 3 3 0 016 0zM4 9a1 1 0 100-2 1 1 0 000 2zm13-1a1 1 0 11-2 0 1 1 0 012 0zM1.75 14.5a.75.75 0 000 1.5c4.417 0 8.693.603 12.749 1.73 1.111.309 2.251-.512 2.251-1.696v-.784a.75.75 0 00-1.5 0v.784a.272.272 0 01-.35.25A49.043 49.043 0 001.75 14.5z" clipRule="evenodd" />
                </svg>
            )
        },
        'link': PAYMENT_PUBLIC_HOME
    },
    {
        'item': 'Settings',
        'icon': function () {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
            )
        },
        'link': '/settings'
    },
]
export const LatestAppoinmentsColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'patient_name',
        headerName: 'Patient Name',
        width: 150,
        flex: 1
    },
    {
        field: 'doctor_name',
        headerName: 'Doctor Name',
        width: 150,
        flex: 1
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 150,
        flex: 1
    },
    {
        field: 'price',
        headerName: 'Price',
        width: 150,
        flex: 1
    },
];
export const LatestAppoinmentsRows = [
    { id: 1, patient_name: 'Alex Johnson', doctor_name: 'Dr. Arun Kumar', date: "05/09/2022", price: "₹350" },
    { id: 2, patient_name: 'Alex Johnson', doctor_name: 'Dr. Arun Kumar', date: "05/09/2022", price: "₹350" },
    { id: 3, patient_name: 'Alex Johnson', doctor_name: 'Dr. Arun Kumar', date: "05/09/2022", price: "₹350" },
    { id: 4, patient_name: 'Alex Johnson', doctor_name: 'Dr. Arun Kumar', date: "05/09/2022", price: "₹350" },
    { id: 5, patient_name: 'Alex Johnson', doctor_name: 'Dr. Arun Kumar', date: "05/09/2022", price: "₹350" },
];
export const DoctorColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'name',
        headerName: 'Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            <Link to={`${DOCTOR_PUBLIC_DETAILS}/${params.row.id}`} className="table_title_cell_link">{params.row.name}</Link>
        )
    },
    {
        field: 'clinic',
        headerName: 'Clinic',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            <div>
                {params.row.clinic && <Link to={`${CLINIC_PUBLIC_DETAILS}/${params.row.clinic.id}`} className="table_title_cell_link">{params.row.clinic.name}</Link>}
            </div>
        )
    },
    {
        field: 'specializations',
        headerName: 'Specializations',
        width: 150,
        flex: 1
    },
    {
        field: 'qualification',
        headerName: 'Qualification',
        width: 150,
        flex: 1
    },
    {
        field: 'mci_reg_no',
        headerName: 'MCI Reg No',
        width: 150,
        flex: 1
    },
    {
        field: 'contact_no',
        headerName: 'Contact No',
        width: 150,
        flex: 1
    },
    {
        field: 'profile_image',
        headerName: 'Profile Picture',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            <div className="justify_center">
                {params.row.profile_image && <img src={`${BASE_URL}${params.row.profile_image.slice(2)}`} style={{ width: 64 }} alt={`${params.row.name} profile`} />}
            </div>
        )
    },
];

export const ClinicDoctorColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'name',
        headerName: 'Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            <Link to={`${DOCTOR_PUBLIC_DETAILS}/${params.row.id}`} className="table_title_cell_link">{params.row.name}</Link>
        )
    },
    {
        field: 'specializations',
        headerName: 'Specializations',
        width: 150,
        flex: 1
    },
    {
        field: 'qualification',
        headerName: 'Qualification',
        width: 150,
        flex: 1
    },
    {
        field: 'mci_reg_no',
        headerName: 'MCI Reg No',
        width: 150,
        flex: 1
    },
    {
        field: 'contact_no',
        headerName: 'Contact No',
        width: 150,
        flex: 1
    },
    {
        field: 'profile_image',
        headerName: 'Profile Picture',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            <div className="justify_center">
                {params.row.profile_image && <img src={`${BASE_URL}${params.row.profile_image.slice(2)}`} style={{ width: 64 }} alt={`${params.row.name} profile`} />}
            </div>
        )
    },
];

export const PatientColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'name',
        headerName: 'Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            <Link to={`${PATIENT_PUBLIC_DETAILS}/${params.row.id}`} className="table_title_cell_link">{params.row.name}</Link>
        )
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 150,
        flex: 1,
    },
    {
        field: 'contact_no',
        headerName: 'Contact No',
        width: 150,
        flex: 1,
    },
    {
        field: 'appointment',
        headerName: 'Booked Appoinment',
        width: 150,
        flex: 1,
    },
    {
        field: 'payment',
        headerName: 'Total Payment',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            <span><strong>₹</strong>{params.row.payment}</span>
        )
    },
    {
        field: 'profile_pic',
        headerName: 'Profile Picture',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            <div className="justify_center">
                {params.row.profile_pic && <img src={`${BASE_URL}${params.row.profile_pic.slice(2)}`} style={{ width: 64 }} alt="profile" />}
            </div>
        )
    },
]

export const PatientAppoinmentsColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'appointment_status',
        headerName: 'Status',
        width: 180,
        flex: 1,
        renderCell: (params) => (
            <div className="justify_center">
                <span className={
                    `status ${params.row.status === "Completed" || "completed" ? "completed"
                        : params.row.status === "Pending" || "pending" ? "pending"
                            : params.row.status === "Cancelled" || "cancelled" ? "cancelled" : ""}`
                }>{params.row.appointment_status}</span>
            </div>
        )
    },
    {
        field: 'booked_date',
        headerName: 'Booked Date',
        width: 120,
        flex: 1,
        renderCell: (params) => (
            <React.Fragment>
                {params.row.booked_date && <span>{`${new Date(params.row.booked_date).getDate()}/${new Date(params.row.booked_date).getMonth()}/${new Date(params.row.booked_date).getFullYear()}`}</span>}
            </React.Fragment>
        )
    },
    {
        field: 'clinic',
        headerName: 'Clinic Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.clinic && <Link to={`${CLINIC_PUBLIC_DETAILS}/${params.row.clinic.id}`} className="table_title_cell_link">{params.row.clinic.name}</Link>
        )
    },
    {
        field: 'doctor',
        headerName: 'Doctor Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.doctor && <Link to={`${DOCTOR_PUBLIC_DETAILS}/${params.row.doctor.id}`} className="table_title_cell_link">{params.row.doctor.name}</Link>
        )
    },
    // {
    //     field: 'clinic',
    //     headerName: 'Clinic Name',
    //     width: 220,
    //     flex: 1,
    //     renderCell: (params) => (
    //         params.row.clinic && <Link to={`${DOCTOR_PUBLIC_DETAILS}/${params.row.clinic.id}`} className="table_title_cell_link">{params.row.clinic.name}</Link>
    //     )
    // },
    {
        field: 'fees',
        headerName: 'Price',
        width: 100,
        renderCell: (params) => (
            <span><strong>₹</strong>{params.row.fees}</span>
        )
    },
    {
        field: 'appointment_id',
        headerName: 'Appoinments Details',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.id &&
            <div className="justify_center">
                <Link to={`${APPOINMENT_PUBLIC_DETAILS}/${params.row.id}`} className="table_title_cell_link">
                    <Button variant="contained" size="small" style={{ textTransform: 'capitalize' }}>Details</Button>
                </Link>
            </div>
        )
    },
]
export const ClinicAppoinmentsColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'appointment_status',
        headerName: 'Status',
        width: 180,
        flex: 1,
        renderCell: (params) => (
            <div className="justify_center">
                <span className={
                    `status ${params.row.status === "Completed" || "completed" ? "completed"
                        : params.row.status === "Pending" || "pending" ? "pending"
                            : params.row.status === "Cancelled" || "cancelled" ? "cancelled" : ""}`
                }>{params.row.appointment_status}</span>
            </div>
        )
    },
    {
        field: 'booked_date',
        headerName: 'Booked Date',
        width: 120,
        flex: 1,
        renderCell: (params) => (
            <React.Fragment>
                {params.row.booked_date && <span>{`${new Date(params.row.booked_date).getDate()}/${new Date(params.row.booked_date).getMonth()}/${new Date(params.row.booked_date).getFullYear()}`}</span>}
            </React.Fragment>
        )
    },
    {
        field: 'patient',
        headerName: 'Patient Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.patient && <Link to={`${PATIENT_PUBLIC_DETAILS}/${params.row.patient.id}`} className="table_title_cell_link">{params.row.patient.name}</Link>
        )
    },
    {
        field: 'doctor',
        headerName: 'Doctor Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.doctor && <Link to={`${DOCTOR_PUBLIC_DETAILS}/${params.row.doctor.id}`} className="table_title_cell_link">{params.row.doctor.name}</Link>
        )
    },
    // {
    //     field: 'clinic',
    //     headerName: 'Clinic Name',
    //     width: 220,
    //     flex: 1,
    //     renderCell: (params) => (
    //         params.row.clinic && <Link to={`${DOCTOR_PUBLIC_DETAILS}/${params.row.clinic.id}`} className="table_title_cell_link">{params.row.clinic.name}</Link>
    //     )
    // },
    {
        field: 'fees',
        headerName: 'Price',
        width: 100,
        renderCell: (params) => (
            <span><strong>₹</strong>{params.row.fees}</span>
        )
    },
    {
        field: 'appointment_id',
        headerName: 'Appoinments Details',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.id &&
            <div className="justify_center">
                <Link to={`${APPOINMENT_PUBLIC_DETAILS}/${params.row.id}`} className="table_title_cell_link">
                    <Button variant="contained" size="small" style={{ textTransform: 'capitalize' }}>Details</Button>
                </Link>
            </div>
        )
    },
]
export const DoctorAppoinmentsColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'appointment_status',
        headerName: 'Status',
        width: 180,
        flex: 1,
        renderCell: (params) => (
            <div className="justify_center">
                <span className={
                    `status ${params.row.status === "Completed" || "completed" ? "completed"
                        : params.row.status === "Pending" || "pending" ? "pending"
                            : params.row.status === "Cancelled" || "cancelled" ? "cancelled" : ""}`
                }>{params.row.appointment_status}</span>
            </div>
        )
    },
    {
        field: 'booked_date',
        headerName: 'Booked Date',
        width: 120,
        flex: 1,
        renderCell: (params) => (
            <React.Fragment>
                {params.row.booked_date && <span>{`${new Date(params.row.booked_date).getDate()}/${new Date(params.row.booked_date).getMonth()}/${new Date(params.row.booked_date).getFullYear()}`}</span>}
            </React.Fragment>
        )
    },
    {
        field: 'patient',
        headerName: 'Patient Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.patient && <Link to={`${PATIENT_PUBLIC_DETAILS}/${params.row.patient.id}`} className="table_title_cell_link">{params.row.patient.name}</Link>
        )
    },
    {
        field: 'clinic',
        headerName: 'Clinic Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.clinic && <Link to={`${CLINIC_PUBLIC_DETAILS}/${params.row.clinic.id}`} className="table_title_cell_link">{params.row.clinic.name}</Link>
        )
    },
    // {
    //     field: 'clinic',
    //     headerName: 'Clinic Name',
    //     width: 220,
    //     flex: 1,
    //     renderCell: (params) => (
    //         params.row.clinic && <Link to={`${DOCTOR_PUBLIC_DETAILS}/${params.row.clinic.id}`} className="table_title_cell_link">{params.row.clinic.name}</Link>
    //     )
    // },
    {
        field: 'fees',
        headerName: 'Price',
        width: 100,
        renderCell: (params) => (
            <span><strong>₹</strong>{params.row.fees}</span>
        )
    },
    {
        field: 'appointment_id',
        headerName: 'Appoinments Details',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.id &&
            <div className="justify_center">
                <Link to={`${APPOINMENT_PUBLIC_DETAILS}/${params.row.id}`} className="table_title_cell_link">
                    <Button variant="contained" size="small" style={{ textTransform: 'capitalize' }}>Details</Button>
                </Link>
            </div>
        )
    },
]

export const ClinicPaymentsColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'payment_status',
        headerName: 'Status',
        width: 180,
        flex: 1,
        renderCell: (params) => (
            <div className="justify_center">
                <span className={
                    `status ${params.row.status === "Completed" || "completed" ? "completed"
                        : params.row.status === "Pending" || "pending" ? "pending"
                            : params.row.status === "Cancelled" || "cancelled" ? "cancelled" : ""}`
                }>{params.row.payment_status}</span>
            </div>
        )
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 120,
        flex: 1,
        renderCell: (params) => (
            <React.Fragment>
                {params.row.date && <span>{`${new Date(params.row.date).getDate()}/${new Date(params.row.date).getMonth()}/${new Date(params.row.date).getFullYear()}`}</span>}
            </React.Fragment>
        )
    },
    {
        field: 'patient',
        headerName: 'Patient Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.patient && <Link to={`${PATIENT_PUBLIC_DETAILS}/${params.row.patient.id}`} className="table_title_cell_link">{params.row.patient.name}</Link>
        )
    },
    {
        field: 'doctor',
        headerName: 'Doctor Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.doctor && <Link to={`${DOCTOR_PUBLIC_DETAILS}/${params.row.doctor.id}`} className="table_title_cell_link">{params.row.doctor.name}</Link>
        )
    },
    // {
    //     field: 'clinic',
    //     headerName: 'Clinic Name',
    //     width: 220,
    //     flex: 1,
    //     renderCell: (params) => (
    //         params.row.clinic && <Link to={`${DOCTOR_PUBLIC_DETAILS}/${params.row.clinic.id}`} className="table_title_cell_link">{params.row.clinic.name}</Link>
    //     )
    // },
    {
        field: 'fees',
        headerName: 'Price',
        width: 100,
        renderCell: (params) => (
            <span>₹{params.row.fees}</span>
        )
    },
    {
        field: 'appointment_id',
        headerName: 'Appoinments',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.appointment_id &&
            <div className="justify_center">
                <Link to={`${APPOINMENT_PUBLIC_DETAILS}/${params.row.appointment_id}`} className="table_title_cell_link">
                    <Button variant="contained" size="small" style={{ textTransform: 'capitalize' }}>Details</Button>
                </Link>
            </div>
        )
    },
    {
        field: 'payment_id',
        headerName: 'Payments',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.id &&
            <div className="justify_center">
                <Link to={`${PAYMENT_PUBLIC_DETAILS}/${params.row.id}`} className="table_title_cell_link">
                    <Button variant="contained" size="small" style={{ textTransform: 'capitalize' }}>Details</Button>
                </Link>
            </div>
        )
    },
]

export const PatientPaymentsColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'payment_status',
        headerName: 'Status',
        width: 180,
        flex: 1,
        renderCell: (params) => (
            <div className="justify_center">
                <span className={
                    `status ${params.row.status === "Completed" || "completed" ? "completed"
                        : params.row.status === "Pending" || "pending" ? "pending"
                            : params.row.status === "Cancelled" || "cancelled" ? "cancelled" : ""}`
                }>{params.row.payment_status}</span>
            </div>
        )
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 120,
        flex: 1,
        renderCell: (params) => (
            <React.Fragment>
                {params.row.date && <span>{`${new Date(params.row.date).getDate()}/${new Date(params.row.date).getMonth()}/${new Date(params.row.date).getFullYear()}`}</span>}
            </React.Fragment>
        )
    },
    {
        field: 'clinic',
        headerName: 'Clinic Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.clinic && <Link to={`${CLINIC_PUBLIC_DETAILS}/${params.row.clinic.id}`} className="table_title_cell_link">{params.row.clinic.name}</Link>
        )
    },
    {
        field: 'doctor',
        headerName: 'Doctor Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.doctor && <Link to={`${DOCTOR_PUBLIC_DETAILS}/${params.row.doctor.id}`} className="table_title_cell_link">{params.row.doctor.name}</Link>
        )
    },
    // {
    //     field: 'clinic',
    //     headerName: 'Clinic Name',
    //     width: 220,
    //     flex: 1,
    //     renderCell: (params) => (
    //         params.row.clinic && <Link to={`${DOCTOR_PUBLIC_DETAILS}/${params.row.clinic.id}`} className="table_title_cell_link">{params.row.clinic.name}</Link>
    //     )
    // },
    {
        field: 'fees',
        headerName: 'Price',
        width: 100,
        renderCell: (params) => (
            <span>₹{params.row.fees}</span>
        )
    },
    {
        field: 'appointment_id',
        headerName: 'Appoinments',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.appointment_id &&
            <div className="justify_center">
                <Link to={`${APPOINMENT_PUBLIC_DETAILS}/${params.row.appointment_id}`} className="table_title_cell_link">
                    <Button variant="contained" size="small" style={{ textTransform: 'capitalize' }}>Details</Button>
                </Link>
            </div>
        )
    },
    {
        field: 'payment_id',
        headerName: 'Payments',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            params.row.id &&
            <div className="justify_center">
                <Link to={`${PAYMENT_PUBLIC_DETAILS}/${params.row.id}`} className="table_title_cell_link">
                    <Button variant="contained" size="small" style={{ textTransform: 'capitalize' }}>Details</Button>
                </Link>
            </div>
        )
    },
]

export const ClinicColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'active_status', headerName: "Status", width: 80, flex: 1,
        renderCell: (params) => (
            <UpdateStatus row={params.row} />
        )
    },
    {
        field: 'name', headerName: 'Clinic Name', width: 200, renderCell: (params) => (
            <Link to={`${CLINIC_PUBLIC_DETAILS}/${params.row.id}`} className="table_title_cell_link">{params.row.name}</Link>
        )
    },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'contact_no', headerName: 'Contact No', width: 140, flex: 1 },
    { field: 'reg_no', headerName: 'Reg No', width: 140, flex: 1 },
    { field: 'address', headerName: 'Address', width: 200, flex: 1 },
    {
        field: 'action', headerName: "Change Password", width: 140, flex: 1,
        renderCell: (params) => (
            <ReGeneratePassword id={params.row.id} />
        )
    },
]

export const AppoinmentsColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'status',
        headerName: 'Status',
        width: 180,
        flex: 1,
        renderCell: (params) => (
            <div className="justify_center">
                <span className={
                    `status ${params.row.status === "Completed" || "completed" ? "completed"
                        : params.row.status === "Pending" || "pending" ? "pending"
                            : params.row.status === "Cancelled" || "cancelled" ? "cancelled" : ""}`
                }>{params.row.status}</span>
            </div>
        )
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 120,
    },
    {
        field: 'patient_name',
        headerName: 'Patient Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            <Link to={PATIENT_PUBLIC_DETAILS} className="table_title_cell_link">{params.row.patient_name}</Link>
        )
    },
    {
        field: 'doctor_name',
        headerName: 'Doctor Name',
        width: 150,
        flex: 1,
        renderCell: (params) => (
            <Link to={DOCTOR_PUBLIC_DETAILS} className="table_title_cell_link">{params.row.doctor_name}</Link>
        )
    },
    {
        field: 'clinic_name',
        headerName: 'Clinic',
        width: 220,
        flex: 1,
        renderCell: (params) => (
            <Link to={CLINIC_PUBLIC_DETAILS} className="table_title_cell_link">{params.row.clinic_name}</Link>
        )
    },
    {
        field: 'price',
        headerName: 'Price',
        width: 100,
        renderCell: (params) => (
            <span>₹{params.row.price}</span>
        )
    },
    {
        field: 'appointment_id',
        headerName: 'Appoinments Details',
        width: 120,
        flex: 1,
        renderCell: (params) => (
            <div className="justify_center">
                <Link to={`${APPOINMENT_PUBLIC_DETAILS}/${params.row.id}`} className="table_title_cell_link">
                    <Button variant="contained" size="small" style={{ textTransform: 'capitalize' }}>Details</Button>
                </Link>
            </div>
        )
    },
]

export const phoneRegExp = /^(?:(?:\+|0{0, 2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}$/

export const RegNoExp = /^[A-Z]{2}[ -][0-9]{1, 2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/

export const ClinicAddInputs = [
    {
        "name": "name",
        "size": "small",
        "fullWidth": true,
        "label": "Clinic Name",
        "placeholder": "ABC Medical",
        "variant": "outlined"
    },
    {
        "name": "reg_no",
        "size": "small",
        "fullWidth": true,
        "label": "Clinic Reg No",
        "placeholder": "DL01AA5555",
        "variant": "outlined"
    },
    {
        "name": "email",
        "size": "small",
        "fullWidth": true,
        "label": "Clinic Email",
        "placeholder": "abcd@gmail.com",
        "variant": "outlined"
    },
    {
        "name": "contact_no",
        "size": "small",
        "fullWidth": true,
        "label": "Clinic Contact No",
        "placeholder": "9800****90",
        "variant": "outlined"
    },
    {
        "name": "address",
        "size": "small",
        "fullWidth": true,
        "label": "Clinic Address",
        "placeholder": "123 Street, Kolkata",
        "variant": "outlined",
        "rows": 2
    }
]

export const ClinicBankDetailsInputs = [
    {
        "name": "bank_name",
        "size": "small",
        "fullWidth": true,
        "label": "Bank Name",
        "placeholder": "Please enter your bank name",
        "variant": "outlined"
    },
    {
        "name": "account_no",
        "size": "small",
        "fullWidth": true,
        "label": "Account No",
        "placeholder": "12********55",
        "variant": "outlined"
    },
    {
        "name": "ifsc_code",
        "size": "small",
        "fullWidth": true,
        "label": "IFSC Code",
        "placeholder": "SBI*******7",
        "variant": "outlined"
    },
    {
        "name": "account_holder_name",
        "size": "small",
        "fullWidth": true,
        "label": "Account Holder Name",
        "placeholder": "Please enter your account holder name",
        "variant": "outlined"
    },
    {
        "name": "branch_name",
        "size": "small",
        "fullWidth": true,
        "label": "Branch Name",
        "placeholder": "Please enter your branch name",
        "variant": "outlined"
    },
]

export const AdminEditProfile = [
    {
        "name": "name",
        "label": "Name",
        "fullWidth": true,
        "color": "primary",
        "size": "small",
    },
    {
        "name": "email",
        "label": "Email",
        "fullWidth": true,
        "color": "primary",
        "size": "small",
    },
    {
        "name": "contact_no",
        "label": "Contact No",
        "fullWidth": true,
        "color": "primary",
        "size": "small",
    },
    {
        "name": "password",
        "label": "Password",
        "fullWidth": true,
        "color": "primary",
        "size": "small",
    },
]

export const BASE_URL = "https://theappraiser.in/"