import { call, put, takeLatest } from "redux-saga/effects";
import adminApi from '../../../api/admin/adminApi'
import { DashboardApi } from "../../../api/dashboard/DashboardApi";
import { adminActions } from "../../slice/admin/adminSlice";
import { dashboardActions } from "../../slice/dashboard/dashboardSlice";
function* edit(action) {
    try {
        const response = yield call(adminApi.edit, action.payload);
        yield put(adminActions.editSuccess(response));
    } catch (error) {
        yield put(adminActions.editFailed(error.message));
    }
}
function* getAllCountDashboard() {
    try {
        const response = yield call(DashboardApi.getAllCount)
        yield put(dashboardActions.success(response))
    } catch (error) {
        yield put(dashboardActions.failed(error.message))
    }
}
function* deletePatient(action) {
    try {
        const response = yield call(adminApi.patientDelete, action.payload);
        yield put(adminActions.success(response))
    } catch (error) {
        yield put(adminActions.failed(error.message))
    }
}
function* deactivePatient(action) {
    try {
        const response = yield call(adminApi.patientDeactivate, action.payload);
        yield put(adminActions.success(response))
    } catch (error) {
        yield put(adminActions.failed(error.message))
    }
}
function* deleteClinic(action) {
    try {
        const response = yield call(adminApi.clinicDelete, action.payload)
        yield put(adminActions.success(response))
    } catch (error) {
        yield put(adminActions.failed(error.message))
    }
}
function* deactiveClinic(action) {
    try {
        const response = yield call(adminApi.clinicDeactivate, action.payload)
        yield put(adminActions.success(response))
    } catch (error) {
        yield put(adminActions.failed(error.message))
    }
}
function* verifyEmail(action) {
    try {
        const response = yield call(adminApi.verifyEmail, action.payload)
        yield put(adminActions.success(response))
    } catch (error) {
        yield put(adminActions.failed(error.message))
    }
}
function* updateEmail(action) {
    try {
        const response = yield call(adminApi.updateEmail, action.payload)
        yield put(adminActions.success(response))
    } catch (error) {
        yield put(adminActions.failed(error.message))
    }
}
function* adminUpdatePassword(action) {
    try {
        const response = yield call(adminApi.adminPasswordUpdate, action.payload)
        yield put(adminActions.success(response))
    } catch (error) {
        yield put(adminActions.failed(error.message))
    }
}
export default function* adminSaga() {
    yield takeLatest(adminActions.edit.type, edit);
    yield takeLatest(dashboardActions.getAllCount.type, getAllCountDashboard)
    yield takeLatest(adminActions.delelePatient.type, deletePatient);
    yield takeLatest(adminActions.deactivePatient.type, deactivePatient);
    yield takeLatest(adminActions.deleteClinic.type, deleteClinic)
    yield takeLatest(adminActions.deactiveClinic.type, deactiveClinic)
    yield takeLatest(adminActions.verifyEmail.type, verifyEmail)
    yield takeLatest(adminActions.updateEmail.type, updateEmail)
    yield takeLatest(adminActions.adminUpdatePassword.type, adminUpdatePassword)
}