import { call, put, takeLatest } from "redux-saga/effects";
import { specializationDeleteApi } from "../../../api/specialization/specializationDeleteApi";
import { specializationDeleteActions } from "../../slice/specialization/specializationDeleteSlice";

function* specializationDelete (action){
    try {
        const response = yield call(specializationDeleteApi.delete,action.payload);
        yield put(specializationDeleteActions.success(response));
    } catch (error) {
        yield put(specializationDeleteActions.failed(error.message));
    }
}
export default function* specializationDeleteSaga() {
    yield takeLatest(specializationDeleteActions.specializationDelete.type,specializationDelete);
}