import React from "react";
import './custom.scss'
import { Link } from "react-router-dom";
import { User } from '../../assets/svgicons/Svgicons'
import { Logout } from "../../assets/svgicons/Svgicons";
import { useDispatch } from "react-redux";
import { authActions } from "../../redux/slice/auth/authSlice";
export const CustomSelect = (props) => {
    const dispatch = useDispatch()
    function ToggleView(){
        props.toggle()
    }
    return (
        <div className="select_main_wrapper" style={{ display: props.view === true ? "flex" : "none" }}>
            <Link className='link' to='/setting' onClick={ToggleView} ><User stroke="#17BA86" /><p onClick={() => { props.getValue('profile'); props.toggle() }}>Profile</p></Link>
            <Link className='link' to='#' onClick={() => { dispatch(authActions.reset());localStorage.removeItem('access_token');localStorage.removeItem('refresh_token');ToggleView() }} ><Logout stroke="#17BA86" /><p onClick={() => { props.getValue('logout'); props.toggle() }}>Logout</p></Link>
        </div>
    )
}