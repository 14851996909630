import React from 'react'
import '../home/home.scss'
import './clinics.scss'
import './add/addclinic.scss'
import { Button, Modal, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PersonIcon from '@mui/icons-material/Person';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import PhoneIcon from '@mui/icons-material/Phone';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useNavigate, useParams } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Table from '../../components/table/Table';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BusinessIcon from '@mui/icons-material/Business';
import PinIcon from '@mui/icons-material/Pin';
import { ClinicBankDetailsInputs, ClinicDoctorColumns, ClinicAppoinmentsColumns, ClinicPaymentsColumns, ClinicAddInputs } from '../../config/Config'
import { CLINIC_PUBLIC_ADD, CLINIC_PUBLIC_HOME } from '../../routing/clinic/ClinicRouting';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import clinicSelector from '../../redux/selector/clinic/clinicSelector';
import { clinicActions } from '../../redux/slice/clinic/clinicSlice';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useYupValidationResolver } from '../../components/yup/YupResolver';
import CircularProgress from '@mui/material/CircularProgress';
import AlertMsg from '../../components/alert/AlertMsg'
import { yupResolver } from '@hookform/resolvers/yup';
export const ClinicsDetails = () => {
    const params = useParams()
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <React.Fragment>
            <div className="submit_wrap" style={{ justifyContent: 'flex-start' }}>
                <div className="submit_grid">
                    <Button variant="outlined" size="small" onClick={() => { navigate(CLINIC_PUBLIC_HOME) }} sx={{ textTransform: 'capitalize', background: 'white' }}>Back</Button>
                </div>
            </div>
            <Box sx={{ bgcolor: 'white', mx: "12px", borderRadius: '6px 6px 0px 0px', my: "12px" }}>
                <Tabs value={value} onChange={handleChange} variant="scrollable"
                    scrollButtons="auto">
                    <Tab icon={<PersonIcon />} sx={{ textTransform: 'capitalize' }} label="General" />
                    <Tab icon={<GroupAddIcon />} sx={{ textTransform: 'capitalize' }} label="Doctors" />
                    <Tab icon={<FormatListBulletedIcon />} sx={{ textTransform: 'capitalize' }} label="Appointments" />
                    <Tab icon={<PaymentsIcon />} sx={{ textTransform: 'capitalize' }} label="Payments" />
                    <Tab icon={<AccountBalanceIcon />} sx={{ textTransform: 'capitalize' }} label="Bank Info" />
                </Tabs>
            </Box>
            {value === 0 && <GeneralDetails id={params && params.id && params.id} />}
            {value === 1 && <DoctorDetails id={params && params.id && params.id} />}
            {value === 2 && <AppoinmentsDetails id={params && params.id && params.id} />}
            {value === 3 && <PaymentsDetails id={params && params.id && params.id} />}
            {value === 4 && <BankDetails id={params && params.id && params.id} />}
        </React.Fragment >
    );
}

const DoctorDetails = (props) => {
    const [windowDimensions, setWindowDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth });
    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions({ height: window.innerHeight, width: window.innerWidth });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    const dispatch = useAppDispatch()
    const selector = useAppSelector(clinicSelector)
    const [rows, setRows] = React.useState([])
    const [searchVal, setSearchVal] = React.useState()
    const [emptyMsg, setEmptyMsg] = React.useState(false)
    React.useEffect(() => {
        if (props && props.id) {
            dispatch(clinicActions.getDoctor({ 'clinic_id': props.id, 'page': 1, 'limit': 5 }))
        }
    }, [dispatch, props])
    React.useEffect(() => {
        if (selector.data && typeof selector.data === 'object' && selector.data.rows instanceof Array && selector.data.rows.length > 0) {
            setRows(selector.data.rows)
            setEmptyMsg(false)
        }
        else if (selector.data.status === false) {
            setEmptyMsg(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selector.data, searchVal])
    const onSearchChange = (val) => {
        if (val.length > 0) {
            dispatch(clinicActions.searchDoctors({ 'clinic_id': props.id, 'q': val, 'page': 1, 'limit': 5 }))
            setSearchVal(val)
        }
        else {
            dispatch(clinicActions.getDoctor({ 'clinic_id': props.id, 'limit': 5, 'page': 1 }))
            setSearchVal()
        }
    }
    return (
        <React.Fragment>
            {
                (rows && rows.length > 0) ?
                    <Box sx={{ mx: "12px", borderRadius: '6px', mb: "12px" }}>
                        <Table
                            columns={ClinicDoctorColumns}
                            rows={rows}
                            disableCheck={true}
                            totalRows={selector.data.total_rows}
                            onPageChange={(page, ps) => {
                                searchVal ?
                                    dispatch(clinicActions.searchDoctors({ 'clinic_id': props.id, 'q': searchVal, 'page': page, 'limit': ps })) :
                                    dispatch(clinicActions.getDoctor({ 'clinic_id': props.id, 'limit': ps, 'page': page }))
                            }}
                            onPageSizeChange={(page, pageSize) => {
                                searchVal ?
                                    dispatch(clinicActions.searchDoctors({ 'clinic_id': props.id, 'q': searchVal, 'page': page, 'limit': pageSize })) :
                                    dispatch(clinicActions.getDoctor({ 'clinic_id': props.id, 'limit': pageSize, 'page': page }))
                            }}
                            onSearch={onSearchChange}
                        />
                    </Box> : selector.isFetching === false &&
                    <div className="no_data_found_wrap">
                        <div className="no_data_found_img_wrap">
                            <img src={require('../../assets/img/no_data_found.png')} className="no_data_found_img" alt="no data found" />
                        </div>
                        <div className="no_data_found_text_wrap">
                            <span>{"This clinic don't have any doctors. Please create one..."}</span>
                        </div>
                    </div>
            }
            {
                selector && selector.isFetching &&
                <div style={{ height: windowDimensions.height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            }
            {emptyMsg && <AlertMsg open={emptyMsg} msgText={"Can't find any doctors."} type="error" onClose={() => { setEmptyMsg(false) }} />}
        </React.Fragment>
    );
}

const PaymentsDetails = (props) => {
    const dispatch = useAppDispatch()
    const selector = useAppSelector(clinicSelector)
    const [rows, setRows] = React.useState([])
    const [windowDimensions, setWindowDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth });
    const [searchVal, setSearchVal] = React.useState()
    const [emptyMsg, setEmptyMsg] = React.useState(false)
    const [topInfo, setTopInfo] = React.useState()
    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions({ height: window.innerHeight, width: window.innerWidth });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    React.useEffect(() => {
        dispatch(clinicActions.getAllPayments({ 'clinic_id': props.id, 'limit': 5, 'page': 1 }))
    }, [dispatch, props.id])
    React.useEffect(() => {
        if (selector.data && typeof selector.data === 'object' && selector.data.rows instanceof Array && selector.data.rows.length > 0) {
            setRows(selector.data.rows)
            setEmptyMsg(false)
            let obj = {}
            Object.assign(obj, selector.data)
            delete obj["rows"]
            setTopInfo(obj)
        }
        else if (selector.data.status === false) {
            setEmptyMsg(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selector.data, searchVal])
    const onSearchChange = (val) => {
        if (val.length > 0) {
            dispatch(clinicActions.searchPayments({ 'clinic_id': props.id, 'q': val, 'page': 1, 'limit': 5 }))
            setSearchVal(val)
        }
        else {
            dispatch(clinicActions.getAllPayments({ 'clinic_id': props.id, 'limit': 5, 'page': 1 }))
            setSearchVal()
        }
    }
    return (
        <React.Fragment>
            {(rows && rows.length > 0) && <Box sx={{ bgcolor: 'background.paper', mx: "12px", borderRadius: '6px', padding: "12px", mb: "12px" }}>
                <div className="doctor_profile_wrap">
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={3}>
                            <div className="appoin_card">
                                <span className='content'>{topInfo.booked_app}+</span>
                                <span className="desc">Booked Appoinments</span>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={3}>
                            <div className="appoin_card">
                                <span className='content'>{topInfo.complete_app}</span>
                                <span className="desc">Appoinments Completed</span>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={3}>
                            <div className="appoin_card">
                                <span className='content'>{topInfo.pending_app}</span>
                                <span className="desc">Appoinments Pending</span>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={3}>
                            <div className="appoin_card">
                                <span className='content'>₹{topInfo.total_payment}</span>
                                <span className="desc">Received Payment</span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Box>}
            {(rows && rows.length > 0) ? <Box sx={{ mx: "12px", borderRadius: '6px', mb: "12px" }}>
                <Table
                    columns={ClinicPaymentsColumns}
                    rows={rows}
                    disableCheck={true}
                    totalRows={selector.data.total_rows}
                    onPageChange={(page, ps) => {
                        searchVal ?
                            dispatch(clinicActions.searchPayments({ 'clinic_id': props.id, 'q': searchVal, 'page': page, 'limit': ps })) :
                            dispatch(clinicActions.getAllPayments({ 'clinic_id': props.id, 'limit': ps, 'page': page }))
                    }}
                    onPageSizeChange={(page, pageSize) => {
                        searchVal ?
                            dispatch(clinicActions.searchPayments({ 'clinic_id': props.id, 'q': searchVal, 'page': page, 'limit': pageSize })) :
                            dispatch(clinicActions.getAllPayments({ 'clinic_id': props.id, 'limit': pageSize, 'page': page }))
                    }}
                    onSearch={onSearchChange}
                />
            </Box> : selector.isFetching === false &&
            <div className="no_data_found_wrap">
                <div className="no_data_found_img_wrap">
                    <img src={require('../../assets/img/no_data_found.png')} className="no_data_found_img" alt="no data found" />
                </div>
                <div className="no_data_found_text_wrap">
                    <span>{"This clinic don't have any payment."}</span>
                </div>
            </div>
            }
            {
                selector && selector.isFetching &&
                <div style={{ height: windowDimensions.height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            }
            {emptyMsg && <AlertMsg open={emptyMsg} msgText={"Can't find any payments."} type="error" onClose={() => { setEmptyMsg(false) }} />}
        </React.Fragment>
    );
}
const AppoinmentsDetails = (props) => {
    const dispatch = useAppDispatch()
    const selector = useAppSelector(clinicSelector)
    const [rows, setRows] = React.useState([])
    const [windowDimensions, setWindowDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth });
    const [searchVal, setSearchVal] = React.useState()
    const [emptyMsg, setEmptyMsg] = React.useState(false)
    const [topInfo, setTopInfo] = React.useState()
    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions({ height: window.innerHeight, width: window.innerWidth });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    React.useEffect(() => {
        dispatch(clinicActions.getAllAppoinments({ 'clinic_id': props.id, 'limit': 5, 'page': 1 }))
    }, [dispatch, props.id])
    React.useEffect(() => {
        if (selector.data && typeof selector.data === 'object' && selector.data.rows instanceof Array && selector.data.rows.length > 0) {
            setRows(selector.data.rows)
            setEmptyMsg(false)
            let obj = {}
            Object.assign(obj, selector.data)
            delete obj["rows"]
            setTopInfo(obj)
        }
        else if (selector.data.status === false) {
            setEmptyMsg(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selector.data, searchVal])
    const onSearchChange = (val) => {
        if (val.length > 0) {
            dispatch(clinicActions.searchAppoinments({ 'clinic_id': props.id, 'q': val, 'page': 1, 'limit': 5 }))
            setSearchVal(val)
        }
        else {
            dispatch(clinicActions.getAllAppoinments({ 'clinic_id': props.id, 'limit': 5, 'page': 1 }))
            setSearchVal()
        }
    }
    return (
        <React.Fragment>
            {(rows && rows.length > 0) && <Box sx={{ bgcolor: 'background.paper', mx: "12px", borderRadius: '6px', padding: "12px", mb: "12px" }}>
                <div className="doctor_profile_wrap">
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={3}>
                            <div className="appoin_card">
                                <span className='content'>{topInfo.booked_app}+</span>
                                <span className="desc">Booked Appoinments</span>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={3}>
                            <div className="appoin_card">
                                <span className='content'>{topInfo.complete_app}</span>
                                <span className="desc">Appoinments Completed</span>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={3}>
                            <div className="appoin_card">
                                <span className='content'>{topInfo.pending_app}</span>
                                <span className="desc">Appoinments Pending</span>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={3}>
                            <div className="appoin_card">
                                <span className='content'>{topInfo.cancelled_app}</span>
                                <span className="desc">Appoinments Cancelled</span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Box>}
            {(rows && rows.length > 0) ? <Box sx={{ mx: "12px", borderRadius: '6px', mb: "12px" }}>
                <Table
                    columns={ClinicAppoinmentsColumns}
                    rows={rows}
                    disableCheck={true}
                    totalRows={selector.data.total_rows}
                    onPageChange={(page, ps) => { searchVal ? dispatch(clinicActions.searchAppoinments({ 'clinic_id': props.id, 'q': searchVal, 'page': page, 'limit': ps })) : dispatch(clinicActions.getAllAppoinments({ 'clinic_id': props.id, 'limit': ps, 'page': page })) }}
                    onPageSizeChange={(page, pageSize) => { searchVal ? dispatch(clinicActions.searchAppoinments({ 'clinic_id': props.id, 'q': searchVal, 'page': page, 'limit': pageSize })) : dispatch(clinicActions.getAllAppoinments({ 'clinic_id': props.id, 'limit': pageSize, 'page': page })) }}
                    onSearch={onSearchChange}
                />
            </Box> : selector.isFetching === false &&
            <div className="no_data_found_wrap">
                <div className="no_data_found_img_wrap">
                    <img src={require('../../assets/img/no_data_found.png')} className="no_data_found_img" alt="no data found" />
                </div>
                <div className="no_data_found_text_wrap">
                    <span>{"This clinic don't have any appoinments."}</span>
                </div>
            </div>
            }
            {
                selector && selector.isFetching &&
                <div style={{ height: windowDimensions.height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            }
            {emptyMsg && <AlertMsg open={emptyMsg} msgText={"Can't find any appoinments."} type="error" onClose={() => { setEmptyMsg(false) }} />}
        </React.Fragment>
    );
}
const GeneralSchema = yup.object({
    name: yup.string().required("Please enter clinic name"),
    reg_no: yup.string().required("Please enter clinic reg no").matches(/^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$/,"Please enter valid clinic reg no"),
    email: yup.string().required("Please enter clinic email").email("Please enter clinic email"),
    contact_no: yup.string().required("Please enter clinic phone no").matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,"Please enter valid phone no"),
    address: yup.string().required("Please enter clinic address")
});
const GeneralDetails = (props) => {
    const { handleSubmit, register, formState: { errors }, reset, setValue } = useForm({ resolver:yupResolver(GeneralSchema) });
    const dispatch = useAppDispatch()
    const selector = useAppSelector(clinicSelector)
    const navigate = useNavigate()
    const [editModal, setEditModal] = React.useState(false)
    React.useEffect(() => {
        if (props && props.id) {
            dispatch(clinicActions.getOne(props.id))
        }
    }, [dispatch, props])
    const openEditModal = () => {
        setEditModal(true)
        setValue("name", selector.data.name)
        setValue("reg_no", selector.data.reg_no)
        setValue("email", selector.data.email)
        setValue("contact_no", selector.data.contact_no)
        setValue("address", selector.data.address)
    }
    const onSubmit = (data) => {
        data["id"] = props.id
        dispatch(clinicActions.edit(data))
    }
    React.useEffect(() => {
        if (selector && selector.data && selector.data.status) {
            if (props && props.id) {
                dispatch(clinicActions.getOne(props.id))
                setEditModal(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selector, props])
    const [windowDimensions, setWindowDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth });
    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions({ height: window.innerHeight, width: window.innerWidth });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    return (
        <React.Fragment>
            {(selector && (typeof selector.data === "object" && selector.data.hasOwnProperty('email'))) &&
                <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0 12px 12px 12px' }}>
                        <Button variant="contained" size="small" onClick={openEditModal} sx={{ textTransform: 'capitalize' }}>Edit</Button>
                    </div>
                    <Box sx={{ bgcolor: 'background.paper', mx: "12px", borderRadius: '6px', padding: "12px", mb: "12px" }}>
                        <div className="doctor_profile_wrap">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PersonIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Clinic Name"
                                                secondary={selector.data.name}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PhoneIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Phone No"
                                                secondary={
                                                    <a href="tel:9839732890">{selector.data.contact_no}</a>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <EmailIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Email"
                                                secondary={
                                                    <a href="mailto:alex@gmail.com">{selector.data.email}</a>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                {selector.data.reg_no && <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <AppRegistrationIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Reg No"
                                                secondary={selector.data.reg_no}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>}
                                {selector.data.address && <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <LocationOnIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Adress"
                                                secondary={selector.data.address}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>}
                            </Grid>
                        </div>
                    </Box>
                </React.Fragment>
            }
            {
                (selector && selector.data && selector.data.status === false) &&
                <div className="no_data_found_wrap">
                    <div className="no_data_found_img_wrap">
                        <img src={require('../../assets/img/no_data_found.png')} className="no_data_found_img" alt="no data found" />
                    </div>
                    <div className="no_data_found_text_wrap">
                        <span>{selector.data.message}</span>
                        <Button variant="contained" size="small" onClick={() => {
                            navigate(CLINIC_PUBLIC_ADD);
                        }} sx={{ textTransform: 'capitalize', ml: 2 }}>Create One</Button>
                    </div>
                </div>
            }

            {
                editModal &&
                <CustomModal open={editModal} onClose={() => { setEditModal(false) }} children={
                    <div className="add_wrap" style={{ paddingTop: 12 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1 className="form_header" style={{ marginBottom: 0 }}>Edit a Bank Details</h1>
                            <span style={{ cursor: 'pointer' }} onClick={() => { setEditModal(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" style={{ height: 24, width: 24 }} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>
                        </div>
                        <form className="form_wrap" style={{ padding: 0 }} onSubmit={handleSubmit(onSubmit)}>
                            <div className="form_body">
                                {
                                    ClinicAddInputs.map((elem, index) => {
                                        return (
                                            <div className="form_body_item" key={index}>
                                                <TextField multiline={elem.multiline ? true : false} rows={elem.multiline ? elem.rows : 1} fullWidth={elem.fullWidth ? true : false} {...register(elem.name)} size={elem.size} label={elem.label} placeholder={elem.placeholder} variant={elem.variant} />
                                                {errors[elem.name] && <span className="error_text">{errors[elem.name].message}</span>}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </form>
                        <div className="submit_wrap">
                            <div className="submit_grid">
                                <Button variant="outlined" type="reset" onClick={() => { reset() }} size="small" sx={{ textTransform: 'capitalize', background: 'white' }}>Clear</Button>
                                <Button variant="contained" size="small" sx={{ textTransform: 'capitalize' }} onClick={handleSubmit(onSubmit)}>Submit</Button>
                            </div>
                        </div>
                    </div>
                } />
            }
            {
                selector && selector.isFetching &&
                <div style={{ height: windowDimensions.height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            }
        </React.Fragment>
    );
}
export const bankSchema = yup.object({
    bank_name: yup.string().required("Please enter bank name"),
    account_no: yup.string().required("Please enter account no").matches(/^\d{9,18}$/,"Please enter valid account no"),
    ifsc_code: yup.string().required("Please enter ifsc code").matches(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,"Please enter valid ifsc code"),
    account_holder_name: yup.string().required("Please enter account holder name"),
    branch_name: yup.string().required("Please enter branch name")
});
export const BankDetails = (props) => {
    const { handleSubmit, register, formState: { errors }, reset, setValue } = useForm({ resolver:yupResolver(bankSchema) });
    // const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const selector = useAppSelector(clinicSelector)
    React.useEffect(() => {
        if (props && props.id) {
            dispatch(clinicActions.getBankDetails(props.id))
        }
    }, [dispatch, props])
    const [addModal, setAddModal] = React.useState(false)
    const [editModal, setEditModal] = React.useState(false)
    const onSubmit = (data) => {
        data["user_id"] = props.id
        if (addModal) {
            dispatch(clinicActions.addBank(data))
        }
        else {
            dispatch(clinicActions.editBank(data))
        }
    }
    React.useEffect(() => {
        if (selector && selector.data && selector.data.status) {
            dispatch(clinicActions.getBankDetails(props.id))
            setAddModal(false)
            setEditModal(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selector])
    const openEditModal = () => {
        setEditModal(true)
        setValue("bank_name", selector.data.bank_name)
        setValue("account_no", selector.data.account_no)
        setValue("ifsc_code", selector.data.ifsc_code)
        setValue("account_holder_name", selector.data.account_holder_name)
        setValue("branch_name", selector.data.branch_name)
    }
    return (
        <React.Fragment>
            {(selector && selector.data && typeof selector.data === 'object' && selector.data.account_no) &&
                <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0 12px 12px 12px' }}>
                        <Button variant="contained" size="small" onClick={openEditModal} sx={{ textTransform: 'capitalize' }}>Edit</Button>
                    </div>
                    <Box sx={{ bgcolor: 'background.paper', mx: "12px", borderRadius: '6px', padding: "12px", mb: "12px" }}>
                        <div className="doctor_profile_wrap">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <AccountBalanceIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Bank Name"
                                                secondary={selector.data.bank_name}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PinIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Account No"
                                                secondary={selector.data.account_no}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PinIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="IFSC Code"
                                                secondary={selector.data.ifsc_code}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <BusinessIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Branch Name"
                                                secondary={selector.data.branch_name}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    {/* <BusinessIcon /> */}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Account Holder"
                                                secondary={selector.data.account_holder_name}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </React.Fragment>
            }
            <React.Fragment>
                {(selector && selector.data && typeof selector.data === 'object' && selector.data.status === false) &&
                    <div className="no_data_found_wrap">
                        <div className="no_data_found_img_wrap">
                            <img src={require('../../assets/img/no_data_found.png')} className="no_data_found_img" alt="no data found" />
                        </div>
                        <div className="no_data_found_text_wrap">
                            <span>{"You don't have any account. "}</span>
                            <Button variant="contained" size="small" onClick={() => {
                                setAddModal(true)
                            }} sx={{ textTransform: 'capitalize', ml: 1 }}>Create One</Button>
                        </div>
                    </div>
                }
            </React.Fragment>
            {
                addModal &&
                <CustomModal open={addModal} onClose={() => { setAddModal(false) }} children={
                    <div className="add_wrap" style={{ paddingTop: 12 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1 className="form_header" style={{ marginBottom: 0 }}>Add a Bank Details</h1>
                            <span style={{ cursor: 'pointer' }} onClick={() => { setAddModal(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" style={{ height: 24, width: 24 }} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>
                        </div>
                        <form className="form_wrap" style={{ padding: 0 }} onSubmit={handleSubmit(onSubmit)}>
                            <div className="form_body">
                                {
                                    ClinicBankDetailsInputs.map((elem, index) => {
                                        return (
                                            <div className="form_body_item" key={index}>
                                                <TextField multiline={elem.multiline ? true : false} rows={elem.multiline ? elem.rows : 1} fullWidth={elem.fullWidth ? true : false} {...register(elem.name)} size={elem.size} label={elem.label} placeholder={elem.placeholder} variant={elem.variant} />
                                                {errors[elem.name] && <span className="error_text">{errors[elem.name].message}</span>}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </form>
                        <div className="submit_wrap">
                            <div className="submit_grid">
                                <Button variant="outlined" type="reset" onClick={() => { reset() }} size="small" sx={{ textTransform: 'capitalize', background: 'white' }}>Clear</Button>
                                <Button variant="contained" size="small" sx={{ textTransform: 'capitalize' }} onClick={handleSubmit(onSubmit)}>Submit</Button>
                            </div>
                        </div>
                    </div>
                } />
            }
            {
                editModal &&
                <CustomModal open={editModal} onClose={() => { setEditModal(false) }} children={
                    <div className="add_wrap" style={{ paddingTop: 12 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1 className="form_header" style={{ marginBottom: 0 }}>Edit a Bank Details</h1>
                            <span style={{ cursor: 'pointer' }} onClick={() => { setEditModal(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" style={{ height: 24, width: 24 }} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>
                        </div>
                        <form className="form_wrap" style={{ padding: 0 }} onSubmit={handleSubmit(onSubmit)}>
                            <div className="form_body">
                                {
                                    ClinicBankDetailsInputs.map((elem, index) => {
                                        return (
                                            <div className="form_body_item" key={index}>
                                                <TextField multiline={elem.multiline ? true : false} rows={elem.multiline ? elem.rows : 1} fullWidth={elem.fullWidth ? true : false} {...register(elem.name)} size={elem.size} label={elem.label} placeholder={elem.placeholder} variant={elem.variant} />
                                                {errors[elem.name] && <span className="error_text">{errors[elem.name].message}</span>}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </form>
                        <div className="submit_wrap">
                            <div className="submit_grid">
                                <Button variant="outlined" type="reset" onClick={() => { reset() }} size="small" sx={{ textTransform: 'capitalize', background: 'white' }}>Clear</Button>
                                <Button variant="contained" size="small" sx={{ textTransform: 'capitalize' }} onClick={handleSubmit(onSubmit)}>Submit</Button>
                            </div>
                        </div>
                    </div>
                } />
            }
        </React.Fragment>
    );
}

export const CustomModal = (props) => {
    return (
        <Modal open={props.open} onClose={() => { props.onClose(true) }} keepMounted>
            <Box className="modal_box">
                {props.children}
            </Box>
        </Modal>
    );
}