import { call, put, takeLatest } from "redux-saga/effects";
import { paymentActions } from '../../slice/payment/paymentSlice'
import paymentApi from "../../../api/payments/paymentApi";
function* getAll(action) {
    try {
        const response = yield call(paymentApi.doctorPayment, action.payload);
        yield put(paymentActions.success(response));
    } catch (error) {
        yield put(paymentActions.failed(error.message));
    }
}

function* search(action) {
    try {
        const response = yield call(paymentApi.search, action.payload);
        yield put(paymentActions.success(response));
    } catch (error) {
        yield put(paymentActions.failed(error.message));
    }
}
function* performanceDoctor(action) {
    try {
        const response = yield call(paymentApi.performanceDoctor, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* doctorPaymentSearch(action) {
    try {
        const response = yield call(paymentApi.doctorPaymentSearch, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* doctorPaymentCount(action) {
    try {
        const response = yield call(paymentApi.doctorPaymentsCount, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* performancePayment(action) {
    try {
        const response = yield call(paymentApi.performancePayment, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* performancePaymentGetAll(action) {
    try {
        const response = yield call(paymentApi.performancePaymentGetAll, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* performancePaymentGetAllSearch(action) {
    try {
        const response = yield call(paymentApi.performancePaymentGetAllSearch, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* clinicPaymentGetAll(action) {
    try {
        const response = yield call(paymentApi.clinicPaymentGetAll, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* clinicPaymentGetAllSearch(action) {
    try {
        const response = yield call(paymentApi.clinicPaymentGetAllSearch, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* clinicPaymentPerformance(action) {
    try {
        const response = yield call(paymentApi.clinicPaymentPerformance, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* clinicPaymentCount(action) {
    try {
        const response = yield call(paymentApi.clinicPaymentCount, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* paymentGetAll(action) {
    try {
        const response = yield call(paymentApi.paymentGetAll, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* paymentGetAllSearch(action) {
    try {
        const response = yield call(paymentApi.paymentGetAllSearch, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* paymentPerformance(action) {
    try {
        const response = yield call(paymentApi.paymentPerformance, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* paymentCount() {
    try {
        const response = yield call(paymentApi.paymentCount)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
function* paymentSingleGet(action) {
    try {
        const response = yield call(paymentApi.paymentSingleGet, action.payload)
        yield put(paymentActions.success(response))
    } catch (error) {
        yield put(paymentActions.failed(error.message))
    }
}
export default function* paymentSaga() {
    yield takeLatest(paymentActions.getAll.type, getAll)
    yield takeLatest(paymentActions.search.type, search)
    yield takeLatest(paymentActions.performanceDoctor.type, performanceDoctor)
    yield takeLatest(paymentActions.searchDoctorPayments.type, doctorPaymentSearch)
    yield takeLatest(paymentActions.doctorPaymentCount.type, doctorPaymentCount)
    yield takeLatest(paymentActions.performancePayment.type, performancePayment)
    yield takeLatest(paymentActions.performancePaymentGetAll.type, performancePaymentGetAll)
    yield takeLatest(paymentActions.performancePaymentGetAllSearch.type, performancePaymentGetAllSearch)
    yield takeLatest(paymentActions.clinicPaymentGetAll.type, clinicPaymentGetAll)
    yield takeLatest(paymentActions.clinicPaymentGetAllSearch.type, clinicPaymentGetAllSearch)
    yield takeLatest(paymentActions.clinicPaymentPerformance.type, clinicPaymentPerformance)
    yield takeLatest(paymentActions.clinicPaymentCount.type, clinicPaymentCount)
    yield takeLatest(paymentActions.paymentGetAll.type, paymentGetAll)
    yield takeLatest(paymentActions.paymentGetAllSearch.type, paymentGetAllSearch)
    yield takeLatest(paymentActions.paymentPerformance.type, paymentPerformance)
    yield takeLatest(paymentActions.paymentCount.type, paymentCount)
    yield takeLatest(paymentActions.paymentSingleGet.type, paymentSingleGet)
}