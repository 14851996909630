import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { Star, ThumbUp, UserGroup } from "../../assets/svgicons/Svgicons";
import { AppointmentTable } from "../../components/appointment_table/AppointmentTable";
import { AreaChart } from "../../components/area_chart/AreaChart";
import { BackButton } from "../../components/buttons/Buttons";
import { ProgressCircle } from "../../components/progress_circle/ProgressCircle";
import { adminSelector } from "../../redux/selector/admin/adminSelector";
import appoinmentSelector from "../../redux/selector/appoinment/appoinmentSelector";
import clinicSelector from "../../redux/selector/clinic/clinicSelector";
import { doctorSelector } from "../../redux/selector/doctor/doctorSelector";
import paymentSelector from "../../redux/selector/payment/paymentSelector";
import { adminActions } from "../../redux/slice/admin/adminSlice";
import { appoinmentActions } from "../../redux/slice/appoinment/appoinmentSlice";
import { clinicActions } from "../../redux/slice/clinic/clinicSlice";
import { doctorActions } from "../../redux/slice/doctor/doctorSlice";
import { paymentActions } from "../../redux/slice/payment/paymentSlice";
import { ClinicProfileCard, ClinicSocialMedia } from "../home/NewHome";
import './style.scss'
import { formatDate } from "../../utils";
export const ClinicDetails = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const selector = useSelector(clinicSelector)
    const adminSelect = useSelector(adminSelector)
    const appoinmentPerformanceSelector = useSelector(appoinmentSelector)
    const paymentSelect = useSelector(paymentSelector)
    const params = useParams()
    const [clinicData, setClinicData] = React.useState()
    React.useMemo(() => {
        if (params && params.id) {
            dispatch(clinicActions.getOne(params.id))
        }
    }, [params, dispatch])
    React.useMemo(() => {
        if (selector && selector.data && selector.data.id) {
            setClinicData(selector.data)
        }
    }, [selector])
    const deactiveClick = () => {
        if (params && params.id) {
            dispatch(adminActions.deactiveClinic({ "id": params.id }))
        }
    }
    const deleteClick = () => {
        if (window.confirm("Are you want to delete these clinic?")) {
            if (params && params.id) {
                dispatch(adminActions.deleteClinic({ 'id': params.id }))
            }
        }
    }
    React.useEffect(() => {
        if (adminSelect && adminSelect.data && adminSelect.data.status) {
            if (adminSelect.data.message === "Clinic account is deleted successfully") {
                window.alert("These clinic deleted successfully")
                navigate('/clinic')
            }
            else {
                if (params && params.id) {
                    dispatch(clinicActions.getOne(params.id))
                }
            }
            dispatch(adminActions.reset())
        }
    }, [adminSelect])
    React.useEffect(() => {
        if (params && params.id) {
            dispatch(appoinmentActions.clinicAppointmentPerformance({ 'id': params.id, 'filter': 'year' }))
        }
    }, [params, dispatch])
    React.useEffect(() => {
        if (params && params.id) {
            dispatch(appoinmentActions.clinicAppointmentGetAll({ 'page': 1, 'limit': 5, 'clinic_id': params.id }))
            dispatch(paymentActions.clinicPaymentGetAll({ "page": 1, "limit": 5, "clinic_id": params.id }))
        }
    }, [params, dispatch])
    const [appoinmentPerformanceData, setAppoinmentPerformanceData] = React.useState([])
    const [appoinmentPerformanceTableData, setAppoinmentPerformanceTableData] = React.useState([])
    React.useEffect(() => {
        if (appoinmentPerformanceSelector && appoinmentPerformanceSelector.data && appoinmentPerformanceSelector.data instanceof Array) {
            if (appoinmentPerformanceSelector.data.length > 0) {
                setAppoinmentPerformanceData(appoinmentPerformanceSelector)
            }
            else {
                setAppoinmentPerformanceData([])
            }
        }
        else if (appoinmentPerformanceSelector.data.rows && appoinmentPerformanceSelector.data.rows instanceof Array) {
            if (appoinmentPerformanceSelector.data.rows.length > 0) {
                setAppoinmentPerformanceTableData(appoinmentPerformanceSelector)
            }
            else {
                setAppoinmentPerformanceTableData([])
            }
        }
    }, [appoinmentPerformanceSelector])
    const [paymentTableData, setPaymentTableData] = React.useState([])
    React.useEffect(() => {
        if (paymentSelect && paymentSelect.data && paymentSelect.data.rows && paymentSelect.data.rows instanceof Array) {
            if (paymentSelect.data.rows.length > 0) {
                setPaymentTableData(paymentSelect)
            }
            else {
                setPaymentTableData([])
            }
        }
    }, [paymentSelect])
    return (
        <React.Fragment>
            {selector.isFetching ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '128px 0', width: '100%' }}>
                <CircularProgress />
            </div> :
                <React.Fragment>
                    <div className="top_header">
                        <BackButton />
                    </div>
                    <div className="details_main_wrapper">
                        <div className="top_left">
                            <div className="card_shadow">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <p style={{ paddingBottom: 0, margin: 0, fontWeight: 'bold', color: '#314c7c' }}>Appointment Performance</p>
                                        
                                    </div>
                                {
                                    appoinmentPerformanceSelector.isFetching ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',width: '100%',}}>
                                            <CircularProgress />
                                        </div> : appoinmentPerformanceData.data && appoinmentPerformanceData.data.length > 0 ? <AreaChart data={
                                            {
                                                'labels': appoinmentPerformanceData.data.map(e => e._id),
                                                'datasets': [
                                                    {
                                                        'fill': true,
                                                        'label': "Count",
                                                        'lineTension': 0.5,
                                                        'data': appoinmentPerformanceData.data.map(e => e.count),
                                                        backgroundColor: "rgba(75,192,192,0.2)",
                                                        borderColor: "rgba(75,192,192,1)"
                                                    }
                                                ]
                                            }
                                        } /> :
                                            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 320, maxWidth: '100%' }} />
                                            </div>
                                }
                            </div>
                        </div>
                        {clinicData && clinicData.name && <ClinicProfileCard message={true} onClick={true} data={clinicData} view={"grid"} />}
                        {clinicData && clinicData.name && <div className="clinic_details_button_grid" >
                            <Button onClick={deleteClick} sx={{ textTransform: 'capitalize' }} style={{ width: '100%', justifyContent: 'center', backgroundColor: "rgba(255, 0, 0,0.6)", color: "white" }}>Delete Account</Button>
                            <Button onClick={deactiveClick} sx={{ textTransform: 'capitalize' }} style={{ width: '100%', justifyContent: 'center', backgroundColor: "lightgray", color: "white" }}>{clinicData.active_status ? "Deactivate Account" : "Activate Account"}</Button>
                            <Button onClick={() => { navigate("/clinic/details/edit") }} sx={{ textTransform: 'capitalize' }} style={{ width: '100%', justifyContent: 'center', backgroundColor: "#00b3fe", color: "white" }}>Edit Account</Button>
                            <Link to={`/clinic/bank_information/${selector.data.id}`} style={{ width: "100%" }}> <Button sx={{ textTransform: 'capitalize' }} style={{ width: '100%', justifyContent: 'center', backgroundColor: "#17BA86", color: "white" }}>Bank Info</Button></Link>
                        </div>}
                    </div>
                    <div className="grid_two">
                        {appoinmentPerformanceSelector && appoinmentPerformanceSelector.isFetching ?
                            <div className="card_shadow_out_padd">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '240px', width: '100%', background: 'white', borderRadius: 6, margin: '48px 0' }}>
                                    <CircularProgress />
                                </div>
                            </div>
                            :
                            (appoinmentPerformanceTableData && appoinmentPerformanceTableData.data && appoinmentPerformanceTableData.data.rows && appoinmentPerformanceTableData.data.rows.length > 0) ?
                                <div className="card_shadow_out_padd">
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 12 }}>
                                        <p style={{ paddingBottom: 0, margin: 0, fontWeight: 'bold', color: '#314c7c' }}>Recent Appointments</p>
                                        <button onClick={() => { navigate(`/clinic/appoinment/${params.id}`) }} style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14, color: '#314c7c' }}>View All</button>
                                    </div>
                                    <div className="appoinment">
                                        <div className="table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            <div className="th_cont">
                                                                <span>Patient Name</span>
                                                            </div>
                                                        </td>
                                                        <td>Status</td>
                                                        <td>Doctor Name</td>
                                                        <td>Fees</td>
                                                        <td>Date</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {appoinmentPerformanceTableData.data.rows.map((elem, index) => {
                                                        return (
                                                            <tr onClick={() => { navigate(`/appointment/details/${elem.id}`) }} key={index}>
                                                                <td>
                                                                    <div className="usr_ico_n_tex">
                                                                        <div className="ico_wrp">
                                                                            <img src={require('../../assets/icon/patient.png')} alt="user" />
                                                                        </div>
                                                                        <span className="title">{elem.patient ? elem.patient.name : null}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="status_flex">
                                                                        <span className={(elem.appointment_status.trim() === "Booked" || elem.appointment_status.trim() === "Completed") ? "enabled status" : "disabled status"}></span>
                                                                        <span className="title">{elem.appointment_status ? elem.appointment_status : null}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="usr_ico_n_tex">
                                                                        <div className="ico_wrp">
                                                                            <img src={require('../../assets/icon/doctor.png')} alt="user" />
                                                                        </div>
                                                                        <span className="title">{elem.doctor ? elem.doctor.name : null}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="usr_ico_n_tex">
                                                                        <div className="ico_wrp">
                                                                            <img src={require('../../assets/icon/rupee.png')} alt="user" />
                                                                        </div>
                                                                        <span className="title">{elem.fees ? elem.fees : null}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {elem.appointment_date ?
                                                                        <React.Fragment>
                                                                            {formatDate(elem?.appointment_date)} {elem?.time}
                                                                        </React.Fragment> : null}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> :
                                <div className="card_shadow_out_padd">
                                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 240, maxWidth: '100%' }} />
                                    </div>
                                </div>
                        }
                        {paymentSelect && paymentSelect.isFetching ?
                            <div className="card_shadow_out_padd">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '240px', width: '100%', background: 'white', borderRadius: 6, margin: '48px 0' }}>
                                    <CircularProgress />
                                </div>
                            </div>
                            :
                            (paymentTableData && paymentTableData.data && paymentTableData.data.rows && paymentTableData.data.rows.length > 0) ?
                                <div className="card_shadow_out_padd">
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 12 }}>
                                        <p style={{ paddingBottom: 0, margin: 0, fontWeight: 'bold', color: '#314c7c' }}>Recent Payments</p>
                                        <button onClick={() => { navigate(`/clinic/payment/${params.id}`) }} style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14, color: '#314c7c' }}>View All</button>
                                    </div>
                                    <div className="appoinment">
                                        <div className="table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            <div className="th_cont">
                                                                <span>Patient Name</span>
                                                            </div>
                                                        </td>
                                                        <td>Status</td>
                                                        <td>Doctor Name</td>
                                                        <td>Fees</td>
                                                        <td>Date</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {paymentTableData.data.rows.map((elem, index) => {
                                                        return (
                                                            <tr onClick={() => { navigate(`/payment/details/${elem.id}`) }} key={index}>
                                                                <td>
                                                                    <div className="usr_ico_n_tex">
                                                                        <div className="ico_wrp">
                                                                            <img src={require('../../assets/icon/patient.png')} alt="user" />
                                                                        </div>
                                                                        <span className="title">{elem.patient ? elem.patient.name : null}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="status_flex">
                                                                        <span className={elem.payment_status === "Success" ? "enabled status" : "disabled status"}></span>
                                                                        <span className="title">{elem.payment_status ? elem.payment_status : null}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="usr_ico_n_tex">
                                                                        <div className="ico_wrp">
                                                                            <img src={require('../../assets/icon/doctor.png')} alt="user" />
                                                                        </div>
                                                                        <span className="title">{elem.doctor ? elem.doctor.name : null}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="usr_ico_n_tex">
                                                                        <div className="ico_wrp">
                                                                            <img src={require('../../assets/icon/rupee.png')} alt="user" />
                                                                        </div>
                                                                        <span className="title">{elem.fees ? elem.fees : null}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {elem.date ?
                                                                        <React.Fragment>
                                                                            {`${new Date(elem.date).toDateString()}, ${new Date(elem.date).toLocaleTimeString()}`}
                                                                        </React.Fragment> : null}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> :
                                <div className="card_shadow_out_padd">
                                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={require('../../assets/img/no_data.png')} alt="not found" style={{ height: 240, maxWidth: '100%' }} />
                                    </div>
                                </div>
                        }
                    </div>
                    <ClinicDoctorTable />
                </React.Fragment>
            }
        </React.Fragment>
    )
}
export const ClinicPaymentTable = () => {
    const navigate = useNavigate()
    const selector = useSelector(clinicSelector)
    return (
        <React.Fragment>
            <div className="card_shadow_out_padd">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 12 }}>
                    <p style={{ paddingBottom: 0, margin: 0, fontWeight: 'bold', color: '#314c7c' }}>Recent Payments</p>
                    <button onClick={() => { navigate(`/clinic/payment/${selector.data.id}`) }} style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14, color: '#314c7c' }}>View All</button>
                </div>
                <div className="appoinment">
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                    <td>Appointment Id</td>
                                    <td>
                                        <div className="th_cont">
                                            <span>Patient Name</span>
                                        </div>
                                    </td>
                                    <td>Status</td>
                                    <td>
                                        <div className="th_cont">
                                            <span>Doctor Name</span>
                                        </div>
                                    </td>
                                    <td>Amount</td>
                                    <td>Date</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr onClick={() => { navigate('/apointment/details/123') }}>
                                    <td>
                                        <div className="usr_ico_n_tex">
                                            <span className="title">ABC1234567890</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="usr_ico_n_tex">
                                            <div className="ico_wrp">
                                                <img src={require('../../assets/icon/patient.png')} alt="user" />
                                            </div>
                                            <span className="title">Chandan Payra</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="status_flex">
                                            <span className="enabled status"></span>
                                            <span className="title">Paid</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="usr_ico_n_tex">
                                            <div className="ico_wrp">
                                                <img src={require('../../assets/icon/doctor.png')} alt="user" />
                                            </div>
                                            <span className="title">Dr. Arun Kumar</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="usr_ico_n_tex">
                                            <div className="ico_wrp">
                                                <img src={require('../../assets/icon/rupee.png')} alt="user" />
                                            </div>
                                            <span className="title">300.50</span>
                                        </div>
                                    </td>
                                    <td>
                                        {`${new Date().toDateString()}, ${new Date().toLocaleTimeString()}`}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export const ClinicDoctorTable = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const selector = useSelector(doctorSelector)
    const clinicSelect = useSelector(clinicSelector)
    const params = useParams()
    const [rows, setRows] = React.useState([])
    React.useEffect(() => {
        if (params && params.id && clinicSelect && clinicSelect.data && clinicSelect.data.id) {
            dispatch(doctorActions.getAllByClinic({ 'clinic_id': clinicSelect.data.id, 'page': 1, 'limit': 5 }))
        }
    }, [params, dispatch, clinicSelect])
    React.useEffect(() => {
        if (selector && selector.data && selector.data.rows && selector.data.rows instanceof Array && selector.data.rows.length > 0) {
            setRows(selector.data.rows)
        }
        else {
            setRows([])
        }
    }, [selector])
    return (
        <React.Fragment>
            {selector.isFetching === true ?
                <div style={{ height: 359, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
                :
                rows.length === 0 ?
                    <div className="card_shadow_out_padd" style={{ height: 359, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 22 }}>
                        <img src={require('../../assets/img/no_data_found.png')} style={{ height: 250, maxWidth: '100%' }} alt="no data found" />
                        <span style={{ color: 'gray' }}>We Can't find any doctor.</span>
                    </div>
                    : <div className="card_shadow_out_padd main_table" style={{ marginTop: 22,backgroundColor:'white',borderRadius:'6px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 12 }}>
                            <p style={{ paddingBottom: 0, margin: 0, fontWeight: 'bold', color: '#314c7c' }}>Doctors</p>
                            <button onClick={() => { navigate(`/clinic/doctor/${params.id}`) }} style={{ boxShadow: 'none', background: '#f2f6f8', padding: '6px 12px', fontSize: 14, color: '#314c7c' }}>View All</button>
                        </div>
                        <div className="appoinment">
                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <td>
                                                <div className="th_cont">
                                                    <span>Name</span>
                                                </div>
                                            </td>
                                            <td>Status</td>
                                            <td>Email</td>
                                            <td>Phone No</td>
                                            <td>Specializations</td>
                                            <td>Fees</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            rows && rows.length > 0 && rows.map((elem, index) => {
                                                return (
                                                    <tr key={index} onClick={() => { navigate(`/doctor/details/${elem.id}`) }}>
                                                        <td>
                                                            <div className="usr_ico_n_tex">
                                                                <div className="ico_wrp">
                                                                    <img src={require('../../assets/icon/doctor.png')} alt="user" />
                                                                </div>
                                                                <span className="title">{elem.name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="status_flex">
                                                                <span className={elem.active_status ? "enabled status" : "disabled status"}></span>
                                                                <span className="title">{elem.active_status ? "Active" : "Inactive"}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="usr_ico_n_tex">
                                                                <div className="ico_wrp">
                                                                    <img src={require('../../assets/icon/social/gmail.png')} alt="user" />
                                                                </div>
                                                                <span className="title">{elem.email ? elem.email : ''}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="usr_ico_n_tex">
                                                                <div className="ico_wrp">
                                                                    <img src={require('../../assets/icon/social/phone.png')} alt="user" />
                                                                </div>
                                                                <span className="title">{elem.contact_no ? elem.contact_no : ''}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="usr_ico_n_tex">
                                                                <span className="title">{elem.specializations ? elem.specializations : ''}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="usr_ico_n_tex">
                                                                <div className="ico_wrp">
                                                                    <img src={require('../../assets/icon/rupee.png')} alt="user" />
                                                                </div>
                                                                <span className="title">{elem.fees ? elem.fees.includes('.') ? elem.fees : `${elem.fees}.00` : ''}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            }
        </React.Fragment>
    );
}