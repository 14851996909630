import { all } from "redux-saga/effects";
import authSaga from "../redux/saga/auth/authSaga";
import clinicSaga from "../redux/saga/clinic/clinicSaga";
import adminSaga from '../redux/saga/admin/adminSaga'
import appoinmentSaga from '../redux/saga/appoinment/appoinmentSaga'
import paymentSaga from '../redux/saga/payment/paymentSaga'
import patientSaga from '../redux/saga/patient/patientSaga'
import doctorSaga from '../redux/saga/doctor/doctorSaga'
import appoinmentTableSaga from "../redux/saga/appoinment/appoinmentTableSaga";
import aboutSaga from "../redux/saga/about/aboutSaga";
import termsSaga from '../redux/saga/terms/termsSaga'
import aboutUpdateSaga from "../redux/saga/about/aboutUpdateSaga";
import aboutAddSaga from "../redux/saga/about/aboutAddSaga";
import termsAddSaga from "../redux/saga/terms/termsAddSaga";
import termsUpdateSaga from "../redux/saga/terms/termsUpdateSaga";
import aboutDeleteSaga from "../redux/saga/about/aboutDeleteSaga";
import termsDeleteSaga from "../redux/saga/terms/termsDeleteSaga";
import privacyAddSaga from "../redux/saga/privacy/privacyAddSaga";
import privacySaga from "../redux/saga/privacy/privacySaga";
import privacyDeleteSaga from "../redux/saga/privacy/privacyDeleteSaga";
import privacyUpdateSaga from "../redux/saga/privacy/privacyUpdateSaga";
import specializationAddSaga from "../redux/saga/specialization/specializationAddSaga";
import specializationGetAllSaga from "../redux/saga/specialization/specializationGetAllSaga";
import specializationDeleteSaga from "../redux/saga/specialization/specializationDeleteSaga";
import specializationUpdateSaga from "../redux/saga/specialization/specializationUpdateSaga";
export function* rootSaga() {
    yield all([
        authSaga(),
        clinicSaga(),
        adminSaga(),
        appoinmentSaga(),
        paymentSaga(),
        patientSaga(),
        doctorSaga(),
        appoinmentTableSaga(),
        aboutSaga(),
        termsSaga(),
        aboutUpdateSaga(),
        aboutAddSaga(),
        termsAddSaga(),
        termsUpdateSaga(),
        aboutDeleteSaga(),
        termsDeleteSaga(),
        privacyAddSaga(),
        privacySaga(),
        privacyDeleteSaga(),
        privacyUpdateSaga(),
        specializationAddSaga(),
        specializationGetAllSaga(),
        specializationUpdateSaga(),
        specializationDeleteSaga()
    ])
}
export default rootSaga;