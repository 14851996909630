import { urls } from '../urls'
import axiosClient from '../axiosClient';

const appoinmentApi = {
    getAll(params) {
        return axiosClient.get(`${urls.GET_ALL_APPOINMENTS}`, { params })
    },
    getOne(params) {
        return axiosClient.get(`${urls.GET_SINGLE_APPOINMENTS}/${params}`)
    },
    search(params) {
        return axiosClient.get(`${urls.APPOINMENTS_SEARCH}`, { params })
    },
    performanceDoctor(params) {
        return axiosClient.get(urls.APPOINMENT_PERFORMANCE_DOCTOR, { params })
    },
    doctorAppoinments(params) {
        return axiosClient.get(`${urls.DOCTOR_APPOINMENT}`, { params })
    },
    doctorAppoinmentsSearch(params) {
        return axiosClient.get(urls.DOCTOR_APPOINMENTS_SEARCH, { params })
    },
    doctorAppoinmentsCount(params) {
        return axiosClient.get(`${urls.DOCTOR_APPOINMENT_COUNT}/${params}`,)
    },
    performancePatient(params) {
        return axiosClient.get(urls.APPOINMENT_PATIENT_PERFORMANCE, { params })
    },
    patientAppoinmentsGetAll(params) {
        return axiosClient.get(urls.APPOINMENT_PATIENT_GET_ALL, { params })
    },
    patientAppoinmentsGetAllSearch(params) {
        return axiosClient.get(urls.APPOINMENT_PATIENT_GET_ALL_SEARCH, { params })
    },
    patientAppoinmentsCount(params) {
        return axiosClient.get(`${urls.APPOINMENT_PATIENT_COUNT_ALL}/${params}`)
    },
    clinicAppointmentPerformance(params) {
        return axiosClient.get(urls.CLINIC_APPOINTMENT_PERFORMANCE, { params })
    },
    clinicAppointmentGetAll(params) {
        return axiosClient.get(urls.CLINIC_APPOINTMENT_GET_ALL, { params })
    },
    clinicAppointmentGetAllSearch(params) {
        return axiosClient.get(urls.CLINIC_APPOINTMENT_GET_ALL_SEARCH, { params })
    },
    clinicAppointmentCount(params) {
        return axiosClient.get(`${urls.CLINIC_APPOINTMENT_COUNT}/${params}`)
    },
    appointmentGetAll(params) {
        return axiosClient.get(urls.APPOINTMENT_GET_ALL, { params })
    },
    appointmentGetAllSearch(params){
        return axiosClient.get(urls.APPOINTMENT_GET_ALL_SEARCH,{params})
    },
    appointmentGetSingle(params){
        return axiosClient.get(`${urls.APPOINTMENT_GET_SINGLE}/${params}`)
    },
    appointmentPerformance(params){
        return axiosClient.get(urls.APPOINTMENT_PERFORMANCE,{params})
    },
    appointmentCount(){
        return axiosClient.get(urls.APPOINTMENT_COUNT)
    },
    appointmentSingleGet(params){
        return axiosClient.get(`${urls.APPOINTMENT_SINGLE_GET}/${params}`)
    }
}

export default appoinmentApi;