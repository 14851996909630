import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const doctorSlice = createSlice({
    name: "doctor",
    initialState,
    reducers: {
        getAll: (state) => {
            state.isFetching = true
        },
        getOne: (state) => {
            state.isFetching = true
        },
        getAllAppoinments: (state) => {
            state.isFetching = true
        },
        doctorSearch: (state) => {
            state.isFetching = true
        },
        getAllByClinic:(state)=>{
            state.isFetching = true
        },
        getSearchByClinic:(state)=>{
            state.isFetching = true
        },
        appoinmentSearch: (state) => {
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        failed(state, action) {
            state.message = action.payload
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    },
});

// Actions
export const doctorActions = doctorSlice.actions;

// Reducer
const doctorReducer = doctorSlice.reducer;

export { doctorReducer };