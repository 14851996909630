import './style.scss'
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import React from 'react';
import CustomDialog from './CustomDialog';
import { CircularProgress, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import authSelector from '../../redux/selector/auth/authSelector'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { phoneRegExp } from '../../config/Config';
import { adminSelector } from '../../redux/selector/admin/adminSelector';
import { adminActions } from '../../redux/slice/admin/adminSlice';
import { authActions } from '../../redux/slice/auth/authSlice';
import axiosClient from '../../api/axiosClient';
import { ChangePasswordModal } from './password/ChangePasswordModal';
import { ChangeNameModal } from './name/ChangeNameModal';
const phoneSchema = yup.object({
    contact_no: yup.string().matches(phoneRegExp, "Please enter a valid phone no").required("Please enter a phone no")
})
const phoneOTPSchema = yup.object({
    contact_no: yup.string().matches(phoneRegExp, "Please enter a valid phone no").required("Please enter a phone no"),
    otp: yup.number().min(100000, "You are enter a invalid OTP").max(999999, "You are enter a invalid OTP").required("Please enter a valid OTP")
})
const emailSchema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("Please enter a email address")
})
const emailOTPSchema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("Please enter a email address"),
    otp: yup.number().min(100000, "You are enter a invalid OTP").max(999999, "You are enter a invalid OTP").required("Please enter a valid OTP")
})
export const Settings = () => {
    const [toggleModal, setToggleModal] = React.useState()
    const [otpModal, setOtpModal] = React.useState()
    const [passwordModal, setPasswordModal] = React.useState()
    const [nameModal, setNameModal] = React.useState()
    const [emailUpdatedData, setEmailUpdatedData] = React.useState()
    const selector = useSelector(authSelector)
    const { handleSubmit, reset, watch, register, formState: { errors } } = useForm({ resolver: toggleModal === "email" ? otpModal ? yupResolver(emailOTPSchema) : yupResolver(emailSchema) : toggleModal === "contact_no" ? otpModal ? yupResolver(phoneOTPSchema) : yupResolver(phoneSchema) : null })
    const watchEmail = watch('email')
    const watchContact = watch('contact_no')
    const dispatch = useDispatch()
    const adminSelect = useSelector(adminSelector)
    const onSubmit = (data) => {
        setEmailUpdatedData(data)
    }
    React.useEffect(() => {
        if (emailUpdatedData) {
            if (toggleModal === "email" && emailUpdatedData.email && !otpModal) {
                dispatch(adminActions.reset())
                dispatch(adminActions.updateEmail({ "email": emailUpdatedData.email }))
            }
            else if (toggleModal === "email" && emailUpdatedData.otp && otpModal) {
                dispatch(adminActions.reset())
                dispatch(adminActions.verifyEmail({ "otp": emailUpdatedData.otp }))
            }
        }
    }, [emailUpdatedData, toggleModal, dispatch, otpModal])
    React.useEffect(() => {
        if (toggleModal === 'email') {
            if (adminSelect && adminSelect.data) {
                if (adminSelect.data.status) {
                    if (adminSelect.data.message === "Otp send to your email successfully!" && !otpModal) {
                        setOtpModal(true)
                    }
                    else if (adminSelect.data.message === "Email is updated successfully" && otpModal) {
                        setOtpModal()
                        setToggleModal()
                        reset()
                        setEmailUpdatedData()
                        if (localStorage.getItem("refresh_token") && localStorage.getItem("access_token")) {
                            dispatch(authActions.refreshLogin({ "token": localStorage.getItem("refresh_token") }))
                            axiosClient.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` }
                        }
                    }
                }
                else if (adminSelect.data.status === false && !adminSelect.data.message) {
                    alert(adminSelect.data.message)
                    dispatch(adminActions.reset())
                    setEmailUpdatedData()
                }
            }
        }
        //react-hooks/exhaustive-deps
    }, [adminSelect, otpModal, toggleModal, dispatch, reset])
    const onError = (data) => {
        //console.log(data)
    }
    return (
        <React.Fragment>
            <div className="settings_wrap">
                <h1>Account Setting</h1>
                <div className="settings_main">
                    {selector && selector.data && selector.data.name && <div className="single_item">
                        <div className="left_icon">
                            <PersonIcon />
                            <div className='primary_text'>
                                <span className='user_title'>Name</span>
                                <span className="user_content">{selector.data.name}</span>
                            </div>
                        </div>
                        <div className='right_action'>
                            <button onClick={() => { setNameModal(true) }}>
                                <EditIcon />
                            </button>
                        </div>
                    </div>}
                    {selector && selector.data && selector.data.email && <div className="single_item">
                        <div className="left_icon">
                            <EmailIcon />
                            <div className='primary_text'>
                                <span className='user_title'>Email</span>
                                <span className="user_content">{selector.data.email}</span>
                            </div>
                        </div>
                        <div className='right_action'>
                            <button onClick={() => { dispatch(adminActions.reset()); setToggleModal("email"); setOtpModal(); reset(); setEmailUpdatedData(); }}>
                                <EditIcon />
                            </button>
                        </div>
                    </div>}
                    {selector && selector.data && selector.data.contact_no && <div className="single_item">
                        <div className="left_icon">
                            <PhoneIcon />
                            <div className='primary_text'>
                                <span className='user_title'>Phone No</span>
                                <span className="user_content">{selector.data.contact_no}</span>
                            </div>
                        </div>
                        <div className='right_action'>
                            <button onClick={() => { setToggleModal("contact_no"); setOtpModal(); reset(); }}>
                                <EditIcon />
                            </button>
                        </div>
                    </div>}
                </div>
                <div className='settings_footer'>
                    <button className='b_psw' onClick={() => { setPasswordModal(true) }}>Change Password</button>
                    
                </div>
            </div>
            {nameModal && <ChangeNameModal open={nameModal} onClose={() => { setNameModal() }} />}
            {toggleModal && (toggleModal === "email" || toggleModal === "contact_no") && <CustomDialog
                open={true}
                onClose={() => { setToggleModal(false) }}
                children={
                    <React.Fragment>
                        {adminSelect.isFetching ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200, width: 200 }}>
                                <CircularProgress />
                            </div> :
                            <React.Fragment>
                                <DialogTitle style={{ color: 'black', paddingBottom: 10 }}>{`${toggleModal === "email" ? otpModal ? "Verify your OTP(One Time Password)" : "Update your email" : ''} ${toggleModal === "contact_no" ? otpModal ? "Verify your OTP(One Time Password)" : "Update your phone no" : ''}`}</DialogTitle>
                                <form onSubmit={handleSubmit(onSubmit, onError)}>
                                    <DialogContent style={{ paddingTop: 0 }}>
                                        <DialogContentText style={{ marginBottom: 6 }}>
                                            {toggleModal === "email" ? otpModal ? `OTP sending successfully your email ending with ${watchEmail.split('@')[0].charAt(0)}${watchEmail.split('@')[0].charAt(1)}****${watchEmail.split('@')[0].charAt(watchEmail.split('@')[0].length - 3)}${watchEmail.split('@')[0].charAt(watchEmail.split('@')[0].length - 2)}${watchEmail.split('@')[0].charAt(watchEmail.split('@')[0].length - 1)}@${watchEmail.split('@')[1]}.` : "Please fill correct information that are directly update on database." : toggleModal === "contact_no" && otpModal ? `OTP sending successfully your phone no ending with ******${watchContact.charAt(watchContact.length - 4)}${watchContact.charAt(watchContact.length - 3)}${watchContact.charAt(watchContact.length - 2)}${watchContact.charAt(watchContact.length - 1)}.` : "Please fill correct information that are directly update on database."}
                                        </DialogContentText>
                                        {otpModal ?
                                            <React.Fragment key={otpModal}>
                                                <TextField fullWidth {...register('otp')} inputMode='numeric' variant='outlined' label="OTP" />
                                                {errors && errors["otp"] && <p style={{ color: 'red', margin: 0, padding: 0 }}>{errors.otp?.message}</p>}
                                            </React.Fragment> :
                                            <React.Fragment>
                                                <TextField fullWidth {...register(toggleModal === "email" ? 'email' : "contact_no")} inputMode={toggleModal === "email" ? 'email' : 'numeric'} variant='outlined' label={toggleModal === "email" ? "Email" : "Phone No"} />
                                                {errors && errors[toggleModal === "email" ? "email" : "contact_no"] && <p style={{ color: 'red', margin: 0, padding: 0 }}>{toggleModal === "email" ? errors.email?.message : errors.contact_no?.message}</p>}
                                            </React.Fragment>
                                        }

                                    </DialogContent>
                                    <div className="dialog_footer">
                                        <div className="dialog_footer_child_wrap">
                                            <button className="b_d_f_close" type='reset' onClick={() => { setToggleModal(); setOtpModal() }}>Close</button>
                                            <button className='b_d_f_update' type='submit' onClick={() => { handleSubmit(onSubmit, onError) }}>{otpModal ? "Update" : "Verify"}</button>
                                        </div>
                                    </div>
                                </form>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            />}
            {passwordModal && <ChangePasswordModal open={passwordModal} onClose={() => { setPasswordModal() }} />}
        </React.Fragment>
    );
}