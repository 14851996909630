import { call, put, takeLatest } from "redux-saga/effects";
import { aboutUpdateActions } from "../../slice/about/aboutUpdateSlice";
import aboutUpdateApi from "../../../api/about/aboutUpdateApi";

function* edit(action) {
    try {
        const response = yield call(aboutUpdateApi.edit, action.payload);
        yield put(aboutUpdateActions.success(response));
    } catch (error) {
        yield put(aboutUpdateActions.failed(error.message));
    }
}
export default function* aboutUpdateSaga() {
    yield takeLatest(aboutUpdateActions.edit.type, edit)
}