import React from "react";
import '../style.scss'
import { useNavigate } from "react-router-dom";
function useScrollTop() {
    const [scrollTop, setScrollTop] = React.useState(0);
    const onScroll = (event) => setScrollTop(event.target.scrollTop);
    return [scrollTop, { onScroll }];
}
export const Terms = () => {
    const [scrollTop, scrollProps] = useScrollTop();
    const navigate = useNavigate()
    return (
        <React.Fragment>
            <div className="main">
                <div className="header" >
                    <h1 onClick={() => { navigate('/policies') }}>DNA<span>LIVE</span></h1>
                </div>
                <div className="body" {...scrollProps} style={{ boxShadow: scrollTop > 0 ? "inset 0 8px 5px -1px #e9e9e9" : "none", transition: "box-shadow 0.3s" }}>

                    {/* <div className="body_wrap">
                            <img src={require("../../assets/logo.jpg")} />
                            <h1>Comming Soon....</h1>
                        </div>
                         */}
                    <div className="card_content">
                        <div className="logo_container">
                            <img src={require("../../assets/logo.jpg")} />
                        </div>
                        <div className="t_c">
                            <div className="t_c_header">
                                <p>Terms & Conditions</p>
                            </div>
                            <div className="t_c_content">
                                <p className="list">1. CONTRACTUAL RELATIONSHIP</p>
                            </div>
                            <div className="t_c_main_content">
                                <p className="list">
                                These Terms of Use (“Terms”) govern the access or use by you, an individual / a third party provider, as applicable (“user”), of applications, websites, content, products, and services (the “Services”) made available by The AppRaiser, a partnership Firm and it’s product DNA LIVE (“The AppRaiser”), established in India, having its registered office at 118, M.G Road, Kolkata-104 and having a place of business at the above address. PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE SERVICES.

                                 Your access and use of the Services constitute your agreement to be bound by these Terms, which establishes a contractual relationship between you and The Appraiser. If you do not agree to these Terms, you may not access or use the Services. The AppRaiser may immediately terminate these Terms or any Services with respect to you, or generally cease offering or deny access to the Services or any portion thereof, at any time for any reason. Supplementary terms may apply to certain Services, such as policies for a particular event, activity or promotion, and such supplementary terms will be disclosed to you in connection with the applicable Services. Supplementary terms are in addition to, and shall be deemed a part of, the Terms for the purposes of the applicable Services. Supplementary terms shall prevail over these Terms in the event of a conflict with respect to the applicable Services. The AppRaiser may amend the Terms related to the Services from time to time. Amendments will be effective upon The AppRaiser’s posting of such updated Terms or the amended policies or supplementary terms on the applicable Service. Your continued access or use of the Services after such posting constitutes your consent to be bound by the Terms, as amended. Our collection and use of personal information in connection with the Services is as provided in our Privacy Policy.
                                </p>
                            </div>
                            <div className="t_c_content">
                                <p className="list">2. THE SERVICES</p>
                            </div>
                            <div className="t_c_main_content">
                                <p className="list">
                                The Services constitute (a) the technology platform that enables users of DNA LIVE to arrange and schedule appointment services with independent third party provider / individual, as applicable, of such services (ii) support services to the users of DNA LIVE in relation to sharing appointment related details, resolving issues in relation to booking appointment.

                                    <p>YOU ACKNOWLEDGE THAT THE APPRAISER DOES NOT PROVIDE MEDICAL SERVICES OR FUNCTION AS MEDICAL SERVICE PROVIDER AND THAT ALL SUCH MEDICAL SERVICES ARE PROVIDED BY INDEPENDENT THIRD PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY THE APPRAISER OR ANY OF ITS AFFILIATES. MEDICAL SERVICES REQUESTED BY INDIVIDUAL IS SOLELY CONTROLLED BY THE THIRD PARTY PROVIDER OF IT’S CHOICE.</p>
                                    <p className="sub_list">LICENSE.</p>
                                    <p>
                                    Subject to your compliance with these Terms, The AppRaiser grants you a limited, non-exclusive, non-sub licensable, revocable, non-transferable license to: (i) access and use the Applications on your device solely in connection with your use of the Services; and (ii) access and use any content, information and related materials that may be made available through the Services. Any rights not expressly granted herein are reserved by The AppRaiser.
                                    </p>
                                    <p className="sub_list">RESTRICTIONS.</p>
                                    <p>
                                    You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the Services; (ii)reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services except as expressly permitted by The AppRaiser; (iii) de-compile, reverse engineer or disassemble the Services except as may be permitted by applicable law; (iv) link to, mirror or frame any portion of the Services; (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services or unduly burdening or hindering the operation and/or functionality of any aspect of the Services; or (vi) attempt to gain unauthorized access to or impair any aspect of the Services or its related systems or networks.
                                    </p>
                                    <p className="sub_list">THIRD PARTY SERVICES AND CONTENT.</p>
                                    <p>
                                    The Services may be made available or accessed in connection with third party services and content (including advertising) that The AppRaiser does not control. You acknowledge that different terms of use and privacy policies may apply to your use of such third party services and content. The AppRaiser does not endorse such third party services and content and in no event shall The AppRaiser be responsible or liable for any products or services of such third party providers.                           </p>
                                    <p className="sub_list">OWNERSHIP.</p>
                                    <p>
                                    The Services and all rights therein are and shall remain The AppRaiser’s property or the property of The AppRaiser’s licensors. Neither these Terms nor your use of the Services convey or grant to you any rights: (i) in or related to the Services except for the limited license granted above; or (ii) to use or reference in any manner The AppRaiser’s and / or it’s application’s names, logos, product and service names, trademarks or services marks or those of The AppRaiser’s licensors.
                                    </p>
                                </p>
                            </div>
                            <div className="t_c_content">
                                <p className="list">3. YOUR USE OF THE SERVICES</p>
                            </div>
                            <div className="t_c_main_content">
                                <p className="list">
                                    <p className="sub_list">USER ACCOUNTS.</p>
                                    <p>
                                    In order to use most aspects of the Services, you must register for and maintain an active user Services account(“Account”). Account registration requires you to submit certain personal / institutional information, such as name, address, e-mail address, mobile phone number, registration number, registration certificate, valid payment method etc as applicable. You agree to maintain accurate, complete, and up-to-date information in your Account. Having outdated Account information, including having an invalid or expired payment method, may result in your inability to access and use the Services or termination of these Terms with you. You are responsible for all activity that occurs under your Account, and you agree to maintain the security and secrecy of your Account username and password at all times.
                                    </p>
                                    <p className="sub_list">USER REQUIREMENTS AND CONDUCT.</p>
                                    <p>
                                    The Service is not available for use by persons under the age of 18. You may not authorize third parties to use your Account, and you may not allow persons under the age of 18 to receive services from Third Party Providers unless they are accompanied by you. You may not assign or otherwise transfer your Account to any other person or entity. You agree to comply with all applicable laws when using the Services, and you may only use the Services for lawful purposes. You will not, in your use of the Services, cause nuisance, annoyance, inconvenience, or property damage, whether to the individual or Third Party Provider or any other party as applicable. In certain instances you may be asked to provide proof of identity to access or use the Services, and you agree that you may be denied access to or use of the Services if you refuse to provide proof of identity.
                                    </p>
                                    <p className="sub_list">TEXT MESSAGING.</p>
                                    <p>
                                        By creating an Account, you agree that the Services may send you informational text (SMS) messages as part of the normal business operation of your use of the Services.
                                    </p>
                                    <p className="sub_list">PROMOTIONAL CODES.</p>
                                    <p>
                                    The AppRaiser may, in it’s sole discretion, create promotional codes that may be redeemed for Account credit, or other features or benefits related to the Services and/or a Third Party Provider’s services, subject to any additional terms that The AppRaiser establishes on a per promotional code basis (“Promo Codes”). You agree that Promo Codes: (i) must be used for the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated, sold or transferred in any manner, or made available to the general public (whether posted to a public form or otherwise), unless expressly permitted by The AppRaiser; (iii)may be disabled by The AppRaiser at any time for any reason without liability to it; (iv) may only be used pursuant to the specific terms that The AppRaiser establishes for such Promo Code; and (v) may expire prior to your use. The AppRaiser reserves the right to withhold or deduct credits or other features or benefits obtained through the use of Promo Codes by you or any other user in the event that The AppRaiser determines or believes that the use or redemption of the Promo Code was in error, fraudulent, illegal, or in violation of the applicable Promo Code terms or these Terms.
                                    </p>
                                    <p className="sub_list">DATA NETWORK.</p>
                                    <p>
                                    You are responsible for obtaining the data network access necessary to use the Services. Your mobile network’s data and messaging rates and fees may apply if you access or use the Services from a wireless-enabled device and you shall be responsible for such rates and fees. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Services and Applications and any updates thereto. The AppRaiser does not guarantee that the Services, or any portion thereof, will function on any particular hardware or devices. In addition, the Services may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.
                                    </p>
                                    
                                </p>
                            </div>
                            <div className="t_c_content">
                                <p className="list">4. PAYMENT</p>
                            </div>
                            <div className="t_c_main_content">
                                <p className="list">
                                As individual, you understand that use of the Services may result in charges to you for the services or goods you receive from The AppRaiser (“ Booking Charge”) and for the services or goods you receive from the Third Party Provider ( “Clinic Charge”). After you have received services obtained through your use of the Services, The AppRaiser will facilitate your payment of the Booking Charge. Payment of Clinic Charges applicable to the Third Party Provider shall be made directly by you to the Third Party Provider at the time of availing such services or goods. All Charges will be inclusive of applicable taxes where required by law. The Appraiser reserves the right to fix the amount of Booking Charge. Booking Charge paid by you are final and non-refundable, unless otherwise determined by The AppRaiser. The AppRaiser has nothing to do with Clinic Charge which is solely controlled by the Third Party Provider. You have to abide by the rules of the Third Party Provider regarding service provided by the service provider on your request. All charges are due immediately. The AppRaiser will use reasonable efforts to inform you of Charges that may apply.
                                </p>
                            </div>
                            <div className="t_c_content">
                                <p className="list">5. DISCLAIMERS; LIMITATION OF LIABILITY; INDEMNITY.</p>
                            </div>
                            <div className="t_c_main_content">
                                <p className="list">
                                THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE”. THE APPRAISER MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR￾FREE. THE APPRAISER DOES NOT GUARANTEE THE QUALITY,SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. THE APPRAISER SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii)ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND INDIVIDUAL / THIRD PARTY PROVIDER, AS THE CASE MAY BE, EVEN IF THE APPRAISER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE APPRAISER SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND THE APPRAISER’S REASONABLE CONTROL. YOU AGREE THAT THE APPRAISER HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY SERVICES PROVIDED TO YOU BY THIRD PARTY PROVIDERS.
                                   
                                    <p className="sub_list">INDEMNITY.</p>
                                    <p>
                                    You agree to indemnify and hold The AppRaiser and its partners, officers, directors, employees and agents harmless from any and all claims, demands, losses, liabilities, and expenses arising out of or in connection with: (i) your use of the Services or services or goods obtained through your use of the Services; (ii) your breach or violation of any of these Terms;(iii) The AppRaiser’s use of your User Content; or (iv) your violation of the rights of any third party, including Third Party Providers.
                                    </p>
                                </p>
                            </div>
                            <div className="t_c_content">
                                <p className="list">6. GOVERNING LAW; ARBITRATION.</p>
                            </div>
                            <div className="t_c_main_content">
                                <p className="list" style={{ paddingBottom: '10px' }}>
                                Except as otherwise set forth in these Terms, these Terms shall be exclusively governed by and construed in accordance with the laws of India.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="footer" >
                    <ul>
                        <li onClick={() => { navigate('/policies/terms-condition') }}>Terms and Condition</li>
                        <li style={{ color: 'black' }}>|</li>
                        <li onClick={() => { navigate('/policies/privacy-policy') }}>Privacy Policy</li>
                    </ul>
                </div>
            </div>

        </React.Fragment>
    )
}