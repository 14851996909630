  export const formatDate=(date)=>{
    const isoDate = date;
    const year = isoDate.slice(0, 4);
    const month = isoDate.slice(5, 7);
    const day = isoDate.slice(8, 10);
    // const hour = isoDate.slice(11, 13);
    // const minute = isoDate.slice(14, 16);
  
    const dateObj = new Date(year, month - 1, day); // Note: month is 0-based in Date constructor
  
    const formattedDate = dateObj.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // hour12: true,
    });
return formattedDate
   }

export const slashFormatDate=(date)=>{
    const isoDate = date

const year = isoDate.slice(0, 4);
const month = isoDate.slice(5, 7);
const day = isoDate.slice(8, 10);

return `${day}/${month}/${year}`;
}