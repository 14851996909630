import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const clinicSlice = createSlice({
    name: "clinic",
    initialState,
    reducers: {
        add: (state, action) => {
            state.isFetching = true;
        },
        edit: (state, action) => {
            state.isFetching = true;
        },
        getAll: (state) => {
            state.isFetching = true;
        },
        getOne: (state, action) => {
            state.isFetching = true;
        },
        getBankDetails: (state, action) => {
            state.isFetching = true
        },
        addBank: (state) => {
            state.isFetching = true
        },
        editBank: (state) => {
            state.isFetching = true
        },
        changePassword: (state) => {
            state.isFetching = true
        },
        getAllAppoinments: (state) => {
            state.isFetching = true
        },
        getAllPayments: (state) => {
            state.isFetching = true
        },
        getDoctor: (state) => {
            state.isFetching = true
        },
        searchClinic: (state) => {
            state.isFetching = true
        },
        searchAppoinments: (state) => {
            state.isFetching = true
        },
        searchDoctors: (state) => {
            state.isFetching = true
        },
        searchPayments: (state) => {
            state.isFetching = true
        },
        updateStatus: (state) => {
            state.isFetching = true
        },
        success(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        failed(state, action) {
            state.message = action.payload
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    },
});

// Actions
export const clinicActions = clinicSlice.actions;

// Reducer
const clinicReducer = clinicSlice.reducer;


export { clinicReducer };