import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
    },
    isFetching: false
};


const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            localStorage.setItem("login_count", 1)
            state.isFetching = true;
        },
        refreshLogin: (state,action)=>{
            const count=localStorage.getItem("login_count")
            localStorage.setItem("login_count", parseInt(count)+1)
            state.isFetching = true
        },
        authSuccess(state, action) {
            state.data = action.payload.data
            state.isFetching = false
        },
        authFailed(state, action) {
            state.message = action.payload
            state.isFetching = false
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    },
});

// Actions
export const authActions = authSlice.actions;

// Reducer
const authReducer = authSlice.reducer;


export { authReducer };