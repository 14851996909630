import { call, put, takeLatest } from "redux-saga/effects";
import { termsAddActions } from "../../slice/terms/termsAddSlice";
import termsAddApi from "../../../api/terms/termsAddApi";
function* add(action) {
    try {
        const response = yield call(termsAddApi.add, action.payload);
        yield put(termsAddActions.success(response));
    } catch (error) {
        yield put(termsAddActions.failed(error.message));
    }
}
export default function* termsAddSaga() {
    yield takeLatest(termsAddActions.add.type, add);
}