import { Button, TextField, InputAdornment, IconButton } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom'
import '../login/login.scss'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { authActions } from '../../redux/slice/auth/authSlice'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AlertMsg from '../../components/alert/AlertMsg'
import authSelector from '../../redux/selector/auth/authSelector'
import './style.scss'
const schema = yup.object({
    email: yup.string().required("Please enter your email").email("Please enter a valid email"),
    otp:yup.number().min(6).max(6).required('Please enter valid otp')
})
export const ForgotPassword = () => {
    const { handleSubmit, register, formState: { errors }, reset } = useForm({ resolver: yupResolver(schema) });
    const [emailVerify, setEmailVerify] = React.useState(false)
    const [email,setEmail]=React.useState()
    const [otpVerify, setOtpVerify] = React.useState(false)
    const [pswToggle, setPswToggle] = React.useState(false)
    const [message, setMessage] = React.useState(false)
    
    const selector = useAppSelector(authSelector)
   
    const onSubmit = (data) => {
       
        setEmail(data)
        console.log({ data });
        reset();
      };
    return (
        <React.Fragment>
            <div className='login_main_wrap' style={{ backgroundImage: `url(${require('../../assets/img/login_back/forgot_bg.jpg')}) `, overflow: "hidden" }}>
                <div className='login_top'>
                    <h1>DNA<span>LIVE</span></h1>
                </div>
                <div className='center_wrap'>
                    <div className='center_left_section'>
                        <img src={require('../../assets/img/login_png/forgot_left_img.png')} alt="img" />
                    </div>
                    <div className='center_right_wrap'>
                        <div className='text_wrap' style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
                            <h1 style={{marginBottom:"12px"}}>{otpVerify===true?"Create New ":"Forgot" }<span style={{color:otpVerify===true&&'#439599'}}>Password</span></h1>
                            <span style={{margin:0,color:"gray",textAlign:"center",padding:"0px 16px",display:otpVerify===true&&'none'}}>For verification we send a OTP in your email</span>
                            <form onSubmit={handleSubmit(onSubmit)} className='input_wrap' style={{display:emailVerify===true&&'none'}}>
                                <div style={{width:'80%'}}>
                                <TextField name='email'  {...register("email")} sx={{width:'100%'}} size="small" placeholder='johndoe@gmail.com' required type="email" label="Email" />
                                <span  style={{textAlign:'left',color:'red',width:'100%',margin:0,marginTop:'10px'}}>{errors.email?.message}</span>
                                </div>
                                <div style={{ width: "80%", display: "flex",justifyContent: emailVerify===true?'flex-end':'space-between',alignItems:'center',gap:'16px' }}>
                                    <Link to="/" style={{textDecoration:'underline',color:'#17BA86',display:emailVerify===true&&"none"}}>Back to login</Link>
                                    <Button  variant='contained' sx={{ bakcgroundColor: "#17BA86",marginBottom:'16px' }} type={"submit"} onClick={()=>{handleSubmit(onSubmit);setEmailVerify(true)}}>Verify</Button>
                                </div>
                            </form>
                            <div style={{ width: "80%",display:emailVerify===true&&otpVerify===false?"flex":'none',flexDirection:'column',marginTop:"16px" }}>
                                <TextField {...register("password")} sx={{ width: '100%' }} size="small" placeholder='123XX67' type={"text"} label="OTP(One Time Password)" />
                                <div className='sign_in_button' style={{ marginBottom: "16px",width:"100%",display:'flex',justifyContent:'flex-end', gap: '16px' }}>
                                    <Link to="/"><Button variant="outlined" sx={{ textTransform: 'capitalize' }}>Cancel</Button></Link>
                                    <Button onClick={()=>{setOtpVerify(true)}} variant="contained" sx={{ textTransform: 'capitalize' }}>Submit</Button>
                                </div>
                            </div>
                            <div className='input_wrap' style={{display:(emailVerify===true&&otpVerify===true)?'flex':'none'}}>
                                <TextField  {...register("username")} sx={{ width: '80%'}} size="small" placeholder='123XXGHXX' type="password" label="New Password" />
                                <TextField  {...register("username")} sx={{ width: '80%'}} size="small" placeholder='123XXGHXX' type={pswToggle===true?"text":"password"} label="Confirm Password" 
                                 InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={() => { setPswToggle(!pswToggle) }}
                                            onMouseDown={(e) => { e.preventDefault() }}
                                        >
                                            {pswToggle ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                                <div className='sign_in_button' style={{ marginBottom: "16px",width:"80%",display:'flex',justifyContent:'flex-end', gap: '16px' }}>
                                    <Link to="/"><Button variant="outlined" sx={{ textTransform: 'capitalize' }}>Cancel</Button></Link>
                                    <Button onClick={()=>{setOtpVerify(true)}} variant="contained" sx={{ textTransform: 'capitalize' }}>Submit</Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {message && <AlertMsg open={message} onClose={() => { setMessage(false) }} type={"error"} msgText={selector.data.message} />}
        </React.Fragment>
    );
}