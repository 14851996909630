import React from "react";
import './style.scss'
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useParams } from "react-router-dom";
import { BackButton } from "../../../components/buttons/Buttons";
import Stepper from '../../../components/stepper/Stepper'
import { CustomizedSteppers } from "../AppoinmentsDetails";
import { appoinmentActions } from "../../../redux/slice/appoinment/appoinmentSlice";
import appoinmentSelector from "../../../redux/selector/appoinment/appoinmentSelector";
import { ClinicProfileCard, DoctorProfileCard, DoctorSocialMedia, PatientProfileCard } from "../../home/NewHome";
import { slashFormatDate } from "../../../utils";
export const SingleAppointment = () => {
    const params = useParams()
    const dispatch = useAppDispatch()
    const selector = useAppSelector(appoinmentSelector)
    const [appoinData, setAppoinData] = React.useState()

    React.useEffect(() => {
        console.log(params)
        if (params && params.id) {
            dispatch(appoinmentActions.appointmentSingleGet(params.id))
        }
    }, [dispatch, params])
    React.useEffect(() => {
        if (selector && selector.data && typeof selector.data === 'object') {
            console.log("selector data:",selector?.data)
            setAppoinData(selector?.data)
        }
    }, [selector])
    return (
        <React.Fragment>
            <div className='top_header'>
                <BackButton />
            </div>
            {appoinData && <div className="content_wrap">
                <CustomizedSteppers steps={[
                    {
                        "item": "Booked",
                        "value":  appoinData?.req_date?slashFormatDate(appoinData?.req_date):"",
                        "status": appoinData?.booked_date ? true : false
                    },
                    {
                        "item": "Accepted",
                        "isCancel":appoinData?.booked_date&&appoinData?.appointment_status==="Cancelled"?true:false,
                        "value": appoinData.booked_date ? slashFormatDate(appoinData?.booked_date) : "",
                        "status": appoinData.booked_date&&(appoinData?.appointment_status==="Pending"||appoinData?.appointment_status==="Completed"||appoinData.payment.clinic?.status === "Paid") ? true : false
                    },
                    {
                        "item": `Payment ${appoinData?.booked_date && appoinData?.payment ? `(${appoinData?.payment?.clinic?.fees}/-)` : ''}`,
                        "value": appoinData?.booked_date && (appoinData?.payment && (appoinData.payment.clinic?.status === "Paid" && appoinData?.payment?.date&&slashFormatDate(appoinData?.payment?.date))),
                        "status": appoinData?.payment?.clinic?.status === "Paid"?true:false,
                    },
                    {
                        "item":`Completed ${appoinData?.booked_date&&appoinData?.payment?`(${appoinData?.payment?.doctor?.fees}/-)`:""}`,
                        "value": appoinData?.booked_date && (appoinData?.payment && (appoinData?.payment?.doctor?.status === "Paid" && appoinData?.appointment_date&&slashFormatDate(appoinData?.appointment_date))),
                        "status": appoinData?.payment && ((appoinData?.payment?.doctor?.status === "Paid" && appoinData?.appointment_status === "Completed") ?true:false)
                    },
                ]} />
            </div>}
            {/* {appoinData &&appoinData.clinic && appoinData.patient && appoinData.doctor &&
                <div className="bottom_card_wrapper">
                    <PatientProfileCard data={appoinData.patient} view={'grid'} />
                    <DoctorProfileCard data={appoinData.doctor} view={'grid'} />
                    <ClinicProfileCard data={appoinData.clinic} view={'grid'} />
                </div>} */}
        </React.Fragment>
    )
}