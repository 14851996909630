import { call, put, takeLatest } from "redux-saga/effects";
import { patientActions } from "../../slice/patient/patientSlice";
import { patientApi } from "../../../api/patient/patientApi";
function* getAll(action) {
    try {
        const response = yield call(patientApi.getAll, action.payload);
        yield put(patientActions.success(response));
    } catch (error) {
        yield put(patientActions.failed(error.message));
    }
}
function* getOne(action) {
    try {
        const response = yield call(patientApi.getOne, action.payload)
        yield put(patientActions.success(response))
    }
    catch (error) {
        yield put(patientActions.failed(error.message))
    }
}
function* getAllAppooinments(action) {
    try {
        const response = yield call(patientApi.getAllAppoinment, action.payload)
        yield put(patientActions.success(response))
    }
    catch (error) {
        yield put(patientActions.failed(error.message))
    }
}
function* getAllPayments(action) {
    try {
        const response = yield call(patientApi.getAllPayments, action.payload)
        yield put(patientActions.success(response))
    }
    catch (error) {
        yield put(patientActions.failed(error.message))
    }
}

function* searchPatients(action) {
    try {
        const response = yield call(patientApi.searchPatient, action.payload)
        yield put(patientActions.success(response))
    } catch (error) {
        yield put(patientActions.failed(error.message))
    }
}
function* searchAppoinments(action) {
    try {
        const response = yield call(patientApi.searchPatientAppoinments, action.payload)
        yield put(patientActions.success(response))
    } catch (error) {
        yield put(patientActions.failed(error.message))
    }
}

function* searchPayments(action) {
    try {
        const response = yield call(patientApi.searchPatientPayments, action.payload)
        yield put(patientActions.success(response))
    } catch (error) {
        yield put(patientActions.failed(error.message))
    }
}

export default function* patientSaga() {
    yield takeLatest(patientActions.getAll.type, getAll);
    yield takeLatest(patientActions.getOne.type, getOne)
    yield takeLatest(patientActions.getAllAppoinments.type, getAllAppooinments)
    yield takeLatest(patientActions.getAllPayments.type, getAllPayments)
    yield takeLatest(patientActions.searchPatients.type, searchPatients)
    yield takeLatest(patientActions.searchAppoinments.type, searchAppoinments)
    yield takeLatest(patientActions.searchPayments.type, searchPayments)
}