import React from "react";
import './style.scss'
import { Link } from "react-router-dom";

export const SmallCard = (props) => {
   
    return (
        <React.Fragment>
           
            <Link to={props.data.link} className='small_card_main'>
                <div>
                    <h4>{props.data.header}</h4>
                    <div className="count">
                        <h1>{props.data.count}</h1>
                    </div>
                </div>
                <div className='image_wrapper'>
                    <img src={props.data.img} />
                </div>

            </Link>
        </React.Fragment>

    )
}