import { call, put, takeLatest } from "redux-saga/effects";
import privacyAddApi from "../../../api/privacy/privacyAddApi";
import { privacyAddActions } from "../../slice/privacy/privacyAddSlice";

function* add(action) {
    try {
        const response = yield call(privacyAddApi.add, action.payload);
        yield put(privacyAddActions.success(response));
    } catch (error) {
        yield put(privacyAddActions.failed(error.message));
    }
}
export default function* privacyAddSaga() {
    yield takeLatest(privacyAddActions.add.type, add);
}